import { getConfigData } from '@choo-app/lib/editor/plugins/configData'

import { Command, PaginationType } from '../prosemirrorHelpers/types'

import { inlinePageBreak } from './inlinePageBreak'
import { structuralPageBreak } from './structuralPageBreak'

export { handleEnter } from './enter'

// Command that never fires
const noopCommand = () => false

const insertPageBreakCommands: Record<PaginationType, Command> = {
  structural: structuralPageBreak.create,
  inline: inlinePageBreak.create,
  none: noopCommand,
}

export const removePageBreakCommands: Record<PaginationType, Command> = {
  structural: structuralPageBreak.remove,
  inline: inlinePageBreak.remove,
  none: noopCommand,
}

export const insertPageBreak: Command = (state, dispatch) =>
  insertPageBreakCommands[getConfigData(state).paginationType](state, dispatch)

export const removePageBreak: Command = (state, dispatch) =>
  removePageBreakCommands[getConfigData(state).paginationType](state, dispatch)
