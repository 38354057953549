import React from 'react'

import { useQuery } from '@tanstack/react-query'
import { Fragment } from 'prosemirror-model'

import { useMst } from '@state'
import { createProsemirrorDoc } from '@util'

import { BlankSide, cardTitle, subtitle } from './helpers'

/*
  notes about staleTime/gcTime

  staleTime means: "How long is this query considered valid"
  gcTime means: "When no longer mounted, when should it be garbage collected"

  While in snapshot land for a script, we need to balance between reusing API
  responses and using too much memory.

  For the "current version" of the script, we should consider the value to
  be invalid pretty quickly, but it's still in discussion how this should work

  Snapshots on the other hand are immutable, so staleTime is Infinity, but we don't
  want to over-cache them.

*/
const minutesToMillis = (minutes: number) => minutes * 60 * 1000

export const useSnapshotData = ({
  snapshotId,
  scriptId,
}: {
  snapshotId: string
  scriptId: string
}) => {
  const { apiClient, doDebug } = useMst()

  return useQuery({
    queryFn: async () => {
      await doDebug()
      return apiClient.getSnapshot({ scriptId, snapshotId })
    },
    queryKey: ['snapshotland-snapshot', { scriptId, snapshotId }],
    // if we have a snapshot in the cache, it is not stale
    staleTime: Infinity,
    // keep for 5 minutes if a snapshot is unmounted
    gcTime: minutesToMillis(5),
  })
}

export const useMaybeSnapshot = ({
  snapshotId,
  scriptId,
}: {
  snapshotId?: string
  scriptId: string
}) => {
  const { apiClient, doDebug } = useMst()
  return useQuery({
    queryFn: async () => {
      if (snapshotId) {
        await doDebug()
        return apiClient.getSnapshot({ scriptId, snapshotId })
      }
      return null
    },
    queryKey: ['snapshotland-snapshot', { scriptId, snapshotId }],
    // if we have a snapshot in the cache, it is not stale
    staleTime: Infinity,
    // keep for 5 minutes if a snapshot is unmounted
    gcTime: minutesToMillis(5),
  })
}

export const useSelectedSnapshots = (scriptId: string) => {
  const { snap1, snap2 } = useMst().view.snapshotLand
  const topQuery = useMaybeSnapshot({ scriptId, snapshotId: snap1 })
  const bottomQuery = useMaybeSnapshot({ scriptId, snapshotId: snap2 })

  return {
    topQuery,
    bottomQuery,
  }
}

export const useScriptData = (scriptId: string) => {
  const { apiClient, doDebug } = useMst()
  return useQuery({
    queryFn: async () => {
      await doDebug()
      const script = await apiClient.getScript(scriptId)
      const fetchedAt = new Date()
      return {
        script,
        fetchedAt,
      }
    },
    queryKey: ['snapshotland-script', scriptId],

    // TODO: revisit these
    staleTime: Infinity,
    gcTime: 0,
  })
}

export type PopulatedSideBySideData = {
  leftTitle: string
  leftSubtitle: string
  leftEmphasis: boolean
  rightTitle: string
  rightSubtitle: string
  rightEmphasis: boolean
  leftFragment: Fragment
  rightFragment: Fragment
  blankSide?: BlankSide
}
type SideBySideData =
  | 'no-data' // user hasn't picked anything
  | 'error' // we have an error and can't show anything
  | 'loading' // we don't have either side yet but have some data
  | PopulatedSideBySideData

export const useSideBySideDiffData = (scriptId: string): SideBySideData => {
  const { snap1, snap2 } = useMst().view.snapshotLand
  const leftQuery = useMaybeSnapshot({ scriptId, snapshotId: snap2 })
  const rightQuery = useMaybeSnapshot({ scriptId, snapshotId: snap1 })

  const leftFragment: Fragment | null = React.useMemo(() => {
    return leftQuery.data
      ? createProsemirrorDoc(leftQuery.data.doc).content
      : null
  }, [leftQuery.data])

  const rightFragment: Fragment | null = React.useMemo(() => {
    return rightQuery.data
      ? createProsemirrorDoc(rightQuery.data.doc).content
      : null
  }, [rightQuery.data])

  // if either side errors, return 'error'
  if (leftQuery.isError || rightQuery.isError) {
    return 'error'
  }

  // return loading if both sides are loading
  if (leftQuery.isPending && rightQuery.isPending) {
    return 'loading'
  }

  const leftTitle = cardTitle(leftQuery)
  const leftSubtitle = leftQuery.data ? subtitle(leftQuery.data, true) : ''
  const leftEmphasis = !leftQuery.data?.autoSave
  const rightTitle = cardTitle(rightQuery)
  const rightSubtitle = rightQuery.data ? subtitle(rightQuery.data, true) : ''
  const rightEmphasis = !rightQuery.data?.autoSave

  // both sides ready and distinct
  if (leftFragment && rightFragment) {
    return {
      leftTitle,
      leftSubtitle,
      leftEmphasis,
      rightTitle,
      rightSubtitle,
      rightEmphasis,
      leftFragment,
      rightFragment,
    }
  }

  // left side is ready, right is loading or not picked
  if (leftFragment && !rightFragment) {
    const reason = rightQuery.isPending ? 'loading' : 'pickAnother'
    return {
      leftFragment,
      rightFragment: leftFragment,
      leftTitle,
      leftSubtitle,
      leftEmphasis,
      rightTitle,
      rightSubtitle,
      rightEmphasis,
      blankSide: {
        side: 'right',
        reason,
      },
    }
  }
  // right side is ready, left is loading or not picked
  if (rightFragment && !leftFragment) {
    const reason = leftQuery.isPending ? 'loading' : 'pickAnother'
    return {
      leftTitle,
      leftSubtitle,
      leftEmphasis,
      rightTitle,
      rightSubtitle,
      rightEmphasis,
      leftFragment: rightFragment,
      rightFragment,
      blankSide: {
        side: 'left',
        reason,
      },
    }
  }

  // last case is that neither side is picked
  return 'no-data'
}
