import { SegmentedControl } from '@mantine/core'
import { observer } from 'mobx-react-lite'

import { useMst } from '@hooks'

export const SnapshotFilter = observer(function HistoryFilter() {
  const { user } = useMst()
  const filter = user.prefs.snapshotFilter ?? 'all'
  const setFilter = (value: string) => {
    user.updatePreferences({
      snapshotFilter: value === 'all' ? 'all' : 'manual',
    })
  }

  return (
    <SegmentedControl
      style={{ flexShrink: 0 }}
      value={filter}
      onChange={setFilter}
      size="xs"
      data={[
        {
          value: 'all',
          label: 'All',
        },
        {
          value: 'manual',
          label: 'Manual',
        },
      ]}
    />
  )
})
