import { EditorState } from 'prosemirror-state'

import { ScriptFormatPayload } from '@util/ScriptoApiClient/types'

import { pageBreakerKey } from './constants.js'
import joiner from './joiner.js'
import getSplitterFunction from './splitter.js'
/**
 * Get start and end diff position values for two states (old, new).
 */
function getDiffRange(oldState: EditorState, newState: EditorState) {
  // https://prosemirror.net/docs/ref/#model.Fragment.findDiffStart
  const start = oldState.doc.content.findDiffStart(newState.doc.content) ?? 0
  // Important: diffEnd value is {a,b} object since end pos will differ.
  // https://prosemirror.net/docs/ref/#model.Fragment.findDiffEnd
  const end = oldState.doc.content.findDiffEnd(newState.doc.content)
  const a = end?.a ?? start
  let b = end?.b ?? start

  // WARNING: diffEnd may be lower than diffStart.
  // If so, add overlap to get correct range.
  // https://discuss.prosemirror.net/t/overlap-handling-of-finddiffstart-and-finddiffend/2856
  const overlap = start - Math.min(a, b)
  if (overlap > 0) {
    b += overlap
  }
  return { start, end: b }
}
export function getRepaginateFunction(
  format: ScriptFormatPayload['definition']['blocks'],
) {
  /**
   * Repaginate a document based on two differing states.
   * Optionally repaginate a full document if only on state is provided.
   * @param {EditorState} editorState - current PM editor state
   * @param {EditorState} oldState - old editor state
   * @returns {Transaction|null} - PM transaction or null
   */
  function repaginate(editorState: EditorState, oldState: EditorState) {
    let splitTr
    // FIXME: TODO: PROBLEM!
    // in a locked script,
    // when repaginating dynamic pages that have custom page numbers,
    // those numbers will be lost during a join operation.
    // SO! we need to retain those numbers and reapply them when splitting,
    // somehow...
    const splitterFunction = getSplitterFunction(format)
    if (!oldState) {
      const { tr: joinTr, pageNumbers } = joiner(editorState)
      splitTr = splitterFunction(joinTr, editorState, undefined, pageNumbers)
    } else {
      const diffRange = getDiffRange(oldState, editorState)
      const { tr: joinTr, pageNumbers } = joiner(editorState, diffRange)
      splitTr = splitterFunction(joinTr, editorState, diffRange, pageNumbers)
    }
    if (!splitTr || splitTr.doc.eq(editorState.doc)) {
      return null
    }
    return splitTr
      .setMeta('addToHistory', false) // pagination should never be undo-able
      .setMeta(pageBreakerKey, 'repaginate') // signal this is a pagination tr
  }
  return repaginate
}
