import React from 'react'

import { ActionIcon } from '@mantine/core'
import { compile } from 'path-to-regexp'
import { useLocation } from 'wouter'

import { FaIcon } from '@components/FaIcon'
import { useMst } from '@hooks'
import { ROUTE_PATTERNS } from '@util'

const toSnapshotLand = compile<{ scriptId: string }>(
  ROUTE_PATTERNS.scriptHistory,
)

export const SnapshotLandIcon = ({ scriptId }: { scriptId: string }) => {
  const { view } = useMst()
  const [, setLocation] = useLocation()

  const handleClick = () => {
    view.explorerState.setLastScriptPath(location.pathname)
    setLocation(toSnapshotLand({ scriptId }))
  }

  if (!view.snapshotLandEnabled) {
    return null
  }

  return (
    <ActionIcon color="dark.9" onClick={handleClick}>
      <FaIcon icon="fa-clock-rotate-left" />
    </ActionIcon>
  )
}
