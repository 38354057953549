import cn from 'classnames'
import {
  EditorState,
  PluginKey,
  Selection,
  TextSelection,
  Transaction,
} from 'prosemirror-state'
import { Decoration, DecorationSet, EditorView } from 'prosemirror-view'

import { MarkTypeMap, NodeTypeMap, schema } from '@showrunner/codex'

import { ILoadedScript } from '@state'
import { isNonEmptyTextSelection, uuid } from '@util'

export const commentMarkType = schema.marks[MarkTypeMap.COMMENT]

export type CommentInventory = {
  // keys are block ids, values are arrays of commentIds that start in
  // each block and the position of the block for a decoration
  blockToComments: {
    [blockId: string]: {
      commentIds: string[]
      blockPos: number
    }
  }

  // keys are the ids of comments, values are the block ID they appear
  // in and the doc posiiton of the mark (used for sorting threads in the
  // comment panel)
  commentMarkInfo: {
    [threadId: string]: {
      pos: number
      blockId: string
      resolved: boolean
    }
  }

  // when the user clicks the add thread gutter button, we start tracking the selection
  // in state. We need the pos to allow us to put the new comment form in the correct order in
  // the comment panel
  unsavedComment?: {
    id: string
    pos: number
    snippet: string
  }

  // if the user has their empty cursor in an unresolved comment mark, we
  // record the id of that mark here
  activeMarkId?: string
}

export const commentsPluginKey = new PluginKey<CommentInventory>('comments')

export const COMMENT_MARK_ACTIONS = {
  CREATE_UNSAVED: 'CREATE_UNSAVED',
  REMOVE_UNSAVED: 'REMOVE_UNSAVED',
} as const

type NewCommentAction = ValueOf<typeof COMMENT_MARK_ACTIONS>
function isNewCommentAction(value: unknown): value is NewCommentAction {
  return (
    typeof value === 'string' &&
    Object.values(COMMENT_MARK_ACTIONS).some((a) => a === value)
  )
}

const dispatchNewCommentAction = (
  editorView: EditorView,
  action: ValueOf<typeof COMMENT_MARK_ACTIONS>,
) => {
  const { tr } = editorView.state
  tr.setMeta(commentsPluginKey, action).setMeta('addToHistory', false)
  editorView.dispatch(tr)
}

export const startNewCommentMark = (editorView: EditorView) => {
  dispatchNewCommentAction(editorView, COMMENT_MARK_ACTIONS.CREATE_UNSAVED)
}

export const clearNewCommentMark = (editorView: EditorView) => {
  dispatchNewCommentAction(editorView, COMMENT_MARK_ACTIONS.REMOVE_UNSAVED)
}

// this is called after the user has saved the new thread to the
// database so that we can transform the unsaved thread into a comment mark
export const saveNewCommentMark = (editorView: EditorView) => {
  const { tr, selection } = editorView.state
  const inventory = commentsPluginKey.getState(editorView.state)

  // we'll remove the unsavedThread state no matter what
  tr.setMeta(commentsPluginKey, COMMENT_MARK_ACTIONS.REMOVE_UNSAVED).setMeta(
    'addToHistory',
    false,
  )

  // check to see if we've still got appropriate data to create
  // the comment mark
  if (inventory?.unsavedComment && isNonEmptyTextSelection(selection)) {
    const { id } = inventory.unsavedComment
    const { from, to, head } = selection
    tr.addMark(from, to, commentMarkType.create({ id }))
    tr.setSelection(TextSelection.create(tr.doc, head))
  }

  editorView.dispatch(tr)
  editorView.focus()
}

export const getSnippetAndPosFromSelection = ({
  selection,
  doc,
}: EditorState): CommentInventory['unsavedComment'] => {
  if (isNonEmptyTextSelection(selection)) {
    const { from, to } = selection

    // TODO: we want to use the clipboardTextSerializer
    // for this to get correct uppercasing
    const snippet = doc.textBetween(from, to, '\n')

    return {
      id: uuid(),
      pos: from,
      snippet,
    }
  }
}

export const getSavedCommentInventory = (
  state: EditorState,
): CommentInventory => {
  const result: CommentInventory = {
    commentMarkInfo: {},
    blockToComments: {},
  }

  // traverse to the direct descenants of PAGE and gather up all the
  // comment IDs within each of them
  state.doc.descendants((node, pos, parent) => {
    if (parent.type.name === NodeTypeMap.PAGE) {
      const blockId = node.attrs.id
      if (typeof blockId === 'string') {
        const commentIdsInBlock = new Set<string>()
        state.doc.nodesBetween(
          pos + 1,
          pos + 1 + node.content.size,
          (n, threadPos) => {
            if (n.isText) {
              // find the ids all the comments in this node that weren't already
              // found in previous blocks.
              n.marks
                .filter(
                  (m) =>
                    m.type === commentMarkType &&
                    m.attrs.id &&
                    !result.commentMarkInfo[m.attrs.id],
                )
                .map((m) => ({
                  threadId: m.attrs.id,
                  threadPos,
                  resolved: !!m.attrs.resolved,
                }))
                .forEach(({ threadId, threadPos, resolved }) => {
                  if (!resolved) {
                    commentIdsInBlock.add(threadId)
                  }
                  result.commentMarkInfo[threadId] = {
                    blockId,
                    pos: threadPos,
                    resolved,
                  }
                })

              if (commentIdsInBlock.size > 0) {
                const commentIdsInBlockArray = Array.from(
                  commentIdsInBlock.values(),
                )
                result.blockToComments[blockId] = {
                  blockPos: pos + 1,
                  commentIds: commentIdsInBlockArray,
                }
              }
            }
          },
        )
      }
      // don't recurse
      return false
    }
  })
  return result
}

// find the first unresolved thread at the cursor position. We use
// this information to highlight the current comment mark in the document
// when the comment panel is open.
export const getOpenThreadIdAtCursor = (
  selection: Selection,
): string | undefined => {
  if (selection instanceof TextSelection && selection.empty) {
    const mark = selection.$from
      .marks()
      .find(
        (m) =>
          m.type.name === MarkTypeMap.COMMENT &&
          !m.attrs.resolved &&
          typeof m.attrs.id === 'string',
      )
    return mark?.attrs.id
  }
}

const decorationForUnsavedComment = ({
  selection,
}: EditorState): Decoration | undefined => {
  if (isNonEmptyTextSelection(selection)) {
    const { from, to } = selection
    const deco = Decoration.inline(from, to, {
      class: 'prose-unsaved-comment',
    })
    return deco
  }
}

const gutterThreadDecorations = (
  inventory: CommentInventory,
  script: ILoadedScript,
): Decoration[] => {
  const decos: Decoration[] = []
  Object.entries(inventory.blockToComments).forEach(
    ([blockId, { blockPos, commentIds }]) => {
      const count = String(commentIds.length)
      const deco = Decoration.widget(
        blockPos,
        () => {
          const elt = document.createElement('i')
          elt.className = cn(
            'fa-solid comment-thread-bubble',
            commentIds.length > 1 ? 'fa-comments' : 'fa-comment',
          )
          elt.onclick = () =>
            script.commentData.handleClickCommentBubble(commentIds)
          return elt
        },
        {
          key: 'comment-bubble-' + blockId + count,
        },
      )
      decos.push(deco)
    },
  )
  return decos
}

export const getDecorations = (state: EditorState, script: ILoadedScript) => {
  const inventory = commentsPluginKey.getState(state)
  if (!inventory) {
    return DecorationSet.empty
  }

  const decos = gutterThreadDecorations(inventory, script)
  const unsavedDeco = decorationForUnsavedComment(state)
  if (unsavedDeco) {
    decos.push(unsavedDeco)
  }
  return DecorationSet.create(state.doc, decos)
}

export const commentActionForTransaction = (
  tr: Transaction,
  pluginState: CommentInventory,
): NewCommentAction | undefined => {
  // the transaction may be an explicit instruction
  // from the UI
  const meta = tr.getMeta(commentsPluginKey)
  if (isNewCommentAction(meta)) {
    return meta
  }

  // if the transaction explicitly set the selection (user deliberately changed
  // their cursor) then if we've got an unsaved comment, we want to blow it away
  if (tr.selectionSet && pluginState.unsavedComment) {
    return COMMENT_MARK_ACTIONS.REMOVE_UNSAVED
  }
}
