import cn from 'classnames'

import styles from './SelectedSideIcon.module.scss'

export const SelectedSideIcon = ({
  side,
  large,
}: {
  side: 'right' | 'left'
  large?: boolean
}) => (
  <div
    className={cn(styles.selectedSideIcon, {
      [styles.left]: side === 'left',
      [styles.right]: side === 'right',
      [styles.large]: large,
    })}
  >
    <div className={cn(styles.inner)} />
  </div>
)
