import React from 'react'

import { Button } from '@mantine/core'

import { schemas, ZInfer } from '@showrunner/scrapi'

import { Loader } from '@components/Loader'
import { showConfirmModal, showError } from '@components/Modals'
import { useMst } from '@hooks'

const MAX_TO_CONVERT = 100
type ConvertScriptsToInlineResult = {
  converted: string[]
  failures: Array<
    ZInfer<typeof schemas.UpdateProseDocError> & {
      id: string
    }
  >
  remaining: number
}

export const ConvertToInline = ({ orgId }: { orgId: string }) => {
  const { scrapi } = useMst()
  const [loading, setLoading] = React.useState(false)
  const [result, setResult] =
    React.useState<ConvertScriptsToInlineResult | null>(null)

  const handleClick = async () => {
    const answer = await showConfirmModal({
      title: 'Start conversion',
      children:
        'Are you sure you want to do this now? It will happen right away.',
    })
    if (answer) {
      setLoading(true)
      setResult(null)
      const response = await scrapi.staff.convertWorkspaceScriptsToInline({
        params: { id: orgId },
        body: { maxToConvert: MAX_TO_CONVERT },
      })
      setLoading(false)

      if (response.status === 200) {
        setResult(response.body)
        if (response.body.failures.length > 0) {
          showError('Some scripts failed to convert, see console')
          // eslint-disable-next-line no-console
          console.error(response.body)
        }
      } else {
        showError('See console for error')
        // eslint-disable-next-line no-console
        console.error(response)
      }
    }
  }

  return (
    <div>
      <h4>
        This will convert up to {MAX_TO_CONVERT} structural scripts in this
        workspace to inline.
      </h4>
      <Button onClick={handleClick}>Convert</Button>
      {result && (
        <>
          <div>converted: {result.converted.length}</div>
          <div>remaining: {result.remaining}</div>
        </>
      )}
      <Loader visible={loading} fullScreen />
    </div>
  )
}
