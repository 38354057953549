import { deepMerge } from '@mantine/core'
import { setBlockType } from 'prosemirror-commands'
import { Fragment, Node as PmNode } from 'prosemirror-model'
import {
  AllSelection,
  EditorState,
  NodeSelection,
  Plugin,
  Selection,
  TextSelection,
} from 'prosemirror-state'
import { RemoveMarkStep } from 'prosemirror-transform'
import { EditorView } from 'prosemirror-view'

import {
  AlignmentType,
  MarkTypeKey,
  MarkTypeMap,
  NodeTypeMap,
  schema,
  util,
} from '@showrunner/codex'

import {
  configDataPlugin,
  getConfigData,
} from '@choo-app/lib/editor/plugins/configData'
import { indentationStylesFactory } from '@choo-app/lib/editor/plugins/indentation-styles'
import {
  inlinePagebreaksKey,
  inlinePageBreaksPlugin,
} from '@choo-app/lib/editor/plugins/inlinePageBreaks'
import { inlineChangesPlugin } from '@choo-app/lib/editor/plugins/revision-asterisks'
import { syntaxHighlightingFactory } from '@choo-app/lib/editor/plugins/syntax-highlighting'
import { IRoot } from '@state'
import { notEmptyFilter } from '@util'
import { BlockInfo, isSectionDelineator } from '@util/constants'
import {
  BlockFormats,
  BlockOverrides,
  parseOverrides,
  ScriptFormatConfiguration,
} from '@util/formats'
import {
  ScriptJson,
  ScriptPayload,
  ScriptSnapshotPayload,
} from '@util/ScriptoApiClient/types'

import {
  checkWrap,
  isWrappedBlock,
  maybeAddWrapChars,
  maybeStripWrapChars,
  maybeTrimWhitespace,
} from './wrapped-block-helpers'

export * from './types'

const { BRACKET, CHARACTER, DIALOGUE, GENERAL, PARENTHETICAL } = NodeTypeMap

// same RE as in hyperlinker.js (without EOL token)
const HAS_URL =
  /(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62})?[a-z0-9\u00a1-\uffff]\.)+(?:[a-z\u00a1-\uffff]{2,}\.?))(?::\d{2,5})?(?:[/?#]\S*)?/i

export const createProsemirrorDoc = (json: ScriptJson) =>
  PmNode.fromJSON(schema, json)

export const createSnapshotEditorState = (
  snapshot: Pick<ScriptSnapshotPayload, 'doc' | 'scriptFormat'>,
  mst: IRoot,
): EditorState => {
  const isInk = snapshot.scriptFormat.definition.scriptType === 'ink'
  const plugins: Plugin[] = [
    configDataPlugin({ script: snapshot }),
    isInk ? indentationStylesFactory() : null,
    inlinePageBreaksPlugin(),
    isInk ? syntaxHighlightingFactory({ mst }) : null,
  ].filter(notEmptyFilter)

  const pmDoc = snapshot.doc
  return EditorState.create({
    doc: PmNode.fromJSON(schema, pmDoc),
    plugins,
  })
}

export const createComparisonEditorState = ({
  script,
  snapshot,
}: {
  script: Pick<ScriptPayload | ScriptSnapshotPayload, 'doc' | 'scriptFormat'>
  snapshot: Pick<ScriptSnapshotPayload, 'doc'>
}): EditorState => {
  const snapshotDoc = PmNode.fromJSON(schema, snapshot.doc)
  const plugins: Plugin[] = [
    configDataPlugin({ script }),
    inlinePageBreaksPlugin(),
    inlineChangesPlugin({ snapshotDoc }),
  ]

  return EditorState.create({
    doc: PmNode.fromJSON(schema, script.doc),
    plugins,
  })
}

export const incrementPushCount = (currValue: unknown): number => {
  const numericValue =
    typeof currValue === 'number' ? currValue : parseInt(String(currValue))
  return isNaN(numericValue) ? 1 : numericValue + 1
}

export const buildScriptBreakdown = (
  payload: ScriptPayload,
): util.ScriptBreakdown => {
  const pmDoc = PmNode.fromJSON(schema, payload.doc)
  return new util.ScriptBreakdown(pmDoc, payload.readRate)
}

const { SCENE_HEADING, SLUG, NEW_ACT } = NodeTypeMap
const isSleneStart = (node: PmNode): boolean => {
  return SCENE_HEADING === node.type.name || SLUG === node.type.name
}

const isSleneEnd = (node: PmNode): boolean => {
  return isSleneStart(node) || node.type.name === NEW_ACT
}

// Given a slug or sceneHeading ID, extract a fragment from the
// doc that starts with that ID and ends at the next new_act or slug or sceneHeading
export const extractSleneFragment = (
  doc: PmNode,
  sleneId: string,
): Fragment | null => {
  let startPosition = -1
  let endPosition: number | undefined = undefined
  doc.descendants((node, pos) => {
    if (startPosition < 0) {
      if (isSleneStart(node) && node.attrs.id === sleneId) {
        startPosition = pos
      }
    } else if (endPosition === undefined) {
      if (isSleneEnd(node)) {
        endPosition = pos
      }
    }
  })
  if (startPosition > -1) {
    const slice = doc.slice(startPosition, endPosition)
    return slice.content
  }
  return null
}

// extract a list of slene names/ids from a doc
export const listSlenes = (
  doc: PmNode,
): Array<{ id: string; label: string }> => {
  const result: Array<{ id: string; label: string }> = []
  doc.descendants((node) => {
    if (isSleneStart(node) && typeof node.attrs.id === 'string') {
      result.push({
        id: node.attrs.id,
        label: node.textContent,
      })
    }
  })

  return result
}

export const shouldEnableTimingExclusion = (state: EditorState) => {
  if (!state) return false
  const { doc, selection } = state
  if (selection instanceof AllSelection) return false

  let enabled = false
  doc.nodesBetween(selection.from, selection.to, (node) => {
    if (blockIsTimeable(node)) {
      enabled = true
    }
  })
  return enabled
}

export const isSelectionUntimed = (state: EditorState) => {
  if (!state) return false
  const { doc, selection } = state
  if (selection instanceof AllSelection) return false

  let untimed = false
  doc.nodesBetween(selection.from, selection.to, (node) => {
    if (node.attrs?.untimed) {
      untimed = true
    }
  })
  return untimed
}

export const toggleTimingExclusion = (
  state: EditorState,
  dispatch: Dispatch,
): void => {
  const { doc, selection, tr } = state

  // loop through all the nodes in the selection once to determine if any of
  // them are already untimed
  let prevUntimed = false
  doc.nodesBetween(selection.from, selection.to, (node) => {
    if (!blockIsTimeable(node)) return
    if (!prevUntimed) {
      prevUntimed = node.attrs.untimed === true
    }
  })

  // update exclusion for all timed nodes in the selection accordingly
  doc.nodesBetween(selection.from, selection.to, (node, position) => {
    if (!blockIsTimeable(node)) return
    const attrs = {
      ...node.attrs,
      untimed: prevUntimed ? null : true,
    }
    tr.setNodeMarkup(position, undefined, attrs, node.marks)
  })
  if (tr.steps.length > 0) {
    dispatch(tr)
  }
}

export const setAlignment = (
  alignment: AlignmentType,
  state: EditorState,
  dispatch: Dispatch,
) => {
  const { doc, selection, tr } = state
  // update alignment for all nodes in selection that have an alignment attr
  doc.nodesBetween(selection.from, selection.to, (node, position) => {
    // only process nodes
    // ignore pages
    // ignore nodes without alignment prop
    const shouldSkip =
      node.isText ||
      node.type.name === NodeTypeMap.PAGE ||
      !('alignment' in node.attrs)
    if (shouldSkip) {
      return
    }
    const newAttrs = { ...node.attrs, alignment }
    tr.setNodeMarkup(position, undefined, newAttrs, node.marks)
  })
  dispatch(tr)
}

// Checks if the currently selected text is something that can
// can be modified (e.g. can we format, align, etc)
export const hasModifiableSelectedText = (editorView: EditorView) => {
  const { state } = editorView
  const readOnly = !editorView.editable
  const noSelection = state.selection.empty
  const isDocSelection = state.selection.$anchor.depth === 0
  const selectionTooBig = isSelectionTooBig(state)

  return !(readOnly || noSelection || isDocSelection || selectionTooBig)
}

export const shouldDisableAlignment = (state: EditorState) => {
  if (state.selection instanceof AllSelection) {
    return true
  }
  const { doc, selection } = state
  let shouldDisable = true
  doc.nodesBetween(selection.from, selection.to, (node) => {
    // show alignment if node isn't text or page and has alignment prop
    if (
      !node.isText &&
      node.type.name !== NodeTypeMap.PAGE &&
      'alignment' in node.attrs
    ) {
      shouldDisable = false
    }
  })
  return shouldDisable
}

/**
 * Applies a link mark with the given href to the current selection.
 */
export const addLinkMarkToSelection = (
  editorView: EditorView,
  href: string,
) => {
  const { tr } = editorView.state
  const { from, to } = tr.selection
  const mark = schema.marks[MarkTypeMap.LINK].create({ href })
  tr.addMark(from, to, mark)
  tr.scrollIntoView().setMeta('paste', true).setMeta('uiEvent', 'paste')
  editorView.dispatch(tr)
}

// if the text being copy/pasted includes one or more URLs, we:
// 1. slice up the string to separate links and non-links
// 1. delete the existing selection (if present)
// 1. loop through our data structure backwards
// 1. injecting plain text as plain text
// 1. injecting urls as text with an appended mark
// we purposely avoid tr.mapping.map() because we
// need to refer to positions in the document that didnt exist
// prior to these intermediate steps
export const injectMarkedUpLinks = ({
  editorView,
  ds,
}: {
  editorView: EditorView
  ds: { text: string; url?: string }[]
}) => {
  const { tr } = editorView.state
  const { from, to } = tr.selection
  tr.insertText('', from, to)
  for (let i = ds.length - 1; i >= 0; i--) {
    // we use $from and not $to to ensure we account for the
    // selected text which might have just been obliterated
    tr.insertText(ds[i].text, from, from)
    if (ds[i].url) {
      const mark = schema.marks[MarkTypeMap.LINK].create({
        href: ds[i].url,
      })
      tr.addMark(from, from + ds[i].text.length, mark)
    }
  }
  tr.scrollIntoView().setMeta('paste', true).setMeta('uiEvent', 'paste')
  editorView.dispatch(tr)
}

// possibly expensive operation for big selections
export const selectionContainsFormattingMark = (state: EditorState) =>
  selectionContainsMark(MarkTypeMap.STRONG, state) ||
  selectionContainsMark(MarkTypeMap.EM, state) ||
  selectionContainsMark(MarkTypeMap.UNDERLINE, state) ||
  selectionContainsMark(MarkTypeMap.STRIKE, state)

export const LINK_MARK = schema.marks[MarkTypeMap.LINK]

const TIMED_BLOCKNAMES: readonly string[] = [
  BRACKET,
  DIALOGUE,
  CHARACTER,
  PARENTHETICAL,
  GENERAL,
]

const blockIsTimeable = (node: PmNode) =>
  !node.isText && TIMED_BLOCKNAMES.includes(node.type.name)

/**
 * Identifies selections that include 10 or more pages of content.
 * we arbitrarily disallow adding comments or marks to these 'big' selections
 */
export const isSelectionTooBig = (state: EditorState): boolean => {
  const { $from, $to } = state.selection
  const fromPageIndex = $from.index(0)
  const toPageIndex = $to.index(0)
  const span = toPageIndex - fromPageIndex

  if (span > 9) {
    return true
  }
  return false
}

/*
  HT https://discuss.prosemirror.net/t/some-pointers-in-creating-a-casing-plugin/2805/2
  insertText() is much more terse, but looping through individual TextNodes is a more dependable way to ensure that marks are preserved, even when the selection includes a mix of them.

  it also (hopefully) leaves us better poised to support selections that span multiple blocks in the future
*/
export const forceCaps = (editorView?: EditorView) => {
  if (!editorView) return
  const { dispatch } = editorView
  const { doc, schema } = editorView.state
  const tr = editorView.state.tr
  const selection = tr.selection
  let shouldUpdate = false
  doc.nodesBetween(selection.from, selection.to, (node, position) => {
    // only process text, must be a selection
    if (!node.isText || selection.from === selection.to) {
      return
    }
    // calculate the section of the current text node to replace
    const startPos = Math.max(position, selection.from)
    const endPos = Math.min(position + node.nodeSize, selection.to)
    // grab the content using offsets
    const substringFrom = Math.max(0, selection.from - position)
    const substringTo = Math.max(0, selection.to - position)
    // convert to all caps and ensure that the text is indeed altered
    const text = node.textBetween(substringFrom, substringTo)
    const capsText = text.toUpperCase()
    const marks = node.marks
    if (text !== capsText) {
      tr.replaceRangeWith(
        tr.mapping.map(startPos),
        tr.mapping.map(endPos),
        // create a new text node
        schema.text(capsText, marks),
      )
      shouldUpdate = true
    }
  })
  if (dispatch && shouldUpdate) {
    dispatch(tr)
  }
}

/**
 * lil selected helper cribbed from
 * https://github.com/ProseMirror/prosemirror-example-setup/blob/f84ad32ec79f9884709f5b50f4668bf34597a2b5/src/menu.ts#L58-L62
 *
 * returns true if current selection range has mark
 * or cursor sits on the righthand side of a marked character
 */
export const selectionContainsMark = (
  typeKey: MarkTypeKey,
  state: EditorState,
): boolean => {
  const type = schema.marks[typeKey]
  const { from, $from, to, empty } = state.selection
  if (empty) return !!type.isInSet(state.storedMarks || $from.marks())
  else return state.doc.rangeHasMark(from, to, type)
}

export const shouldDisableFormatting = (editorView: EditorView) => {
  const { state } = editorView
  const readOnly = !editorView.editable
  const isDocSelection = state.selection.$anchor.depth === 0
  const selectionTooBig = isSelectionTooBig(state)

  return readOnly || isDocSelection || selectionTooBig
}

export const isSingleBlockTextSelection = (state: EditorState) => {
  const { selection } = state
  if (!(selection instanceof TextSelection)) return false
  return selection.$anchor.parent.eq(selection.$head.parent)
}

export const selectionHasUrl = (state: EditorState) => {
  const selectedText = state.doc.textBetween(
    state.selection.from,
    state.selection.to,
  )
  return HAS_URL.test(selectedText)
}

/**
 * Get the nearest block node from the beginning of the current selection.
 */
export const getSelectionBlock = (state: EditorState): PmNode | undefined => {
  const { selection } = state
  if (selection instanceof NodeSelection) {
    return selection.node
  }
  if (selection instanceof TextSelection) {
    return selection.$from.parent
  }
}

/**
 * Determine block type of the beginning of the current selection.
 */
export const getSelectionBlockType = (state: EditorState): string | undefined =>
  getSelectionBlock(state)?.type.name

/**
 * Uses either the PM setBlockType command or something more finegrained
 * when we need to manipulate the block prior to changing its type
 */
export const setEditorBlockType = (
  state: EditorState,
  dispatch: Dispatch,
  newBlockType: string,
) => {
  // if the selection isnt a plain cursor or text confined
  // to a single block, we update in bulk without all the massaging
  if (!isSingleBlockTextSelection(state)) {
    setEditorBulkBlockType(state, dispatch, newBlockType)
    return
  }

  // the JS code i ported  claimed (falsely) to ignore node selections

  const { $from } = state.selection
  const currBlock = getSelectionBlock(state)
  const currBlockType = currBlock?.type.name
  if (!currBlockType) return

  // if the block type isnt actually changing, abort early
  if (currBlockType === newBlockType) return

  // pluck attributes to pass through when changing block type
  const { attrs } = currBlock
  const nodeType = schema.nodes[newBlockType]

  // if neither block type is wrapped, delegate to PM
  if (!isWrappedBlock(currBlockType) && !isWrappedBlock(newBlockType)) {
    setBlockType(nodeType, attrs)(state, dispatch)
    return
  }

  // if the old block type was wrapped, delete the wrapping (if present)
  // and preserve the selection and pass through existing attributes
  let { tr } = state
  const start = $from.start()

  // trim whitespace at the end of the block only if needed
  tr = maybeTrimWhitespace(tr)
  const wasWrapped = checkWrap(tr)
  // strip stale wrapping chars if needed
  tr = maybeStripWrapChars(tr)
  // use PM to change the actual blocktype and preserve attrs
  tr.setBlockType(start, start, nodeType, attrs)
  // if the new block type is wrapped, do the wrapping if it wasnt done already
  tr = maybeAddWrapChars(tr, wasWrapped)
  // dispatch a transaction to make the edit real
  dispatch(tr)
}

/**
 * loop through multiblock selections and dispatch a single PM transaction to change their type
 */
export const setEditorBulkBlockType = (
  state: EditorState,
  dispatch: Dispatch,
  blockType: string,
) => {
  const { doc, selection, tr } = state
  doc.nodesBetween(selection.from, selection.to, (node, pos, parent) => {
    const isDifferent = node.type.name !== blockType
    if (isStandardBlock({ node, parent }) && isDifferent) {
      const start = pos + 1 // shift inside from the block boundary
      tr.setBlockType(start, start, schema.nodes[blockType], node.attrs)
    }
  })
  if (tr.docChanged) dispatch(tr)
}

// if we call editorView.coordsAtPos after it's been destroyed,
// then prosemirror throws. This is just a race condition, so use
// soemthing with the right shape
const DUMMY_RECT = Object.freeze({ top: 0, bottom: 0, left: 0, right: 0 })
// When a bunch of text is selected on the screen, this gives us the
// viewport coordinates of a rectangle that encloses the selected text
export const absoluteSelectionRect = (
  editorView: EditorView,
  selection: TextSelection | NodeSelection,
): {
  top: number
  left: number
  bottom: number
  right: number
} => {
  if (editorView.isDestroyed) {
    return { ...DUMMY_RECT }
  }
  // we destructure here because, for node selections only,
  // PM returns a DOMRectReadOnly
  let { top, left, bottom, right } = editorView.coordsAtPos(selection.from)
  for (let i = selection.from + 1; i < selection.to; i++) {
    const coords = editorView.coordsAtPos(i)
    top = Math.min(coords.top, top)
    left = Math.min(coords.left, left)
    bottom = Math.max(coords.bottom, bottom)
    right = Math.max(coords.right, right)
  }

  return { top, left, bottom, right }
}

const scaleForZoom = (
  { left, right, top, bottom }: Rect,
  zoomLevel: number,
): Rect => {
  return {
    left: left / zoomLevel,
    right: right / zoomLevel,
    top: top / zoomLevel,
    bottom: bottom / zoomLevel,
  }
}

// use viewport coordinates to figure out the relative
// positioning of an element to a parent element
const relativeToParent = (
  { left, right, top, bottom }: Rect,
  parent: Element,
): Rect => {
  const eltEdges = parent.getBoundingClientRect()
  return {
    left: left - eltEdges.left,
    // yes, right is relative to the left of the element
    right: right - eltEdges.left,
    top: top - eltEdges.top,
    // yes, bottom is relative to the top of the element
    bottom: bottom - eltEdges.top,
  }
}

// Use this to get the selection rect relative to the editor view
// (e.g. to position something inside the editor).
export const relativeSelectionRect = ({
  editorView,
  selection,
  zoomLevel,
}: {
  zoomLevel: number
  editorView: EditorView
  selection: TextSelection | NodeSelection
}): {
  left: number
  right: number
  bottom: number
  top: number
} => {
  if (editorView.isDestroyed) {
    return { ...DUMMY_RECT }
  }
  const absoluteEdges = absoluteSelectionRect(editorView, selection)
  const relativeEdges = relativeToParent(absoluteEdges, editorView.dom)
  return scaleForZoom(relativeEdges, zoomLevel)
}

export const selectionHeadPosition = ({
  editorView,
  selection,
  zoomLevel,
}: {
  zoomLevel: number
  editorView: EditorView
  selection: TextSelection | NodeSelection
}): Rect | undefined => {
  if (editorView.isDestroyed) {
    return { ...DUMMY_RECT }
  }

  const coords = relativeToParent(
    editorView.coordsAtPos(selection.$head.pos),
    editorView.dom,
  )
  return scaleForZoom(coords, zoomLevel)
}

// Test to ensure that a PmNode is a textBlock and is not
// something weird like a dual dialogue block
export const isStandardBlock = ({
  node,
  parent,
}: {
  node: PmNode
  parent: PmNode
}) => node.isTextblock && parent.type.name === NodeTypeMap.PAGE

/**
 * inspects the active selection for comments and retrieves their id(s).
 * we gather comments contained in a cut in order to preserve them on subsequent paste.
 */
export function retrieveCommentIds(viewState: EditorState): string[] {
  const { from, to } = viewState.selection
  const ids: string[] = []
  viewState.doc.nodesBetween(from, to, (node) => {
    node.marks.forEach((m) => {
      if (m.type.name === MarkTypeMap.COMMENT) {
        ids.push(m.attrs.id)
      }
    })
  })
  return ids
}

export const updateCommentMark = ({
  commentId,
  editorView,
  resolved,
}: {
  resolved: boolean
  commentId: string
  editorView: EditorView
}) => {
  const { tr, doc } = editorView.state
  const size = doc.nodeSize - 2
  const oldValueMark = schema.marks[MarkTypeMap.COMMENT].create({
    id: commentId,
    resolved: !resolved,
  })
  const removed = tr.removeMark(0, size, oldValueMark)
  if (removed.steps.length) {
    removed.steps.forEach((step) => {
      const newMark = schema.marks[MarkTypeMap.COMMENT].create({
        id: commentId,
        resolved,
      })
      // i doubt we'll need to cast this manually when we upgrade prosemirror-transform
      const removeStep = step as RemoveMarkStep
      tr.addMark(removeStep.from, removeStep.to, newMark)
      if (!resolved) {
        editorView.focus()
        tr.setSelection(
          TextSelection.create(tr.doc, removeStep.from),
        ).scrollIntoView()
      }
    })
    tr.setMeta('addToHistory', false)
    editorView.dispatch(tr)
    editorView.focus()
  }
}

export const goToHtmlNode = ({
  editorView,
  domNode,
}: {
  editorView: EditorView
  domNode: HTMLElement
}) => {
  const { tr, doc } = editorView.state
  try {
    const pos = editorView.posAtDOM(domNode, 0)
    if (pos > -1) {
      editorView.focus()
      tr.setSelection(TextSelection.create(doc, pos)).scrollIntoView()
      editorView.dispatch(tr)
    }
  } catch {
    // noop
  }
}

export const reorderScenes = async ({
  oldIndex,
  newIndex,
  navLinks,
  editorView,
}: {
  oldIndex: number
  newIndex: number
  navLinks: BlockInfo[]
  editorView: EditorView
}) => {
  const editorState = editorView.state
  // end of doc minus page token (very wtf)
  const lastPos = editorState.doc.content.size - 1
  // range for the scene/slug to be shuffled
  const from = navLinks[oldIndex].pos
  // find the next SCENE/SLUG/ACT in the list of blocks in script nav
  // to create the relevant document slice. (ie: ignore brackets)
  // if we make it to the end without finding anything
  // we use the last position in the document
  let to = lastPos
  let x = oldIndex + 1
  while (x < navLinks.length) {
    if (isSectionDelineator(navLinks[x].type)) {
      to = navLinks[x].pos
      break
    }
    x++
  }
  // the new position might not exist yet
  const newTo =
    newIndex + 1 <= navLinks.length ? navLinks[newIndex].pos : lastPos
  const tr = editorState.tr
  // place the cursor for the paste
  tr.setSelection(TextSelection.create(tr.doc, newTo))
  // paste
  tr.replaceSelection(editorState.doc.slice(from, to))
  // cut (mapped)
  tr.deleteRange(tr.mapping.map(from), tr.mapping.map(to))
  editorView.dispatch(tr)
}

export const createCommentMark = ({
  id,
  editorView,
}: {
  id: string
  editorView: EditorView
}) => {
  const { schema, selection, tr } = editorView.state
  const mark = schema.marks[MarkTypeMap.COMMENT].create({ id })
  const { from, to } = selection
  tr.setMeta('addToHistory', false).addMark(from, to, mark)
  editorView.dispatch(tr)
  editorView.focus()
}

export const removeCommentMark = ({
  id,
  editorView,
}: {
  id: string
  editorView: EditorView
}) => {
  const { tr, doc } = editorView.state
  const size = doc.nodeSize - 2
  const mark = schema.marks[MarkTypeMap.COMMENT].create({ id })
  tr.removeMark(0, size, mark)
  tr.setMeta('addToHistory', false)
  editorView.dispatch(tr)
}

export const isNonEmptyTextSelection = (selection: Selection) => {
  return selection instanceof TextSelection && !selection.empty
}

export function cursorInDualDialogueBlock(viewState: EditorState): boolean {
  if (!(viewState.selection instanceof TextSelection)) return false
  const { $cursor } = viewState.selection
  return !!($cursor && $cursor.depth === 4)
}

export const defaultBlockType = schema.nodes[NodeTypeMap.DIALOGUE]

export function getPageCount(editorState: EditorState): number {
  const { paginationType } = getConfigData(editorState)
  if (paginationType === 'structural') {
    return editorState.doc.childCount
  }

  if (paginationType === 'inline') {
    const pluginState = inlinePagebreaksKey.getState(editorState)
    if (pluginState) {
      return pluginState.data.pageCount
    }
  }

  return 0
}

/*
  If the formatInfo node is present on the doc, replace the blockOverrides
  value with the one passed in. If there is no formatInfo node on the doc,
  create one.

  The formatInfo node (if one exists) will be the last level 1 child of the
  doc.
*/
export const setBlockOverrides = (
  editorView: EditorView,
  blockOverrides: BlockOverrides,
) => {
  const { doc, tr } = editorView.state
  const pos = doc.content.size - 1
  const nodeAtPos = doc.resolve(pos).node(1)
  const isFormatNode = nodeAtPos.type.name === NodeTypeMap.FORMAT_INFO
  if (isFormatNode) {
    tr.setNodeMarkup(pos - 1, undefined, { ...nodeAtPos.attrs, blockOverrides })
  } else {
    const nodeType = schema.nodes[NodeTypeMap.FORMAT_INFO]
    tr.insert(pos, nodeType.create({ blockOverrides }))
  }
  editorView.dispatch(tr)
}

export const getBlockOverrides = (doc: PmNode): BlockOverrides => {
  const pos = doc.content.size - 1
  const nodeAtPos = doc.resolve(pos).node(1)
  const isFormatNode = nodeAtPos.type.name === NodeTypeMap.FORMAT_INFO
  return isFormatNode ? parseOverrides(nodeAtPos.attrs.blockOverrides) : {}
}

export const getMergedBlockFormats = (
  doc: PmNode,
  baseFormat: BlockFormats,
): BlockFormats => {
  const overrides = getBlockOverrides(doc)
  return overrides ? deepMerge(baseFormat, overrides) : baseFormat
}

export const getMergedFormatDefinition = (
  doc: PmNode | ScriptJson,
  definition: ScriptFormatConfiguration,
): ScriptFormatConfiguration => {
  const proseDoc = doc instanceof PmNode ? doc : createProsemirrorDoc(doc)
  const blocks = deepMerge(definition.blocks, getBlockOverrides(proseDoc))
  const paginationType = doc.attrs.paginationType ?? definition.paginationType
  return {
    ...definition,
    blocks,
    paginationType,
  }
}

export const setBlockFormatAttr = ({
  key,
  value,
  editorView,
}: {
  key: 'lineHeight' | 'blockTopMargin' | 'marginLeft' | 'width'
  value: number | null
  editorView: EditorView
}) => {
  const { doc, selection, tr } = editorView.state
  // update all nodes in selection that have the attr
  doc.nodesBetween(selection.from, selection.to, (node, position) => {
    const shortCircuit =
      node.isText || node.type.name === NodeTypeMap.PAGE || !(key in node.attrs)

    if (shortCircuit) return

    const newAttrs = { ...node.attrs, [key]: value }
    tr.setNodeMarkup(position, undefined, newAttrs, node.marks)
  })
  if (!tr.docChanged) return
  editorView.dispatch(tr)
  editorView.focus()
}
