import { StandardBlockConfig } from '@util/formats'

export type ConfiguredMarginValue = Pick<
  StandardBlockConfig,
  'marginLeft' | 'width'
>
export type SliderPositions = {
  leftPx: number
  rightPx: number
}

export type TickData = {
  label?: string
}

export type AllowedRange = {
  minLeftPx: number
  maxRightPx: number
  minWidthPx: number
}

export type RulerHelperParams = {
  displayedUnitSize: number
  ticksPerUnit: number
  unscaledWidthPx: number
  allowedRange: AllowedRange
}

// in practice, this is the only ruler configuration we're
// using today, but let's leave the door open for things like
// landscape, A4 or changing our minds about how many ticks per unit
// we want
const inToPx = (inches: number) => 96 * inches
const chToPx = (chars: number) => 9.6 * chars
const pxToCh = (px: number) => px / 9.6
// multiply by 10, round, then divide by 10
const roundToTenths = (value: number) => {
  const tooBig = Math.round(value * 10)
  return tooBig / 10
}
// when a left margin of 0 is specified, it is actually
// a hard-coded value that means 1.5 inches
const LEFT_MARGIN_ADJUST_PX = inToPx(1.5)

export const US_PORTRAIT_CONFIG: RulerHelperParams = {
  displayedUnitSize: 8.5,
  ticksPerUnit: 10,
  unscaledWidthPx: 8.5 * 96, // inches to pixels
  allowedRange: {
    minLeftPx: inToPx(1.5),
    maxRightPx: inToPx(7.5),
    minWidthPx: inToPx(1),
  },
}

const buildTicks = ({ displayedUnitSize, ticksPerUnit }: RulerHelperParams) => {
  const ticks: TickData[] = []
  const totalTicks = displayedUnitSize * ticksPerUnit
  for (let i = 0; i < totalTicks; i++) {
    const data: TickData = {}
    if (i % ticksPerUnit === 0) {
      data.label = String(i / ticksPerUnit)
    }
    ticks.push(data)
  }
  return ticks
}

// Margin rulers are configurable (different widths, pixels per unit)
// etc) but we don't change that at runtime (and maybe not even at build-time).
// So, all that goo is wrapped in here with helpers so we CAN change it
// but it doesn't cause extra rendering, etc.
export class RulerHelper {
  private readonly displayedUnitSize: number
  private readonly ticksPerUnit: number
  private readonly unscaledWidthPx: number
  private readonly displayedToPixelsFactor: number
  readonly ticks: TickData[]

  constructor(config: RulerHelperParams) {
    const { displayedUnitSize, ticksPerUnit, unscaledWidthPx } = config
    this.displayedUnitSize = displayedUnitSize
    this.ticksPerUnit = ticksPerUnit
    this.unscaledWidthPx = unscaledWidthPx
    this.displayedToPixelsFactor = unscaledWidthPx / displayedUnitSize
    this.ticks = buildTicks(config)
  }

  toPixels = (displayedValue: number) =>
    displayedValue * this.displayedToPixelsFactor

  buildRulerData = (): {
    ticks: TickData[]
    tickWidthPx: number
  } => {
    const ticks: TickData[] = []
    const totalTicks = this.displayedUnitSize * this.ticksPerUnit
    for (let i = 0; i < totalTicks; i++) {
      const data: TickData = {}
      if (i % this.ticksPerUnit === 0) {
        data.label = String(i / this.ticksPerUnit)
      }
      ticks.push(data)
    }
    return {
      ticks,
      tickWidthPx: this.unscaledWidthPx / totalTicks,
    }
  }

  get tickWidthPx(): number {
    return this.unscaledWidthPx / this.ticks.length
  }
}

// a margin configuration in a script format looks like
// { marginLeft: number; width: number }
// and the units are in ch with 0 for margin left representing
// 1.5 inches (just because...)  This helper transforms that
// into an absolute left in pixels
export const marginConfigToSliderPositions = (
  margins: ConfiguredMarginValue,
): SliderPositions => {
  const leftOffsetPx = chToPx(margins.marginLeft)
  const leftPx = leftOffsetPx + LEFT_MARGIN_ADJUST_PX
  const widthPx = chToPx(margins.width)
  const rightPx = leftPx + widthPx
  return {
    leftPx,
    rightPx,
  }
}

export const sliderPositionToMarginConfig = ({
  leftPx,
  rightPx,
}: SliderPositions): ConfiguredMarginValue => {
  const adjustedLeft = leftPx - LEFT_MARGIN_ADJUST_PX
  const widthPx = rightPx - leftPx

  return {
    marginLeft: roundToTenths(pxToCh(adjustedLeft)),
    width: roundToTenths(pxToCh(widthPx)),
  }
}
