import { PluginKey } from 'prosemirror-state'
import { Decoration } from 'prosemirror-view'

export const CHANGED_CSS_CLASS = 'o-script-content__revised'
export const REMOVED_CSS_CLASS = 'o-script-content__removed'

type RevisionPluginState = {
  changedRanges: [number, number][]
  removedPositions: number[]
}

// Once attached to the DOM we compute where to put revision asterisks.
// This is the data type we use for the map that tracks them
export type AsteriskPositionData = {
  [top: number]: {
    type: 'changed' | 'removed'
    blockId: string
    blockYOffset: number
  }
}

export const PLUGIN_KEY = new PluginKey<RevisionPluginState>(
  'REVISION_COMPARE_PLUGIN',
)

export const buildChangedDecoration = ([from, to]: [
  from: number,
  to: number,
]) =>
  Decoration.inline(from, to, {
    class: CHANGED_CSS_CLASS,
  })

export const buildRemovedDecoration = (position: number) =>
  Decoration.widget(
    position,
    function toDOM() {
      const el = document.createElement('span')
      el.className = REMOVED_CSS_CLASS
      return el
    },
    { side: 1 },
  )
