import axios from 'axios'
import { Step } from 'prosemirror-transform'

import { schema } from '@showrunner/codex'

import {
  delay,
  isPaginationType,
  PaginationType,
  reloadWindow,
  ScrapiClient,
  ScriptoApiClient,
} from '@util'
import { DatadogClient } from '@util/datadog'
import {
  CommonGetStepsPayload,
  CreateStepsParams,
} from '@util/ScriptoApiClient/types'
const ddLog = DatadogClient.getInstance()

const isConsecutiveInt = (v: number, i: number, arr: number[]) => {
  const isInt = Number.isInteger(v)
  if (!isInt) {
    return false
  }
  if (i === 0) {
    return true
  }
  return v === arr[i - 1] + 1
}
/**
 * When we get steps from the server, validate that we should even try to apply
 * them. Log to datadog about invalid steps
 */
export const validateConfirmedSteps = (
  stepData: CommonGetStepsPayload['steps'],
  localServerVersion: number,
) => {
  // compare steps to current version
  const stepVersions = stepData.map(({ version }) => version)
  // This is normal- we got back an async response for GetSteps that's no
  // longer the thing we need
  if (stepVersions[0] !== localServerVersion) {
    return false
  }
  const validVersions = stepVersions.every(isConsecutiveInt)
  if (!validVersions) {
    const message = 'applyConfirmedSteps called with non-consecutive versions'
    const details = {
      localVersion: localServerVersion,
      stepVersions: stepVersions,
    }
    ddLog.warn(message, details)
    return false
  }
  return true
}

export const convertStepData = (
  stepData: CommonGetStepsPayload['steps'],
): {
  steps: Step[]
  collabIds: string[]
  paginationType?: PaginationType
} => {
  const steps = stepData.map(({ step }) => Step.fromJSON(schema, step))
  const collabIds = stepData.map(({ clientId }) => clientId)
  // When the server converts a script to inline, it adds a paginationType
  // attribute to the step, which prosemirror ignores. We pull out the last one
  // (if it exists) and return it
  const paginationTypes = stepData
    .map(({ step }) => step.paginationType)
    .filter((item) => isPaginationType(item))
  const paginationType = paginationTypes.pop()
  return { steps, collabIds, paginationType }
}

/*
  Pushing steps has gnarly edge cases. The server
  returns a 200 for success or for the "normal" failure
  of being out of date.

  In non-200 cases we want to swallow some errors and
  not others. This wraps all those case
*/
type PushStepsResult =
  | {
      type: 'success' | 'behind'
      version: number
    }
  | {
      type: 'failure'
    }

const getErrorInfo = (
  e: unknown,
):
  | {
      isOffline: true
    }
  | {
      isOffline: false
      userFacingMessage: string
      code?: number
    } => {
  if (axios.isAxiosError(e) && !e.response) {
    return {
      isOffline: true,
    }
  } else {
    const userFacingMessage = axios.isAxiosError(e) ? e.message : 'Unknown'

    return {
      isOffline: false,
      userFacingMessage,
      code: axios.isAxiosError(e) ? e.response?.status : undefined,
    }
  }
}

// When we get errors making push/pull calls, introduce a little delay
// so we don't run the sync cycle too fast and spam the server or our logs
// (ideally, we'd be smarter about retry logic)
const slowYourRoll = () => delay(1)

export const pushStepsAndHandleErrors = async ({
  apiClient,
  params,
  debugDelay,
}: {
  debugDelay?: number
  apiClient: ScriptoApiClient
  params: CreateStepsParams
}): Promise<PushStepsResult> => {
  try {
    if (debugDelay) {
      await delay(debugDelay)
    }
    const { success, version } = await apiClient.createSteps(params)
    return {
      type: success ? 'success' : 'behind',
      version,
    }
  } catch (e: unknown) {
    const errorInfo = getErrorInfo(e)

    // If not a normal error, log details to datadog and
    // launch a user facing message
    if (!errorInfo.isOffline) {
      const { steps, ...info } = params
      ddLog.warn(
        'create steps failed',
        {
          ...info,
          stepCount: steps.length,
        },
        e,
      )

      if (errorInfo.code === 403) {
        window.alert(
          'You no longer have permission to edit this script. We need to reload the page',
        )
        await reloadWindow()
      }
    }

    await slowYourRoll()
    return {
      type: 'failure',
    }
  }
}

type PullStepsResult =
  | {
      success: true
      result: CommonGetStepsPayload
    }
  | {
      success: false
      message?: string
      noRetry?: boolean
    }

export const pullStepsFromScrapiAndHandleErrors = async ({
  scrapiClient,
  debugDelay,
  scriptId,
  fromVersion,
}: {
  scriptId: string
  fromVersion: number
  debugDelay?: number
  scrapiClient: ScrapiClient
}): Promise<PullStepsResult> => {
  try {
    if (debugDelay) {
      await delay(debugDelay)
    }

    const newResult = await scrapiClient.scripts.getDocumentUpdates({
      params: { id: scriptId },
      body: { fromVersion },
    })

    if (newResult.status === 200) {
      return {
        success: true,
        result: newResult.body,
      } as PullStepsResult
    }
    if (newResult.status === 420) {
      // This is awkward, we really want the editor manager to
      // stop the sync cycle, but until we have that wired, up, we
      // at least want to delay the next attempt so we don't trigger
      // an out-of-control sync cycle loop.
      //
      // once we stop the sync cycle when noRetry = true, we can remove
      // this delay
      await slowYourRoll()
      return {
        success: false,
        noRetry: true,
        message: 'Script is too far out of date to catch up',
      }
    }

    throw new Error('Unknown get steps error')
  } catch (e) {
    const errorInfo = getErrorInfo(e)
    // Show user facing error except for offline errors. Those are
    // better handled by the sync status
    if (!errorInfo.isOffline) {
      ddLog.warn('get steps from scrapi failed', {
        scriptId,
        fromVersion,
        err: e,
      })
    }

    await slowYourRoll()
    return {
      success: false,
    }
  }
}
