import React from 'react'

import cn from 'classnames'
import { observer } from 'mobx-react-lite'

import { getSelFormatBlockName } from '@components/EditorToolbar/Formatting/SpacingMenu/helpers'
import { useMst } from '@hooks'
import { isSingleBlockTextSelection, setBlockFormatAttr } from '@util'

import {
  ConfiguredMarginValue,
  RulerHelper,
  RulerHelperParams,
} from './helpers'
import { MarginSlider } from './MarginSlider'

import styles from './MarginRuler.module.scss'

// we round to the nearest whole (ch) value before comparing
// and return null if those values are equal to remove the override entirely
const nullIfEqualish = (fresh: number, existing: number): number | null =>
  Math.round(fresh) === Math.round(existing) ? null : fresh

type MarginRulerProps = {
  background?: React.CSSProperties['background']
  config: RulerHelperParams
  key: string
  margins?: ConfiguredMarginValue
  onOverrideMargins?: (value: ConfiguredMarginValue) => void
  disabled?: boolean
}

const Tick = ({ label }: { label?: string }) => (
  <span className={cn(styles.tick, { [styles.majorTick]: label })}>
    {label && <span className={styles.tickLabel}>{label}</span>}
  </span>
)

// exported for storybooks
export const MarginRulerComponent = (props: MarginRulerProps) => {
  const [helper] = React.useState(new RulerHelper(props.config))

  return (
    <div
      key={props.key}
      className={styles.marginRuler}
      style={{
        background: props.background,
        width: props.config.unscaledWidthPx,
        '--tick-width': `${helper.tickWidthPx}px`,
      }}
    >
      <MarginSlider
        constraints={props.config.allowedRange}
        margins={props.margins}
        onChange={props.onOverrideMargins}
        disabled={props.disabled}
      />
      {helper.ticks.map(({ label }, idx) => (
        <Tick label={label} key={idx} />
      ))}
    </div>
  )
}

// this is an observable component that relies on mst.currentScript. Ideally
// we would pass a script in but the script wrappers are really gnarly
// and it's not the easiest place to do that
export const MarginRuler = observer(function MarginRuler(
  props: Omit<MarginRulerProps, 'margins' | 'onOverrideMargins' | 'disabled'>,
) {
  const { currentScript, socketManager } = useMst()
  if (!(currentScript && currentScript.pmEditor.editorView)) {
    return null
  }

  const { editorView, editorState } = currentScript.pmEditor

  // TODO: do we have a one-stop-shop for whether or not the script is
  // CURRENTLY editable?
  const disabled =
    !currentScript.isEditable ||
    currentScript.pmEditor.editabilityLost ||
    !socketManager.connected ||
    !editorState ||
    !isSingleBlockTextSelection(editorState)

  const blockName = getSelFormatBlockName(
    currentScript.pmEditor.editorView.state,
  )
  if (!blockName) return null
  const blockFormat = currentScript.scriptFormat.definition.blocks[blockName]

  const { attrs } =
    currentScript.pmEditor.editorView.state.selection.$from.parent

  const marginLeft = attrs?.marginLeft ?? blockFormat.marginLeft
  const width = attrs?.width ?? blockFormat.width

  const handleOverride = (override: ConfiguredMarginValue) => {
    const ml = nullIfEqualish(
      override.marginLeft,
      currentScript.currentFormatValue(blockName, 'marginLeft'),
    )
    const w = nullIfEqualish(
      override.width,
      currentScript.currentFormatValue(blockName, 'width'),
    )
    setBlockFormatAttr({ key: 'marginLeft', value: ml, editorView })
    setBlockFormatAttr({ key: 'width', value: w, editorView })
  }

  return (
    <MarginRulerComponent
      {...props}
      margins={{ marginLeft, width }}
      onOverrideMargins={handleOverride}
      disabled={disabled}
    />
  )
})

// this handles the scaling and positioning needed to
// have the margin ruler work in the PrintPreviewWrapper
export const StickyRulerWrapper = ({
  zoomLevel,
  positioning,
  leftAffordance,
  children,
}: {
  // left is used when there's horizontal scrolling
  positioning: 'left' | 'center'
  // when left, this is the unscaled value that pads the
  // left side of the script (to accommodate avatars, etc)
  leftAffordance: number
  zoomLevel: number
  // this is meant to be a MarginRuler
  children: React.ReactNode
}) => {
  const marginLeft =
    positioning === 'left' ? leftAffordance * zoomLevel : undefined
  return (
    <div
      className={cn(styles.marginRulerWrapper, {
        [styles.leftAligned]: positioning === 'left',
      })}
      style={{
        '--ruler-zoom': zoomLevel,
        marginLeft,
      }}
    >
      {children}
    </div>
  )
}
