import { observer } from 'mobx-react-lite'

import { SnapshotViewerBase } from '@components/SnapshotViews/SnapshotViewer'
import { useMst } from '@hooks'
import { createSnapshotEditorState } from '@util'
import { ScriptSnapshotPayload } from '@util/ScriptoApiClient/types'

import { useSnapshotData } from '../useSnapshotLandData'

import { BodyPlaceholder } from './BodyPlaceholder'

const PopulatedStaticView = ({
  snapshot,
}: {
  snapshot: Pick<ScriptSnapshotPayload, 'doc' | 'scriptFormat'>
}) => {
  const mst = useMst()
  const { pageless, editorZoom } = mst.user.prefs
  const editorState = createSnapshotEditorState(snapshot, mst)

  return (
    <SnapshotViewerBase
      pageless={pageless}
      editorZoom={editorZoom}
      editorState={editorState}
      format={snapshot.scriptFormat}
    />
  )
}

// Once user has supplied an ID
const RequestedStaticView = observer(function StaticView({
  scriptId,
  snapshotId,
}: {
  scriptId: string
  snapshotId: string
}) {
  const query = useSnapshotData({ scriptId, snapshotId })
  if (query.isPending) {
    return <BodyPlaceholder variant="loading" />
  }
  if (query.isError) {
    return <BodyPlaceholder variant="error" />
  }

  return <PopulatedStaticView snapshot={query.data} />
})

export const StaticView = observer(function StaticView({
  scriptId,
}: {
  scriptId: string
}) {
  const { snap1 } = useMst().view.snapshotLand.params
  if (!snap1) {
    return <BodyPlaceholder variant="pickOne" />
  }

  return <RequestedStaticView scriptId={scriptId} snapshotId={snap1} />
})
