import { useViewportSize } from '@mantine/hooks'
import { observer } from 'mobx-react-lite'

import { Toolbar } from '@components/Toolbar'
import { TabItem } from '@components/Toolbar/ToolbarTabs'
import { useMst } from '@hooks'
import { SnapshotViewType } from '@state/models/View/SnapshotLandState'

import { ExportMenu } from './ExportMenu'
import { SideBySideViewMenu } from './SideBySideViewMenu'
import { SnapshotTimingPopover } from './SnapshotTimingPopover'

type SnapshotTabItem = TabItem & {
  id: SnapshotViewType
}

// breakpoints for toolbar item sizes
const BUTTON_BREAKPOINT = 900
const TAB_BREAKPOINT = 650

export const SnapshotLandToolbar = observer(function SnapshotLandToolbar({
  scriptId,
}: {
  scriptId: string
}) {
  const {
    currentView,
    selectView,
    monochrome,
    setMonochrome,
    sideBySideContextLines,
    setSideBySideContextLines,
  } = useMst().view.snapshotLand

  const { width } = useViewportSize()
  const tabMode = width < TAB_BREAKPOINT ? 'mini' : 'normal'
  const buttonMode = width < BUTTON_BREAKPOINT ? 'mini' : 'normal'

  const setSelectedTab = (newType: string | null) => {
    if (newType) {
      selectView(newType)
    }
  }

  const items: SnapshotTabItem[] = [
    { id: 'static', icon: 'fa-camera', label: 'Snapshot' },
    { id: 'diff', icon: 'fa-columns', label: 'Side-by-side' },
    { id: 'asterisk', icon: 'fa-asterisk', label: 'Asterisk' },
  ]

  return (
    <Toolbar>
      <Toolbar.Section position="left">
        <Toolbar.Tabs
          mode={tabMode}
          selectedTab={currentView}
          onSelectTab={setSelectedTab}
          items={items}
        />
      </Toolbar.Section>
      <Toolbar.Section position="right">
        {currentView === 'diff' && (
          <>
            <SideBySideViewMenu
              mode={buttonMode}
              monochrome={monochrome}
              setMonochrome={setMonochrome}
              linesOfContext={sideBySideContextLines}
              setLinesOfContext={setSideBySideContextLines}
            />
            <Toolbar.Divider />
          </>
        )}
        {currentView === 'static' && (
          <SnapshotTimingPopover mode={buttonMode} scriptId={scriptId} />
        )}
        <Toolbar.CopyUrlButton mode={buttonMode} label="Copy URL" />
        <ExportMenu mode={buttonMode} fdxEnabled={currentView === 'static'} />
      </Toolbar.Section>
    </Toolbar>
  )
})
