export const APP_STARTED = 'app started'
export const SIGNUP_START = 'signup start'
export const SIGNUP = 'signup'
export const CREATE_SHOW_START = 'create show start'
export const SHOW_CREATED = 'show created'
export const INVITE_SENT = 'invite sent'
export const INVITE_REVOKED = 'invite revoked'
export const SHOW_JOINED = 'show joined'
export const INVITE_REQUESTED_NEW_SHOW =
  'user requested invite to create new show'
export const SHOW_REMOVED = 'contributor removed from show'
export const SHOW_REMOVED_SELF = 'member removed self from show'
export const SHOW_RETIRED = 'workspace retired by owner'
export const BETA_CREATE_FOLDER = 'beta create folder'
export const BETA_RENAME_SCRIPT = 'beta rename script'
export const BETA_RENAME_FOLDER = 'beta rename folder'
export const BETA_MOVE_SCRIPT = 'beta move script'
export const BETA_MOVE_FOLDER = 'beta move folder'
export const BETA_TRASH_SCRIPT = 'beta trash script'
export const BETA_TRASH_FOLDER = 'beta trash folder'
export const BETA_DELETE_SCRIPT = 'beta permanently delete script'
export const BETA_SWITCH_SHOW = 'beta switch show'
export const BETA_IMPORTED_FDX = 'beta script fdx import'
export const BETA_EXPORTED_FDX = 'beta script fdx export'
export const BETA_EXPORTED_SNAPSHOT_FDX = 'beta snapshot fdx export'
export const BETA_EXPORTED_PDF = 'beta script pdf export'
export const EXPORTED_SECTION_PDF = 'section pdf export'
export const BETA_SCRIPT_OPENED = 'beta script opened'
export const BETA_SCRIPT_CLOSED = 'beta script closed'
export const BETA_EDITOR_SHORTCUTS_OPENED = 'beta editor shortcuts opened'
export const SCRIPT_SHARED = 'private script shared with org'
export const SCRIPT_TYPE_SELECTED = 'script type selected'
export const SCRIPT_EXPORTED_TXT = 'script prompter export'
export const SCRIPT_EXPORTED_FOUNTAIN = 'script fountain export'
export const SCRIPT_EXPORTED_RUNDOWN = 'script rundown export'
export const SCRIPT_EXPORTED_CHARACTER_REPORT = 'script character report export'
export const SCRIPT_VERSION_CREATED = 'script version created'
export const SCRIPT_VERSION_EXPORTED_FDX = 'script version fdx export'
export const SCRIPT_VERSION_EXPORTED_PDF = 'script version pdf export'
export const SCRIPT_VERSION_EXPORTED_TXT = 'script version prompter export'
export const SCRIPT_VERSION_EXPORTED_FOUNTAIN = 'script version fountain export'
export const SCRIPT_REVISION_EXPORTED =
  'script pdf export with revision asterisks'
export const SCRIPT_HISTORY_FEED_OPENED = 'script snapshot history feed opened'
export const SCRIPT_COMMENT_CREATED = 'script comment created'
export const SCRIPT_COMMENT_DELETED = 'script comment deleted'
export const SCRIPT_COMMENT_EDITED = 'script comment edited'
export const SCRIPT_COMMENT_RESOLVED = 'script comment resolved'
export const SCRIPT_COMMENT_UNRESOLVED = 'script comment unresolved'
export const SCRIPT_COMMENT_FEED_OPENED = 'script comment feed opened'
export const SCRIPT_NAV_REORDERED = 'script nav reordered'
export const SCRIPT_SLACK_INTEGRATION_ENABLED =
  'script slack integration enabled'
export const SCRIPT_SLACK_INTEGRATION_DISABLED =
  'script slack integration disabled'
export const SCRIPT_COMPARE_OPENED = 'script compare opened'
export const SCRIPT_COMPARE_FAILED = 'script compare failed'
export const SCRIPT_ELEMENTS_NUMBERED = 'script elements numbered'
export const SCRIPT_BLOCK_FORMATTING_CUSTOMIZED =
  'script block formatting customized'
export const SCRIPT_PRODUCTION_MENU_OPENED = 'production menu opened'
export const SCRIPT_BRACKETS_IN_NAV_TOGGLED =
  'brackets toggled in script navigation'
export const SCRIPT_STATUS_CHANGED = 'script status changed'
export const EDITOR_WRITING_ACTIVITY = 'writing activity'
export const EDITOR_PRESS_ENTER = 'pressed enter in script'
export const EDITOR_CREATE_DUAL_DIALOGUE = 'dual dialogue created'
export const ADMIN_PROMOTED = 'contributor promoted to admin'
export const ADMIN_DEMOTED = 'admin demoted to contributor'
export const ORG_OPTION_ENABLED = 'org option enabled'
export const ORG_OPTION_DISABLED = 'org option disabled'
export const ORG_RENAMED = 'org renamed'
export const ORG_OWNERSHIP_TRANSFER = 'org assigned a new owner'
export const UPGRADE_INQUIRY_SENT = 'upgrade inquiry sent'
export const SET_ZOOM_LEVEL = 'document zoomed'

export const DOCUMENT_CREATED = 'document created'
export const DOCUMENT_OPENED = 'document opened'
export const DOCUMENT_MODIFIED = 'document modified'

export const LOGIN = 'login'
export const LOGOUT = 'logout'
export const ENABLED_PAGELESS_MODE = 'enabled pageless mode'
export const DISABLED_PAGELESS_MODE = 'disabled pageless mode'
export const SIDEBAR_OPENED = 'sidebar opened'
export const PROMPTER_PUSH_SCRIPT = 'script pushed to prompter'
export const PROMPTER_PUSH_RUNDOWN = 'rundown pushed to prompter'

export const ENTER_SPLIT_VIEW = 'enter split view'
export const EXIT_SPLIT_VIEW = 'exit split view'
export const CHANGE_SPLIT_VIEW = 'change split view'

export const PROMPTER_VIEW_OPENED = 'prompter view opened'
export const PROMPTER_VIEW_CLOSED = 'prompter view closed'
export const PROMPTER_VIEW_CHANGED = 'prompter view changed'

export const ADVANCED_SEARCH_OPENED = 'advanced search popover opened'
export const SIDE_BY_SIDE_EXPORTED = 'side by side comparison exported to pdf'
export const SIDE_BY_SIDE_PRINTED = 'side by side comparison printed'
