import { observer } from 'mobx-react-lite'

import { useMst } from '@hooks'

import { SelectedSideIcon } from './SelectedSideIcon'
import { ChooseSnapshotCard, SnapshotCard } from './SnapshotCard'
import { useSnapshotData } from './useSnapshotLandData'

export const LoadableSnapshotCard = ({
  scriptId,
  snapshotId,
  onClose,
  side,
}: {
  scriptId: string
  snapshotId: string
  onClose?: Callback
  side?: 'left' | 'right'
}) => {
  const { data, isLoading, isError } = useSnapshotData({ scriptId, snapshotId })
  return (
    <SnapshotCard
      icon={side ? <SelectedSideIcon side={side} /> : null}
      data={data}
      sticky
      onClose={onClose}
      loading={isLoading}
      error={isError}
    />
  )
}

export const StickySnapshotCard = observer(function StickySnapshotCard({
  position,
  scriptId,
}: {
  scriptId: string
  position: 'top' | 'bottom'
}) {
  const { snap1, snap2, currentView, dismissBottom, dismissTop } =
    useMst().view.snapshotLand
  const snapshotId = position === 'bottom' ? snap1 : snap2

  const side =
    currentView !== 'diff' ? undefined : position === 'top' ? 'left' : 'right'

  if (!(snapshotId && scriptId)) {
    return <ChooseSnapshotCard compare={currentView !== 'static'} />
  }

  const handleClose = () => {
    if (position === 'top') {
      dismissTop()
    } else {
      dismissBottom()
    }
  }

  return (
    <LoadableSnapshotCard
      side={side}
      scriptId={scriptId}
      snapshotId={snapshotId}
      onClose={currentView === 'static' ? undefined : handleClose}
    />
  )
})
