/***************************************************
 *
 *     AUTOGENERATED FILE -- DO NOT EDIT
 *
 *     Contents generated from the ./configs
 *     directory by running:  yarn codegen:api
 *
 ***************************************************/

import { BaseApiClient } from './BaseApiClient'
import { API_CONFIGS } from './configs'

export class ScriptoApiClient extends BaseApiClient {
  bulkAddFlag = this._configToMethod(API_CONFIGS.bulkAddFlag)
  bulkRemoveFlag = this._configToMethod(API_CONFIGS.bulkRemoveFlag)
  createBetaFlag = this._configToMethod(API_CONFIGS.createBetaFlag)
  destroyBetaFlag = this._configToMethod(API_CONFIGS.destroyBetaFlag)
  getBetaFlags = this._configToMethod(API_CONFIGS.getBetaFlags)
  addCommentToThread = this._configToMethod(API_CONFIGS.addCommentToThread)
  createCommentOrThreadDEPRECATED = this._configToMethod(API_CONFIGS.createCommentOrThreadDEPRECATED)
  createCommentThread = this._configToMethod(API_CONFIGS.createCommentThread)
  deleteComment = this._configToMethod(API_CONFIGS.deleteComment)
  editComment = this._configToMethod(API_CONFIGS.editComment)
  fetchCommentHistory = this._configToMethod(API_CONFIGS.fetchCommentHistory)
  getComment = this._configToMethod(API_CONFIGS.getComment)
  getCommentCounts = this._configToMethod(API_CONFIGS.getCommentCounts)
  getCommentThreads = this._configToMethod(API_CONFIGS.getCommentThreads)
  resolveComment = this._configToMethod(API_CONFIGS.resolveComment)
  unresolveComment = this._configToMethod(API_CONFIGS.unresolveComment)
  exportBracketsList = this._configToMethod(API_CONFIGS.exportBracketsList)
  exportCharacterReport = this._configToMethod(API_CONFIGS.exportCharacterReport)
  exportFdx = this._configToMethod(API_CONFIGS.exportFdx)
  exportFountain = this._configToMethod(API_CONFIGS.exportFountain)
  exportPrompter = this._configToMethod(API_CONFIGS.exportPrompter)
  ping = this._configToMethod(API_CONFIGS.ping)
  getOrg = this._configToMethod(API_CONFIGS.getOrg)
  getOrgFolders = this._configToMethod(API_CONFIGS.getOrgFolders)
  getOrgIdForResource = this._configToMethod(API_CONFIGS.getOrgIdForResource)
  getRundown = this._configToMethod(API_CONFIGS.getRundown)
  addScriptToSlack = this._configToMethod(API_CONFIGS.addScriptToSlack)
  createScript = this._configToMethod(API_CONFIGS.createScript)
  destroyScript = this._configToMethod(API_CONFIGS.destroyScript)
  duplicateScript = this._configToMethod(API_CONFIGS.duplicateScript)
  getScript = this._configToMethod(API_CONFIGS.getScript)
  getScriptMeta = this._configToMethod(API_CONFIGS.getScriptMeta)
  moveScript = this._configToMethod(API_CONFIGS.moveScript)
  removeScriptFromSlack = this._configToMethod(API_CONFIGS.removeScriptFromSlack)
  renameScript = this._configToMethod(API_CONFIGS.renameScript)
  trashScript = this._configToMethod(API_CONFIGS.trashScript)
  updateScriptStatus = this._configToMethod(API_CONFIGS.updateScriptStatus)
  createInviteByStaff = this._configToMethod(API_CONFIGS.createInviteByStaff)
  createRundownSchema = this._configToMethod(API_CONFIGS.createRundownSchema)
  createScriptFormat = this._configToMethod(API_CONFIGS.createScriptFormat)
  fetchRundownSchemas = this._configToMethod(API_CONFIGS.fetchRundownSchemas)
  getFormats = this._configToMethod(API_CONFIGS.getFormats)
  getInvitesByStaff = this._configToMethod(API_CONFIGS.getInvitesByStaff)
  getOrgForStaff = this._configToMethod(API_CONFIGS.getOrgForStaff)
  reindexDocsInOrgByStaff = this._configToMethod(API_CONFIGS.reindexDocsInOrgByStaff)
  revokeUserSessions = this._configToMethod(API_CONFIGS.revokeUserSessions)
  setRundownSchemaForOrg = this._configToMethod(API_CONFIGS.setRundownSchemaForOrg)
  setScriptFormatsForOrg = this._configToMethod(API_CONFIGS.setScriptFormatsForOrg)
  toggleBetaFlagByStaff = this._configToMethod(API_CONFIGS.toggleBetaFlagByStaff)
  updateRundownSchema = this._configToMethod(API_CONFIGS.updateRundownSchema)
  createSteps = this._configToMethod(API_CONFIGS.createSteps)
  changeEmail = this._configToMethod(API_CONFIGS.changeEmail)
  changeStytchPassword = this._configToMethod(API_CONFIGS.changeStytchPassword)
  createAccountStytch = this._configToMethod(API_CONFIGS.createAccountStytch)
  getUserStatusForForgotPassword = this._configToMethod(API_CONFIGS.getUserStatusForForgotPassword)
  initiateAccountMigration = this._configToMethod(API_CONFIGS.initiateAccountMigration)
  initiateEmailChange = this._configToMethod(API_CONFIGS.initiateEmailChange)
  initiateStychPasswordReset = this._configToMethod(API_CONFIGS.initiateStychPasswordReset)
}
