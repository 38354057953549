import React from 'react'

import { Button, Group, Loader, ScrollArea, Stack, Text } from '@mantine/core'
import { observer } from 'mobx-react-lite'

import { FaIcon } from '@components/FaIcon'
import { useMst } from '@hooks'
import { SnapshotViewType } from '@state/models/View/SnapshotLandState'
import { notEmptyFilter } from '@util'
import { SnapshotSummary } from '@util/ScriptoApiClient/types'

import { headerDateStr } from './helpers'
import { SelectionMode, SnapshotCard } from './SnapshotCard'
import { SnapshotFilter } from './SnapshotFilter'
import { StickySnapshotCard } from './StickySnapshotCard'
import { useSnapshotHistory } from './useSnapshotHistory'
import { useSelectedSnapshots } from './useSnapshotLandData'

import styles from './SnapshotList.module.scss'

const StickyCards = ({
  mode,
  scriptId,
}: {
  scriptId: string
  mode: SelectionMode
}) => {
  return (
    <Stack gap={10} className={styles.snapshotList_selectedSection}>
      {mode === 'comparison' && (
        <StickySnapshotCard position="top" scriptId={scriptId} />
      )}
      <StickySnapshotCard position="bottom" scriptId={scriptId} />
    </Stack>
  )
}

const HEADERS: Record<SnapshotViewType, string> = {
  static: 'Snapshots',
  asterisk: 'Snapshot comparison',
  diff: 'Snapshot comparison',
}

const DateSeparator = ({
  snap,
  previousSnap,
}: {
  snap: SnapshotSummary
  previousSnap?: SnapshotSummary
}) => {
  const snapHeader = headerDateStr(snap)
  const prevHeader = previousSnap ? headerDateStr(previousSnap) : undefined
  if (snapHeader === prevHeader) {
    return null
  }
  return (
    <Text tt="uppercase" fw="bold" size="12" c="gray">
      {snapHeader}
    </Text>
  )
}

const LoadMoreControls = ({
  hasMore,
  onClick,
  loading,
  showing,
  total,
}: {
  hasMore: boolean
  showing: number
  total: number
  onClick: Callback
  loading?: boolean
}) => {
  const label = hasMore ? `${showing} of ${total}` : `showing all ${total}`

  return (
    <Stack align="center">
      <Text c="gray.6" fw="bold" size="16">
        {label}
      </Text>
      {hasMore && (
        <Group justify="center" pt={10}>
          <Button
            size="xs"
            variant="subtle"
            onClick={onClick}
            loading={loading}
          >
            Load More
          </Button>
        </Group>
      )}
    </Stack>
  )
}

const NoSnapshots = () => (
  <Stack pl={10} pr={5} pt={10}>
    <Text fw="bold">No snapshots yet</Text>
    <Text>
      Snapshots let you view and compare different versions of a script to see
      what&apos;s changed.
    </Text>
    <Text>
      To create a snapshot, you&apos;ll first need to close out of this view.
      Then, from the editor, click the&nbsp;
      <span className={styles.snapshotList_emptyButton}>
        <FaIcon span c="dark.9" icon="fa-camera" size="14" />
        &nbsp;
        <Text span fw="bold">
          Snapshot
        </Text>
      </span>
      &nbsp;button on the right side of the toolbar.
    </Text>
  </Stack>
)

export const SnapshotList = observer(function SnapshotList({
  scriptId,
}: {
  scriptId: string
}) {
  const { params, currentView, selectSnapshotId, swapSnapshots } =
    useMst().view.snapshotLand
  const { snap1, snap2 } = params
  const selectedIds: string[] = [snap1, snap2].filter(notEmptyFilter)

  const history = useSnapshotHistory(scriptId)
  const { topQuery, bottomQuery } = useSelectedSnapshots(scriptId)

  // if we are comparing with an  newer one on the right side/bottom,
  // swap them
  React.useEffect(() => {
    if (topQuery.data && bottomQuery.data) {
      if (bottomQuery.data.version > topQuery.data.version) {
        swapSnapshots()
      }
    }
  }, [bottomQuery.data, topQuery.data, swapSnapshots, snap1, snap2])

  // we allow you to pick a snapshot from the list if either
  // * you are in static view- then we swap
  // * you are in a comparison view and at least one slot is open
  const selectionEnabled = currentView === 'static' || selectedIds.length < 2

  return (
    <Stack gap={0} className={styles.snapshotList}>
      <Group
        wrap="nowrap"
        justify="space-between"
        align="center"
        className={styles.snapshotList_header}
        gap={5}
        pb={10}
        px={10}
        pt={10}
      >
        <Text lineClamp={1} style={{ wordBreak: 'break-all' }} fw="bold">
          {HEADERS[currentView]}
        </Text>
        <SnapshotFilter />
      </Group>
      <StickyCards
        scriptId={scriptId}
        mode={currentView === 'static' ? 'solo' : 'comparison'}
      />
      <ScrollArea className={styles.snapshotList_scroller}>
        <Stack gap={10} className={styles.snapshotList_contents}>
          {history.isFetchingFirstPage && (
            <Group justify="center">
              <Loader size="sm" />
            </Group>
          )}

          {history.data.map((snapshot, idx) => {
            const isSelected = selectedIds.includes(snapshot.id)

            return (
              <React.Fragment key={snapshot.id}>
                <DateSeparator
                  snap={snapshot}
                  previousSnap={history.data[idx - 1]}
                />
                <SnapshotCard
                  data={snapshot}
                  selected={isSelected}
                  onClick={() => selectSnapshotId(snapshot.id)}
                  enableClick={selectionEnabled}
                />
              </React.Fragment>
            )
          })}
          {history.data.length > 0 && (
            <LoadMoreControls
              hasMore={history.hasMore}
              loading={history.isFetchingNextPage}
              onClick={history.loadMore}
              total={history.total}
              showing={history.data.length}
            />
          )}
          {history.data.length === 0 && !history.isFetchingFirstPage && (
            <NoSnapshots />
          )}
        </Stack>
      </ScrollArea>
    </Stack>
  )
})
