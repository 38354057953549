import React from 'react'

import { schemas, ZInfer } from '@showrunner/scrapi'

import {
  BlockFormats,
  FormatBlockName,
  StandardBlockConfig,
} from '@util/formats'

type CSSVarName = `--${string}`

// This is a zod enum of the different things we store for a block.
// Essentially: ['width', 'marginLeft', ...etc
const formatKeyEnum = schemas.scriptFormats.StandardBlockConfig.keyof()
export type FormatKey = ZInfer<typeof formatKeyEnum>

// these are the css properties we set for different block properties. These
// values are used in the css var names and could be used to programmatically
// generate the standard stylesheet (but are not so today)
const BlockPropToCssKey: Record<FormatKey, string> = {
  marginLeft: 'margin-left',
  width: 'width',
  alignment: 'text-align',
  lineHeight: 'line-height',
  blockTopMargin: 'margin-top',
  bold: 'font-weight',
  uppercase: 'text-transform',
  italic: 'font-style',
  underline: 'text-decoration',
  color: 'color',
}

// these are strings we use in the css vars names that are
// referenced in the Elements.scss stylesheet.  These are also
// used for the classnames assigned by prosmirror (when prefixed
// by 'o-') so it may make sense to use this map in the prosemirror
// schema at some point
const PmNodeToCssName: Record<FormatBlockName, string> = {
  action: 'action',
  bracket: 'bracket',
  character: 'character',
  dialogue: 'dialogue',
  dual_dialogue_column: 'dual-dialogue__column',
  end_of_act: 'end-of-act',
  general: 'general',
  new_act: 'new-act',
  dual_dialogue: 'dual-dialogue',
  parenthetical: 'parenthetical',
  sceneHeading: 'scene',
  slug: 'slug',
  transition: 'transition',
}

// LEGACY CONVERSION: Back in the day we decided to use ch values for
// block width and marginLeft based on fixed size Courier Prime.
// Until/unless we change the data in all script formats, we want to
// convert that into a font independent value.
//
// We need a little funny math to convert that to pixels:
// There are 9.6px in 1ch in this font and multiplying by 9.6 gives
// many digits of converted binary precision, so we do 10x then divide
const TEN_CH_IN_PIXELS = 96

const chToPxStyle = (chValue: number) => {
  const tenXTooBig = TEN_CH_IN_PIXELS * chValue
  const pxValue = tenXTooBig / 10
  return `${pxValue}px`
}

// for lineHeight and blockTopMargin, the number is an em value
// based on a 16px per em basis. Again, convert:
const emToPxStyle = (value: number) => `${value * 16}px`

const buildVarName = (
  blockName: FormatBlockName,
  formatKey: FormatKey,
  dual: boolean,
): CSSVarName => {
  const parts: string[] = [PmNodeToCssName[blockName]]
  if (dual) {
    parts.push('dual')
  }
  parts.push(BlockPropToCssKey[formatKey])
  return `--${parts.join('-')}`
}

type ValueGenerators = Record<
  keyof StandardBlockConfig,
  (config: StandardBlockConfig) => string | undefined
>
const valueGenerators: ValueGenerators = {
  marginLeft: (config) => chToPxStyle(config.marginLeft),
  width: (config) => chToPxStyle(config.width),
  alignment: (config) => config.alignment,
  lineHeight: (config) => emToPxStyle(config.lineHeight),
  blockTopMargin: (config) => emToPxStyle(config.blockTopMargin),
  bold: (config) => (config.bold ? 'bold' : undefined),
  uppercase: (config) => (config.uppercase ? 'uppercase' : undefined),
  italic: (config) => (config.italic ? 'italic' : undefined),
  underline: (config) => (config.underline ? 'underline' : undefined),
  color: (config) => config.color,
}

// builds up an array of css var names and values. This can be used to
// generate a React.CSSProperies object (for on screen) or a style
// string for printing
type VarData = {
  varName: CSSVarName
  value: string
}
const generateVarData = (
  blockFormats: BlockFormats,
  monochrome?: boolean,
): VarData[] => {
  const result: VarData[] = []
  schemas.scriptFormats.StandardBlocks.options.forEach((blockName) => {
    const config = blockFormats[blockName]
    formatKeyEnum.options.forEach((formatKey) => {
      if (monochrome && formatKey === 'color') {
        return
      }
      const value = valueGenerators[formatKey](config)
      if (value) {
        result.push({
          varName: buildVarName(blockName, formatKey, false),
          value,
        })
      }
    })
  })

  schemas.scriptFormats.DualFormatBlocks.options.forEach((blockName) => {
    const config = blockFormats[blockName]
    formatKeyEnum.options.forEach((formatKey) => {
      const standardValue = valueGenerators[formatKey](config)
      const dualValue = valueGenerators[formatKey](config.dualDialogOverrides)
      if (monochrome && formatKey === 'color') {
        return
      }
      if (standardValue) {
        result.push({
          varName: buildVarName(blockName, formatKey, false),
          value: standardValue,
        })
      }
      if (dualValue) {
        result.push({
          varName: buildVarName(blockName, formatKey, true),
          value: dualValue,
        })
      }
    })
  })
  if (monochrome) {
    result.push({
      varName: '--monochrome-text-color',
      value: '#000',
    })
    result.push({
      varName: '--monochrome-background-color',
      value: 'transparent',
    })
  }
  return result
}

// for use in a React component style prop
// { '--bracket-margin-top': '9px', '--bracket-font-weight': 'bold', ... }
export const buildFormatStyleProp = (
  blockFormats: BlockFormats,
): React.CSSProperties => {
  const result: React.CSSProperties = {}
  generateVarData(blockFormats).forEach(({ varName, value }) => {
    result[varName] = value
  })
  return result
}

// for use in an html string sent to docraptor
// e.g. ":root { --bracket-margin-top: 9px; --bracket-font-weight: bold; }"
export const buildFormatStyleString = (
  blockFormats: BlockFormats,
  monochrome: boolean,
) => {
  const lines = [
    ':root {',
    ...generateVarData(blockFormats, monochrome).map(
      ({ varName, value }) => `${varName}: ${value};`,
    ),
    '}',
  ]
  return lines.join('\n')
}
