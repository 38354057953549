import { types } from 'mobx-state-tree'

import { BaseModel } from '../BaseModel'

const VIEW_TYPES = ['static', 'asterisk', 'diff'] as const
export type SnapshotViewType = (typeof VIEW_TYPES)[number]
function isViewType(value: unknown): value is SnapshotViewType {
  return (
    typeof value === 'string' && VIEW_TYPES.includes(value as SnapshotViewType)
  )
}

// these are query param keys
const VIEW = 'view'
const SNAPSHOT1 = 'snap1'
const SNAPSHOT2 = 'snap2'

// This handles state for the script history overlay. However,
// we do not store the actual snapshot data in MST, that's all
// done in tanstack. This is just for managing the view state,
// query params, etc.
export const SnapshotLandState = BaseModel.named('SnapshotLand')
  .props({
    sideBySideContextLines: types.optional(types.maybeNull(types.number), null),
    monochrome: false,
  })
  .views((self) => ({
    get currentView(): SnapshotViewType {
      const param = self.rootStore.location.getQueryParam(VIEW)
      if (isViewType(param)) {
        return param
      }
      return 'static'
    },
    get params(): {
      snap1?: string
      snap2?: string
      view: SnapshotViewType
    } {
      const view = this.currentView
      return {
        view,
        snap1: self.rootStore.location.getQueryParam(SNAPSHOT1),
        snap2: self.rootStore.location.getQueryParam(SNAPSHOT2),
      }
    },
    get snap1() {
      return this.params.snap1
    },
    get snap2() {
      return this.params.snap2
    },
  }))
  .actions((self) => ({
    selectView(view: string) {
      if (isViewType(view)) {
        self.rootStore.location.updateQueryParams({ view })
      }
    },
    selectSnapshotId(id: string) {
      const { view, snap1, snap2 } = self.params
      if (snap1 === id || snap2 === id) {
        return
      }

      // if the view is static, then we swap out snapshot1,
      // otherwise, we look for an empty slot and if none exist
      // we do nothing
      if (view === 'static') {
        self.rootStore.location.updateQueryParams({ [SNAPSHOT1]: id })
      } else if (!snap1) {
        self.rootStore.location.updateQueryParams({ [SNAPSHOT1]: id })
      } else if (!snap2) {
        self.rootStore.location.updateQueryParams({ [SNAPSHOT2]: id })
      }
    },
    dismissTop() {
      self.rootStore.location.updateQueryParams({ [SNAPSHOT2]: null })
    },
    dismissBottom() {
      self.rootStore.location.updateQueryParams({ [SNAPSHOT1]: null })
    },
    swapSnapshots() {
      const { snap1, snap2 } = self.params
      if (snap1 && snap2) {
        self.rootStore.location.updateQueryParams({
          [SNAPSHOT1]: snap2,
          [SNAPSHOT2]: snap1,
        })
      }
    },
    setSideBySideContextLines(value: number | null) {
      self.sideBySideContextLines = value
    },
    setMonochrome(value: boolean) {
      self.monochrome = value
    },
  }))
