import { Menu } from '@mantine/core'

import { FancyMenuItem } from '@components/FancyMenuItem'
import { Toolbar } from '@components/Toolbar'
import { ButtonMode } from '@state'
import { SOME_DIFF_CONTEXT_LINES } from '@util/printing'

export const SideBySideViewMenu = ({
  linesOfContext,
  monochrome,
  setMonochrome,
  setLinesOfContext,
  mode,
}: {
  monochrome: boolean
  setMonochrome: Callback<[boolean]>
  linesOfContext: number | null
  setLinesOfContext: Callback<[number | null]>
  mode: ButtonMode
}) => (
  <Menu position="bottom-end">
    <Menu.Target>
      <div>
        <Toolbar.Button
          mode={mode}
          icon="fa-sliders"
          label="Settings"
          dropdown
        />
      </div>
    </Menu.Target>
    <Menu.Dropdown>
      <Menu.Label>SIDE-BY-SIDE VIEW SETTINGS</Menu.Label>
      <FancyMenuItem
        key="0"
        title="Changes only"
        onClick={() => setLinesOfContext(0)}
        selected={linesOfContext === 0}
      />
      <FancyMenuItem
        key="5"
        title="Changes with some context"
        onClick={() => setLinesOfContext(SOME_DIFF_CONTEXT_LINES)}
        selected={linesOfContext === SOME_DIFF_CONTEXT_LINES}
      />
      <FancyMenuItem
        key="full"
        title="Full text"
        onClick={() => setLinesOfContext(null)}
        selected={linesOfContext === null}
      />
      <Menu.Divider />

      <Menu.Item onClick={() => setMonochrome(!monochrome)}>
        {monochrome
          ? 'Show changes in color'
          : 'Show changes in black and white'}
      </Menu.Item>
    </Menu.Dropdown>
  </Menu>
)
