import { types } from 'mobx-state-tree'

import {
  PROMPTER_PUSH_RUNDOWN,
  PROMPTER_PUSH_SCRIPT,
} from '@util/mixpanel/eventNames'
import { PrompterSegmentPayload } from '@util/ScriptoApiClient/types'

import { BaseModel } from './BaseModel'
import { PrompterSegment } from './PrompterSegment'

export const PrompterPushCandidate = BaseModel.named('PrompterPush')
  .props({
    parentId: types.union(types.string, types.number),
    parentType: types.frozen<DocumentListingType>('script'),
    segments: types.array(PrompterSegment),
  })
  .views((self) => ({
    get visibleSegments() {
      return self.segments.filter((s) => !s.isZerothSlug || s.hasText)
    },
    get areAllSegmentsChecked() {
      return !self.segments.some((s) => !s.shouldPush && s.hasText)
    },
    get areNoSegmentsChecked() {
      return !self.segments.some((s) => s.shouldPush && s.hasText)
    },
    get checkedSegmentCount() {
      return self.segments.filter((s) => s.shouldPush).length
    },
    get parentIsScript() {
      return self.parentType === 'script'
    },
  }))
  // sync
  .actions((self) => ({
    setSegments(segments: Array<PrompterSegmentPayload>) {
      self.segments.replace(segments.map((s) => PrompterSegment.create(s)))
    },
    toggleSegmentsChecked(checked: boolean) {
      self.segments.forEach((s) => s.setShouldPush(checked))
    },
  }))
  // async
  .actions((self) => ({
    async loadSegments() {
      const { segments } = self.parentIsScript
        ? await self.apiClient.prepareScriptPrompterPush({
            scriptId: self.parentId as string,
          })
        : await self.apiClient.prepareRundownPrompterPush({
            rundownId: self.parentId as number,
          })

      self.setSegments(segments)

      //  If there's only one segment the UI will hide the checkboxes
      //  and present the user with just a yes/no choice so mark
      //  the one segment as push=true
      if (self.visibleSegments.length === 1) {
        const segment = self.visibleSegments[0]
        if (!segment.shouldPush) segment.setShouldPush(true)
      }
    },

    async pushToPrompter() {
      if (self.parentIsScript) {
        self.trackEvent(PROMPTER_PUSH_SCRIPT)

        return self.apiClient.pushScriptToPrompter({
          scriptId: self.parentId as string,
          segments: self.visibleSegments,
        })
      } else {
        self.trackEvent(PROMPTER_PUSH_RUNDOWN)

        return self.apiClient.pushRundownToPrompter({
          rundownId: self.parentId as number,
          segments: self.visibleSegments,
        })
      }
    },
  }))
