import { EditorState, TextSelection } from 'prosemirror-state'

import { isStandardBlock } from '@util'

const COMMENT_CHARS = '//'
const INSERT_COMMENT = '// '
const COMMENT_RE = /^(?<leadingSpaces>\s*)\/\/(?<trailingSpace>\s?)/

export const toggleCodeComment = (
  editorState: EditorState,
  dispatch: Dispatch,
) => {
  const { selection, tr, doc } = editorState
  if (!(selection instanceof TextSelection)) {
    // Defer to other commands if this is a non-text selection
    return false
  }

  const { anchor, head } = selection

  // The same command works to comment or uncomment depending on whether
  // all lines in the selection start with a comment or not
  let nonCommentFound = false
  doc.nodesBetween(selection.from, selection.to, (node, pos, parent) => {
    if (isStandardBlock({ node, parent })) {
      const reResult = COMMENT_RE.exec(node.textContent)
      if (!reResult?.groups) {
        nonCommentFound = true
      }
    }
  })

  doc.nodesBetween(selection.from, selection.to, (node, pos, parent) => {
    const startingPos = tr.mapping.map(pos) + 1
    if (isStandardBlock({ node, parent })) {
      const reResult = COMMENT_RE.exec(node.textContent)
      if (nonCommentFound) {
        tr.insertText(INSERT_COMMENT, startingPos)
      } else if (reResult?.groups) {
        const { leadingSpaces, trailingSpace } = reResult.groups
        const replaceFrom = startingPos + leadingSpaces.length
        const replaceTo =
          replaceFrom + COMMENT_CHARS.length + trailingSpace.length
        tr.insertText('', replaceFrom, replaceTo)
      }
    }
  })

  if (tr.steps.length > 0) {
    tr.setSelection(
      TextSelection.create(
        tr.doc,
        tr.mapping.map(anchor),
        tr.mapping.map(head),
      ),
    )
    dispatch(tr)
  }

  return true
}
