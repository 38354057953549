import deepEqual from 'fast-deep-equal'
import { EditorState, Plugin, PluginKey } from 'prosemirror-state'

import { PluginFactory } from '../types'

import { extractBlockInfo } from './extractBlockInfo'
import { BlockInfoState } from './types'

export const blockInfoPluginKey = new PluginKey<BlockInfoState>('blockInfo')

export const blockInfoFactory: PluginFactory = (): Plugin<BlockInfoState> => {
  const getPluginState = (state: EditorState): BlockInfoState => {
    return extractBlockInfo(state)
  }

  return new Plugin<BlockInfoState>({
    key: blockInfoPluginKey,
    state: {
      init(editorState: EditorState) {
        return getPluginState(editorState)
      },
      apply(_, pluginState, oldState, newState) {
        if (oldState.doc.eq(newState.doc)) return pluginState

        const newPluginState = getPluginState(newState)

        // Only update the state when the value has changed to avoid unnecessary
        // rerendering of the same values
        return deepEqual(newPluginState, pluginState)
          ? pluginState
          : newPluginState
      },
    },
  })
}
