import { useViewportSize } from '@mantine/hooks'
import { observer } from 'mobx-react-lite'

import { schema, util } from '@showrunner/codex'

import { TimingPopover } from '@components/EditorToolbar/TimingPopover'
import { useMst } from '@hooks'
import { ButtonMode } from '@state'

import { useMaybeSnapshot } from '../useSnapshotLandData'

export const SnapshotTimingPopover = observer(function SnapshotTimingPopover({
  scriptId,
  mode,
}: {
  scriptId: string
  mode: ButtonMode
}) {
  const { height } = useViewportSize()
  const mst = useMst()
  const readRate = mst.currentOrg?.readRate
  const { snap1 } = mst.view.snapshotLand

  const query = useMaybeSnapshot({ scriptId, snapshotId: snap1 })

  if (
    !(
      readRate &&
      query.isSuccess &&
      query.data &&
      query.data.doc.attrs.docType !== 'screenplay'
    )
  ) {
    return null
  }

  const timing = new util.ScriptBreakdown(
    schema.nodeFromJSON(query.data.doc),
    readRate,
  ).timing

  return <TimingPopover mode={mode} height={height - 100} docTiming={timing} />
})
