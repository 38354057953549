import { types } from 'mobx-state-tree'

import { IFolder, isCollectionId, SplitEditorPref } from '@state/types'
import { LAST_WHATS_NEW_UPDATE } from '@util/constants'
import {
  FORMATTING,
  NIDO_ALPHA,
  NIDO_BETA,
  NIDO_SOCKETS,
} from '@util/featureFlags'
import { RundownScriptLayout } from '@util/LocalPersistence'

import { BaseModel } from '../BaseModel'
import { Folder } from '../Folder'
import { GET_LISTINGS_PAGE_SIZE } from '../util'

import { DimensionsModel } from './Dimensions'
import { ExplorerState } from './ExplorerState'
import { SnapshotLandState } from './SnapshotLandState'

type RequestedDocument = {
  id: string
  type: DocumentListingType
}

// expand all the folders above a specific folderId.
// If the folderId has subfolders, expand it too.
const expandFolders = ({
  folderId,
  folders,
  isFolderExpanded,
  setExpanded,
}: {
  folderId: string
  folders: IFolder[]
  isFolderExpanded: (f: IFolder) => boolean
  setExpanded: (folderId: string) => void
}) => {
  folders.forEach((f) => {
    if (isFolderExpanded(f)) return

    if (f.id !== folderId || f.childFolders.length > 0) {
      setExpanded(f.id)
    }
  })
}

export const ReadonlyFolderListState = BaseModel.named(
  'ReadonlyFolderListState',
)
  .props({
    rootId: types.string,
    expandedFolders: types.array(types.string),
    disabledFolderId: types.maybe(types.string),
    selectedFolderId: '',
  })
  .views((self) => ({
    isExpanded(folder: IFolder) {
      return self.expandedFolders.includes(folder.id)
    },
    isDisabled(folder: IFolder) {
      return self.disabledFolderId === folder.id
    },
  }))
  .actions((self) => ({
    setSelectedFolderId(folderId: string) {
      self.selectedFolderId = folderId
    },
    toggleFolderExpanded(folder: IFolder) {
      if (self.expandedFolders.includes(folder.id)) {
        self.expandedFolders.remove(folder.id)
      } else {
        self.expandedFolders.push(folder.id)
      }
    },
    collapseAllFolders() {
      self.expandedFolders.replace([])
    },
    setFolderExpanded(folder: IFolder, expanded: boolean) {
      const currentlyExpanded = self.isExpanded(folder)
      if (currentlyExpanded !== expanded) {
        this.toggleFolderExpanded(folder)
      }
    },
    expandFolderPath(folderId: string) {
      expandFolders({
        folderId,
        folders: self.rootStore.getFolderPath(folderId),
        isFolderExpanded: self.isExpanded,
        setExpanded: (id: string) => self.expandedFolders.push(id),
      })
    },
  }))

/*
  This is the long-term home for mapping state -> url and for describing
  the view model that should be displayed.

  In the short term, we don't handle urls or manage app navigation directly,
  instead the ChooWrapper calls us to notify us of route changes.
*/
export const View = BaseModel.named('View')
  .props({
    explorerState: types.optional(ExplorerState, {}),
    snapshotLand: types.optional(SnapshotLandState, {}),
    // set when a document is requested and cleared when it's
    // loaded. We use this to figure out if we need to auto-navigate
    // in the folder browser after a script or rundown loads vs letting the user
    // control the folder browser
    requestedDocument: types.maybe(types.frozen<RequestedDocument>()),

    selectedFolderId: '',

    folderCreatingChildId: types.maybe(types.string),

    folderBeingRenamedId: types.maybe(types.string),

    // When we're showing a modal with
    readonlyFolderListState: types.maybe(ReadonlyFolderListState),

    // these are the folders expanded in the folder browser. By having safe references,
    // they just get removed from the array if they're destroyed.
    expandedFolders: types.array(types.safeReference(Folder)),

    debugQueryParams: types.array(types.string),

    dimensions: types.optional(DimensionsModel, {}),
  })
  .views((self) => ({
    get debugFlags(): string[] {
      const { user, loggedIn } = self.rootStore
      if (loggedIn && !user.staff) {
        return []
      }
      return self.debugQueryParams
    },
  }))
  .views((self) => ({
    get currentFolder(): IFolder | undefined {
      return self.rootStore.folderMap.get(self.selectedFolderId)
    },
    getFolder(folderId: string): IFolder | undefined {
      return self.rootStore.folderMap.get(folderId)
    },
    getFolderName(folderId: string): string | undefined {
      const folder = self.rootStore.folderMap.get(folderId)
      if (folder?.isRootFolder && !folder.isPrivate) return 'Shared'
      if (folder?.isRootFolder && folder.isPrivate) return 'Private'
      return folder?.name
    },
    isFolderExpanded(folder: IFolder): boolean {
      return self.expandedFolders.includes(folder)
    },
    isDebugEnabled(key: string): boolean {
      return self.debugFlags.includes(key.toLowerCase())
    },
    isDebugOrFlagEnabled(key: string): boolean {
      return (
        this.isDebugEnabled(key) ||
        !!self.rootStore.currentOrg?.betaFlags.includes(key)
      )
    },
    // any time there's a debug flag, we return true for devMode.
    // This is best used for features that are not annoying for
    // devs to see a lot (vs big behavioral changes)
    get devMode(): boolean {
      return self.debugFlags.length > 0
    },
    get debugSync() {
      return this.isDebugEnabled('sync')
    },
    get snapshotLandEnabled() {
      return this.isDebugOrFlagEnabled('snap')
    },
    get splitLayout(): SplitEditorPref {
      return self.rootStore.user.prefs.splitLayout?.layout ?? 'rows'
    },
    get rundownScriptLayout(): RundownScriptLayout {
      return (
        self.rootStore.user.prefs.splitLayout ?? {
          layout: 'rows',
          enabled: false,
        }
      )
    },
    get showAnnouncementPopover(): boolean {
      return LAST_WHATS_NEW_UPDATE > (self.rootStore.user.prefs.whatsNew ?? 0)
    },
    isRequestedDocument(data: RequestedDocument): boolean {
      return (
        !!self.requestedDocument &&
        self.requestedDocument.type === data.type &&
        self.requestedDocument.id === data.id
      )
    },
    get editorZoom(): number {
      const pref = self.rootStore.user.prefs.editorZoom
      // avoid a divide-by-zero zoom situation
      return pref && pref > 0 ? pref : 1
    },
    get useNidoAlpha(): boolean {
      return this.isDebugOrFlagEnabled(NIDO_ALPHA)
    },
    get useNidoBeta(): boolean {
      return this.isDebugOrFlagEnabled(NIDO_BETA)
    },
    get hasBetaFormatting(): boolean {
      return this.isDebugOrFlagEnabled(FORMATTING)
    },
    get isZoomed(): boolean {
      return this.editorZoom !== 1
    },
    get selectedFolderIsCollection(): boolean {
      return isCollectionId(self.selectedFolderId)
    },
    get selectedFolderIsMyHistory(): boolean {
      return (
        isCollectionId(self.selectedFolderId) &&
        self.selectedFolderId === 'myHistory'
      )
    },
    get selectedFolderIsFavorites(): boolean {
      return (
        isCollectionId(self.selectedFolderId) &&
        self.selectedFolderId === 'favorites'
      )
    },
    get selectedFolderIsRecent(): boolean {
      return (
        isCollectionId(self.selectedFolderId) &&
        self.selectedFolderId === 'recentlyEdited'
      )
    },
    get selectedFolderInTrash(): boolean {
      return !!self.rootStore.folderMap.get(self.selectedFolderId)
        ?.belongsToTrashTree
    },
    get selectedFolderIsRoot(): boolean {
      return !!self.rootStore.folderMap.get(self.selectedFolderId)?.isRootFolder
    },
    get shouldUnwatermark(): boolean {
      return this.isDebugEnabled('unwatermark')
    },
    get debugSleep() {
      return this.isDebugEnabled('sleep')
    },
    get useNidoSockets(): boolean {
      return this.isDebugOrFlagEnabled(NIDO_SOCKETS)
    },
    get listingsPageSize(): number {
      return this.isDebugEnabled('small-page') ? 3 : GET_LISTINGS_PAGE_SIZE
    },
  }))
  .actions((self) => ({
    setEditorZoom(value: number) {
      if (value > 0) {
        self.trackEvent(self.MIXPANEL_EVENTS.SET_ZOOM_LEVEL, {
          zoomLevel: value * 100,
        })
        self.rootStore.user.updatePreferences({ editorZoom: value })
      }
      self.rootStore.currentScript?.pmEditor.rerender()
    },
    updateRundownScriptLayout(value: Partial<RundownScriptLayout>) {
      self.rootStore.user.updatePreferences({
        splitLayout: {
          ...self.rundownScriptLayout,
          ...value,
        },
      })
    },
    initializeReadonlyFolderState(root: IFolder, disabledFolderId?: string) {
      self.readonlyFolderListState = ReadonlyFolderListState.create({
        rootId: root.id,
        disabledFolderId,
      })
      self.readonlyFolderListState.setFolderExpanded(root, true)
      return self.readonlyFolderListState
    },
    setRequestedDocument(item: RequestedDocument | undefined) {
      self.requestedDocument = item
    },
    setSelectedFolderId(folderId: string) {
      if (folderId !== self.selectedFolderId) {
        self.folderCreatingChildId = undefined
        self.selectedFolderId = folderId

        // the two collections that make calls to api aren't set
        // up to auto-refresh
        if (folderId === 'favorites') {
          self.rootStore.refreshFavoriteItems()
        } else if (folderId === 'myHistory') {
          self.rootStore.refreshMyHistoryItems()
        }
      }
    },
    setCreatingChild(value: boolean) {
      const selectedFolder = self.rootStore.folderMap.get(self.selectedFolderId)
      if (selectedFolder && value) {
        self.folderCreatingChildId = selectedFolder.id
        if (!self.isFolderExpanded(selectedFolder)) {
          self.expandedFolders.push(selectedFolder)
        }
      } else {
        self.folderCreatingChildId = undefined
      }
    },
    startRenamingFolder(folderId: string) {
      self.folderBeingRenamedId = folderId
    },
    stopRenamingFolder() {
      self.folderBeingRenamedId = undefined
    },
    // if the user navigated to a /folders/:folderId route we run this
    // after the folder tree is loaded
    handleFoldersLoaded() {
      const { requestedDocument } = self
      if (requestedDocument) {
        self.requestedDocument = undefined
        if (!self.selectedFolderId) {
          this.expandFolderPath(requestedDocument.id)
          this.setSelectedFolderId(requestedDocument.id)
        }
      }
    },
    handleDocumentLoaded({
      id,
      uuid,
      folderId,
      type,
    }: {
      id: string | number
      uuid: string
      folderId: string
      type: DocumentListingType
    }) {
      if (self.requestedDocument) {
        const matchesRequest =
          type === self.requestedDocument.type &&
          String(id) === self.requestedDocument.id
        if (matchesRequest) {
          self.requestedDocument = undefined
          if (!self.selectedFolderId) {
            this.setSelectedFolderId(folderId)
            this.expandFolderPath(folderId)
          }
        }
      }
      self.analytics.trackDocOpened(type, id)
      self.rootStore.currentOrg?.updateMyHistory(uuid)
    },
    goToSearch(term: string) {
      self.rootStore.location.setPathname(`/search?q=${term}`)
    },
    toggleFolderExpanded(folder: IFolder) {
      if (self.expandedFolders.includes(folder)) {
        self.expandedFolders.remove(folder)
      } else {
        self.expandedFolders.push(folder)
      }
    },
    collapseAllFolders() {
      self.expandedFolders.replace([])
    },
    setFolderExpanded(folder: IFolder, expanded: boolean) {
      const currentlyExpanded = self.isFolderExpanded(folder)
      if (currentlyExpanded !== expanded) {
        this.toggleFolderExpanded(folder)
      }
    },
    expandFolderPath(folderId: string) {
      expandFolders({
        folderId,
        folders: self.rootStore.getFolderPath(folderId),
        isFolderExpanded: self.isFolderExpanded,
        setExpanded: (id: string) => self.expandedFolders.push(id),
      })
    },
    afterAttach() {
      const flagStr = self.rootStore.location.getQueryParam('debug')
      if (flagStr) {
        self.debugQueryParams.replace(flagStr.split(','))
      }
    },
  }))
