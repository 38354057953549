import { Badge, Group, Switch, Text } from '@mantine/core'

import { showAsyncConfirmModal } from '@components/Modals'
import { ILoadedScript, useMst } from '@state'

import styles from './SlackToggle.module.scss'

export const SlackToggle = ({
  readOnly,
  script,
}: {
  readOnly: boolean
  script: ILoadedScript
}) => {
  const { analytics, environment } = useMst()
  const { SLACK_CLIENT_ID } = environment.config

  const disableSlack = async () => {
    await script.disableSlack()
    analytics.track(analytics.EVENTS.SCRIPT_SLACK_INTEGRATION_DISABLED)
  }

  const toggleSlackConnection = () => {
    // null or false, trigger the OAuth flow
    // we pass the scriptId as state to redirect the user back to where they started
    if (!script.slackWebhook) {
      const url = `https://slack.com/oauth/v2/authorize?scope=incoming-webhook&client_id=${SLACK_CLIENT_ID}&redirect_uri=${window.location.origin}/oauth/callback&state=${script.id}`

      window.location.href = url
    } else {
      // disable the integration
      showAsyncConfirmModal({
        onConfirm: disableSlack,
        title: 'Disconnect from Slack',
        children:
          'Are you sure you want to disable the Slack integration for this script?',
        dangerous: true,
        errorMessage: 'An unexpected error occurred',
      })
    }
  }

  // TODO: pass down click handler to make overriding in storybook possible
  return readOnly ? (
    <Group p={10} className={styles.slackToggle}>
      <Text>Connect to Slack</Text>
      <Badge color="violet">Paid</Badge>
    </Group>
  ) : (
    <Switch
      className={styles.slackToggle}
      label="Connect to Slack"
      checked={script.slackWebhook}
      onChange={toggleSlackConnection}
      p={10}
    />
  )
}
