import { EditorState, TextSelection } from 'prosemirror-state'
import { EditorView } from 'prosemirror-view'

/**
 * a true AllSelection can lead to an undesired page break when the paste target
 * isnt the first block in a page, so we mimic it with a TextSelection that spans
 * from the opening of the first block to the close of the last block
 */
export const selectAlmostAll = (
  state: EditorState,
  dispatch: EditorView['dispatch'],
) => {
  const { doc, tr } = state
  const start = doc.resolve(2)
  const end = doc.resolve(doc.content.size - 2)

  tr.setSelection(new TextSelection(start, end))
  dispatch(tr)

  // return true to ensure the default PM shortcut isn't triggered too
  return true
}
