import React from 'react'

import NiceModal from '@ebay/nice-modal-react'
import {
  Button,
  Divider,
  Group,
  SegmentedControl,
  Tabs,
  Text,
} from '@mantine/core'
import deepEqual from 'fast-deep-equal'

import { CustomIcon } from '@components/CustomIcon'
import { menuData } from '@components/EditorToolbar/ElementMenu/display-data'
import { ModalShell, useModalControls } from '@components/Modals'
import { ILoadedScript, useMst } from '@state'
import { BLACKISH_TEXT } from '@theme/colors'
import { getSelectionBlockType, setBlockOverrides } from '@util'
import {
  BlockOverrides,
  enhancedBlockOverride,
  FormatBlockName,
  minifyOverrides,
} from '@util/formats'
import { SCRIPT_BLOCK_FORMATTING_CUSTOMIZED } from '@util/mixpanel/eventNames'

import { FormattingCheckboxes } from './FormattingCheckboxes'
import { BulkChangeHandler } from './helpers'
import { LayoutInputs } from './LayoutInputs'
import { TextColorPicker } from './TextColorPicker'

import styles from './CustomizeElementStyles.module.scss'

const validateOverrides = (overrides: BlockOverrides): boolean => {
  for (const block of Object.values(overrides)) {
    if (!enhancedBlockOverride.safeParse(block).success) {
      return false
    }
  }
  return true
}

const CustomizeElementStylesModal = NiceModal.create(
  ({ script }: { script: ILoadedScript }) => {
    const mst = useMst()
    const controls = useModalControls()
    const menuItems = menuData[script.type]

    const pmSelectionBlockType = script.pmEditor.editorState
      ? getSelectionBlockType(script.pmEditor.editorState)
      : undefined

    const findMenuItem = (id: string | undefined) =>
      menuItems.find((i) => i.id === id)

    const [selectedBlock, setSelectedBlock] = React.useState<FormatBlockName>(
      findMenuItem(pmSelectionBlockType)?.id ?? menuItems[0].id,
    )

    const [unsavedOverrides, setUnsavedOverrides] =
      React.useState<BlockOverrides>({})

    const setValues: BulkChangeHandler = (blockName, changes) => {
      // this will always be true
      if (!script.blockFormats) return

      const mergedValue: BlockOverrides = {
        ...script.blockFormats,
        ...unsavedOverrides,
        [blockName]: {
          ...script.blockFormats[blockName],
          ...unsavedOverrides[blockName],
          ...changes,
        },
      }
      setUnsavedOverrides(mergedValue)
    }

    const saveChanges = () => {
      const { editorView } = script.pmEditor
      if (editorView) {
        const minifiedOverrides = minifyOverrides(
          script.originalBlockFormats,
          unsavedOverrides,
        )

        setBlockOverrides(editorView, minifiedOverrides)
        mst.trackEvent(SCRIPT_BLOCK_FORMATTING_CUSTOMIZED, {
          scriptId: script.id,
          overrides: minifiedOverrides,
        })
        controls.onClose()
      }
    }

    const currentColor = script.currentFormatValue(selectedBlock, 'color') ?? ''
    const pendingColor = unsavedOverrides[selectedBlock]?.color ?? currentColor
    const originalColor =
      script.originalFormatValue(selectedBlock, 'color') ?? ''

    const disableSave =
      !validateOverrides(unsavedOverrides) || deepEqual(unsavedOverrides, {})

    return (
      <ModalShell
        size={600}
        title="Customize block type styles"
        opened={controls.opened}
        onClose={controls.onClose}
        errorMessage={controls.errorMessage}
        loading={controls.loading}
      >
        <Group align="start" gap={0}>
          <SegmentedControl
            transitionDuration={0}
            classNames={{
              control: styles.elementList_control,
              root: styles.elementList_root,
            }}
            onChange={(id) => {
              const item = findMenuItem(id)
              if (item) {
                setSelectedBlock(item.id)
              }
            }}
            value={selectedBlock}
            size="md"
            color="violet"
            orientation="vertical"
            withItemsBorders={false}
            data={menuItems.map((item) => {
              const isSelected = selectedBlock === item.id
              return {
                value: item.id,
                label: (
                  <Group gap={10}>
                    <CustomIcon
                      icon={item.icon}
                      c={isSelected ? 'white' : 'dark.4'}
                    />
                    <Text c={isSelected ? 'white' : 'dark.4'}>
                      {item.title}
                    </Text>
                  </Group>
                ),
              }
            })}
          ></SegmentedControl>
          <Divider orientation="vertical" ml={10} mr={20} />
          <Tabs defaultValue="layout">
            <Tabs.List>
              <Tabs.Tab value="layout">Layout</Tabs.Tab>
              <Tabs.Tab value="styles">Styles</Tabs.Tab>
            </Tabs.List>
            <Tabs.Panel
              value="layout"
              className={styles.elementCustom_tabPanel}
              pb={20}
              pt={20}
              px={20}
            >
              <LayoutInputs
                script={script}
                unsavedOverrides={unsavedOverrides}
                selectedBlock={selectedBlock}
                onChange={setValues}
              />
            </Tabs.Panel>
            <Tabs.Panel
              value="styles"
              className={styles.elementCustom_tabPanel}
              pb={20}
              pt={20}
              px={20}
            >
              <Group align="flex-start" mt={20}>
                <TextColorPicker
                  pendingColor={pendingColor}
                  currentColor={currentColor}
                  originalColor={originalColor}
                  onChange={(newColor: string) => {
                    const color =
                      newColor.toLowerCase() === BLACKISH_TEXT ? '' : newColor

                    setValues(selectedBlock, { color })
                  }}
                  originalColors={script.originalFormatColors}
                />
                <FormattingCheckboxes
                  script={script}
                  unsavedOverrides={unsavedOverrides}
                  selectedBlock={selectedBlock}
                  onChange={setValues}
                />
              </Group>
            </Tabs.Panel>
          </Tabs>
        </Group>
        <Divider />
        <Group gap="xs" justify="center">
          <Button type="submit" onClick={saveChanges} disabled={disableSave}>
            Save
          </Button>
          <Button variant="outline" onClick={controls.onClose}>
            Cancel
          </Button>
        </Group>
      </ModalShell>
    )
  },
)

export const showCustomizeElementStylesModal = (script: ILoadedScript) => {
  NiceModal.show(CustomizeElementStylesModal, { script })
}
