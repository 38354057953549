import { ScriptDocType, ScriptStatus } from '@showrunner/codex'

import { SharedScriptStatus } from '@state'

import { MoveScriptPayload, ScriptMetaPayload, ScriptPayload } from '../types'

import { ApiConfig } from './types'

export const renameScript: ApiConfig<
  {
    scriptId: string
    name: string
  },
  unknown
> = ({ scriptId, name }) => ({
  method: 'PUT',
  url: `/scripts/${scriptId}`,
  data: { name },
})

export const moveScript: ApiConfig<
  {
    scriptId: string
    folderId: string
  },
  MoveScriptPayload
> = ({ scriptId, folderId }) => ({
  method: 'PUT',
  url: `/scripts/${scriptId}/folder`,
  data: { folderId },
})

export const updateScriptStatus: ApiConfig<
  { scriptId: string; status: ScriptStatus; socketId: string },
  { status: SharedScriptStatus }
> = ({ scriptId, status, socketId }) => ({
  method: 'PUT',
  url: `/scripts/${scriptId}/status/${status}`,
  data: { socketId },
})

export const createScript: ApiConfig<
  {
    parentId: string
    name: string
    type: ScriptDocType
    format: string
    doc?: unknown
  },
  { id: string }
> = (data) => ({
  method: 'POST',
  url: '/scripts',
  data,
})

export const destroyScript: ApiConfig<string, unknown> = (scriptId) => ({
  method: 'DELETE',
  url: `/scripts/${scriptId}/destroy`,
})

export const duplicateScript: ApiConfig<
  { scriptId: string },
  { id: string }
> = ({ scriptId }) => ({
  method: 'GET',
  url: `/scripts/${scriptId}/copy`,
})

export const trashScript: ApiConfig<
  { scriptId: string },
  { message?: 'success'; error?: string }
> = ({ scriptId }) => ({
  method: 'DELETE',
  url: `/scripts/${scriptId}`,
})

export const addScriptToSlack: ApiConfig<
  {
    scriptId: string
    slackToken: string
  },
  void
> = ({ scriptId, slackToken }) => ({
  method: 'GET',
  url: `/scripts/${scriptId}/slack?code=${slackToken}`,
})

export const removeScriptFromSlack: ApiConfig<
  {
    scriptId: string
  },
  void
> = ({ scriptId }) => ({
  method: 'DELETE',
  url: `/scripts/${scriptId}/slack`,
})

export const getScript: ApiConfig<string, ScriptPayload> = (scriptId) => ({
  method: 'GET',
  url: `/scripts/${scriptId}`,
})

export const getScriptMeta: ApiConfig<string, ScriptMetaPayload> = (
  scriptId,
) => ({
  method: 'GET',
  url: `/scripts/${scriptId}/meta`,
})
