import { ActionIcon, Overlay, Space, Stack, Text } from '@mantine/core'
import { useElementSize } from '@mantine/hooks'
import { RouteComponentProps } from 'wouter'

import { FaIcon } from '@components/FaIcon'
import { Toolbar } from '@components/Toolbar'
import { useMst, useNavigation } from '@hooks'

import { HistoryAndBody } from './HistoryAndBody'
import { SnapshotLandToolbar } from './toolbar'
import { useScriptData } from './useSnapshotLandData'

import styles from './SnapshotLand.module.scss'

const Title = ({ name }: { name?: string }) => {
  if (name) {
    return (
      <Text span>
        Snapshot history for&nbsp;
        <Text span fw="bold">
          {name}
        </Text>
      </Text>
    )
  }
  return <Text span>Snapshot history</Text>
}

export const SnapshotLand = ({
  params,
}: RouteComponentProps<{ scriptId: string }>) => {
  const { view } = useMst()
  const { navigate } = useNavigation()
  const scriptQuery = useScriptData(params.scriptId)
  const { ref: sizeRef, width } = useElementSize()

  const exitSnapshotLand = () => {
    navigate(view.explorerState.lastScriptPath ?? `/scripts/${params.scriptId}`)
  }

  return (
    <Overlay backgroundOpacity={1} fixed ref={sizeRef}>
      <Stack className={styles.snapshotLand} gap={0}>
        <Toolbar>
          <Toolbar.Section position="left">
            <Space h={20} />
            <FaIcon icon="fa-clock-rotate-left" />
            <Title name={scriptQuery.data?.script.name} />
          </Toolbar.Section>
          <Toolbar.Section position="right">
            <ActionIcon onClick={exitSnapshotLand}>
              <FaIcon c="dark.9" icon="fa-xmark" size="18" />
            </ActionIcon>
          </Toolbar.Section>
        </Toolbar>
        <SnapshotLandToolbar scriptId={params.scriptId} />
        <HistoryAndBody scriptId={params.scriptId} width={width} />
      </Stack>
    </Overlay>
  )
}
