// These are all the pathname patterns we recognize in our app
import { match, pathToRegexp } from 'path-to-regexp'

// these are the ones that choo is involved with (even if just for routing logic)
const scriptEditor = '/scripts/:scriptId'
const splitEditor = '/rundowns/:rundownId/scripts/:scriptId'
const compareSnapshot = '/scripts/:scriptId/compare/:snapshotId'
const diffSnapshot = '/scripts/:scriptId/diff/:snapshotId'
const snapshot = '/scripts/:scriptId/snapshots/:snapshotId'

const scriptHistory = '/scripts/:scriptId/history'

// these are ones that choo needs know nothing about
const root = '/'
const folder = '/folders/:folderId'
const staffLanding = '/staff'
const staffWorkspaces = '/staff/workspaces'
const staffInvites = '/staff/invites'
const staffFlags = '/staff/flags'
const staffRundowns = '/staff/rundowns'
const staffUsers = '/staff/users'
const staffUserDetail = '/staff/users/:uuid'
const staffFormats = '/staff/formats'
const staffWorkspaceDetail = '/staff/workspaces/:uuid'
const staffCloneWorkspace = '/staff/clone'
const staffTemplateWorkspaces = '/staff/templates'
const rundown = '/rundowns/:rundownId'
const archivedScript = '/wayback/:id'
const settings = '/settings/:rest*'
const settingsMembers = '/settings/org/members'
const oauthCallback = '/oauth/callback'
const login = '/login'
const logout = '/logout'
const createAccount = '/create-account'
const forgotPassword = '/forgot-password'
// temporary route to help us lazily migrate legacy users to stytch
const updatePassword = '/update-password'

const inkPlayer = '/inkplayer'

// signup is an oddball-- allows authed/unauthed requests & redirects them if not authed.
// It's slated for retirement once we stop using invite codes.
const signup = '/signup'

// allows authed requests with or without orgs
const createWorkspace = '/create-workspace'

export const ROUTE_PATTERNS = {
  root,
  scriptEditor,
  splitEditor,
  scriptHistory,
  snapshot,
  compareSnapshot,
  diffSnapshot,
  signup,
  createWorkspace,
  folder,
  staffFlags,
  staffRundowns,
  staffLanding,
  staffWorkspaces,
  staffFormats,
  staffInvites,
  staffUsers,
  staffUserDetail,
  staffWorkspaceDetail,
  staffCloneWorkspace,
  staffTemplateWorkspaces,
  rundown,
  archivedScript,
  settings,
  settingsMembers,
  oauthCallback,
  login,
  logout,
  createAccount,
  forgotPassword,
  updatePassword,
  inkPlayer,
} as const

export type RoutePattern = keyof typeof ROUTE_PATTERNS

export type RoutePathConfig = {
  pattern: string
  matcher: RegExp
  requiresAuth: boolean
  requiresOrg: boolean
  requiresStaff: boolean
}

// The endsWith means we can match paths with query strings
const buildMatcher = (pattern: string): RegExp =>
  pathToRegexp(pattern, undefined, { endsWith: '?#' })

const staffRoute = (pattern: string): RoutePathConfig => ({
  pattern,
  matcher: buildMatcher(pattern),
  requiresAuth: true,
  requiresStaff: true,
  // for now this is the case for staff routes because the header blows
  // up without an org.
  requiresOrg: true,
})

const workspaceRoute = (pattern: string): RoutePathConfig => ({
  pattern,
  matcher: buildMatcher(pattern),
  requiresAuth: true,
  requiresStaff: false,
  requiresOrg: true,
})

const preauthRoute = (pattern: string): RoutePathConfig => ({
  pattern,
  matcher: buildMatcher(pattern),
  requiresAuth: false,
  requiresStaff: false,
  requiresOrg: false,
})

// This expresses the relationship between a supplied pathname
// and a route configuration.
const configs: Record<RoutePattern, RoutePathConfig> = {
  // commonplace routes-- you're in an org
  scriptEditor: workspaceRoute(scriptEditor),
  splitEditor: workspaceRoute(splitEditor),
  root: workspaceRoute(root),
  folder: workspaceRoute(folder),
  rundown: workspaceRoute(rundown),
  archivedScript: workspaceRoute(archivedScript),
  settings: workspaceRoute(settings),
  settingsMembers: workspaceRoute(settingsMembers),
  oauthCallback: workspaceRoute(oauthCallback),
  scriptHistory: workspaceRoute(scriptHistory),
  snapshot: workspaceRoute(snapshot),
  compareSnapshot: workspaceRoute(compareSnapshot),
  diffSnapshot: workspaceRoute(diffSnapshot),
  inkPlayer: preauthRoute(inkPlayer),

  // staff routes-- can be condensed like settings later
  // into /staff/:rest*
  staffLanding: staffRoute(staffLanding),
  staffWorkspaces: staffRoute(staffWorkspaces),
  staffInvites: staffRoute(staffInvites),
  staffFlags: staffRoute(staffFlags),
  staffRundowns: staffRoute(staffRundowns),
  staffUsers: staffRoute(staffUsers),
  staffUserDetail: staffRoute(staffUserDetail),
  staffFormats: staffRoute(staffFormats),
  staffWorkspaceDetail: staffRoute(staffWorkspaceDetail),
  staffCloneWorkspace: staffRoute(staffCloneWorkspace),
  staffTemplateWorkspaces: staffRoute(staffTemplateWorkspaces),

  // These do not require a user to belong to an org
  createWorkspace: {
    requiresAuth: true,
    requiresOrg: false,
    requiresStaff: false,
    pattern: createWorkspace,
    matcher: buildMatcher(createWorkspace),
  },
  signup: {
    requiresAuth: false,
    requiresOrg: false,
    requiresStaff: false,
    pattern: signup,
    matcher: buildMatcher(signup),
  },

  // pre-auth routes
  login: preauthRoute(login),
  logout: preauthRoute(logout),
  createAccount: preauthRoute(createAccount),
  forgotPassword: preauthRoute(forgotPassword),
  updatePassword: preauthRoute(updatePassword),
}

export const findConfigForPathname = (
  pathname: string,
): RoutePathConfig | undefined => {
  return Object.values<RoutePathConfig>(configs).find((config) =>
    config.matcher.test(pathname),
  )
}

// This is figuring out whether a url is for a configured pattern and if so
// what its parameters are. For example, if the url is /scripts/abc/snapshots/def
// this will return { scriptId: 'abc', snapshotId: 'def' }. But if it doesn't match
// any configured patterns, it will return null.
//
// NOTE: this is something we're doing that wouter does internally but we need to do it in
// mst and it's only exposed there as a hook. Once we get more of the routing out of mst, we
// can move this into a route where it really belongs (e.g. the loading route on app launch)
export const extractParameters = (pathname: string): StringMap | null => {
  const config = findConfigForPathname(pathname)
  if (config) {
    const parsed = match(config.pattern)(pathname)
    if (parsed !== false) {
      return parsed.params as StringMap
    }
  }
  return null
}

export const extractResourceParameter = (
  pathname: string,
): {
  resourceType: ListingType
  resourceId: string | number
} | null => {
  const params = extractParameters(pathname)
  if (params?.rundownId) {
    return {
      resourceType: 'rundown',
      resourceId: parseInt(params.rundownId),
    }
  }
  if (params?.scriptId) {
    return {
      resourceType: 'script',
      resourceId: params.scriptId,
    }
  }
  if (params?.folderId) {
    return {
      resourceType: 'folder',
      resourceId: params.folderId,
    }
  }
  return null
}

const validRedirectAfterLogin = (pathname: string): boolean => {
  const config = findConfigForPathname(pathname)
  if (!config || !config.requiresAuth) {
    return false
  }
  if ([logout, oauthCallback].includes(config.pattern)) {
    return false
  }
  return true
}

export const buildLoginRedirect = ({
  pathname,
  search,
}: {
  pathname: string
  search: string
}) => {
  const canReturnTo = validRedirectAfterLogin(pathname)
  const hasInformation = pathname !== ROUTE_PATTERNS.root || search.length > 0

  if (canReturnTo && hasInformation) {
    return `${ROUTE_PATTERNS.login}?returnTo=${pathname}${search}`
  }
  return ROUTE_PATTERNS.login
}
