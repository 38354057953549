import { DOMSerializer, Node as PmNode } from 'prosemirror-model'
import { renderToString } from 'react-dom/server'

import { schema, util } from '@showrunner/codex'

import { TwoColumnScript } from '@components/TwoColumnScript'
import { buildFormatStyleString } from '@layouts/Script/buildFormatStyles'
import { formatSeconds, getPrintTimestamp } from '@util'
import { BlockFormats, ScriptFormatConfiguration } from '@util/formats'
import { createPaginationBreakdown } from '@util/pagination'
import * as cssStrings from '@util/printing/cssStrings'
import { createProsemirrorDoc, PaginationType } from '@util/prosemirrorHelpers'
import { RundownRowData, ScriptJson } from '@util/ScriptoApiClient/types'
import {
  PageLayout,
  PageMarginSizes,
  ScriptPrintPreferences,
} from '@util/zodSchemas/printPreferences'

import { buildHtmlForPrince } from './buildHtmlForPrince'
import { buildPageCssVars } from './buildPageCssVars'
import * as constants from './constants'
import { ScriptHeaderAndFooter } from './HeaderAndFooter'
import { IPrincePrintStrategy } from './types'

// when we print a whole script, we create a snapshot
// and pull the doc and formatDefinition from the immutable artifact
// when we print a section, we pass the same props directly
type ScriptPrintStrategyParams = {
  doc: ScriptJson
  formatDefinition: ScriptFormatConfiguration
  prefs: ScriptPrintPreferences
  timestamp: string
  title: string
  readRate?: number
  duration?: string
  rundownRowData?: RundownRowData
}

type AsteriskPrintStrategyParmas = {
  html: string
} & ScriptPrintStrategyParams

type SideBySidePrintStrategyParmas = {
  html: string
  headerHtml: string
} & ScriptPrintStrategyParams

const generateOneColumnStyles = ({
  monochrome,
  blockFormats,
}: {
  monochrome: boolean
  blockFormats: BlockFormats
}) => `
${buildFormatStyleString(blockFormats, monochrome)}
${cssStrings.editorCSS}
${cssStrings.oneColumnCSS}
`

// This abstract class is the common base for printing 1 or 2 column scripts and should contain
// any state or logic that is common across both
abstract class BaseScriptPrintStrategy implements IPrincePrintStrategy {
  protected prefs: ScriptPrintPreferences
  protected title: string
  protected timestamp: string
  protected duration: string | undefined
  protected rundownRowData: RundownRowData | undefined
  protected doc: PmNode
  protected blockFormats: BlockFormats
  protected paginationType: PaginationType | undefined

  constructor({
    doc,
    formatDefinition,
    prefs,
    title,
    timestamp,
    duration,
    rundownRowData,
  }: ScriptPrintStrategyParams) {
    this.doc = createProsemirrorDoc(doc)
    this.blockFormats = formatDefinition.blocks
    this.paginationType = formatDefinition.paginationType
    this.prefs = prefs
    this.timestamp = timestamp
    this.title = title
    this.duration = duration
    this.rundownRowData = rundownRowData
  }

  // The One and Two-column strategies are responsible for implementing these
  // measurements for the margins
  abstract margins: PageMarginSizes
  // any styles specific to the print format
  abstract generateFormatSpecificStyles(): string
  // the HTML that goes into the body
  abstract generateBody(): string

  generateHeadElements(): string {
    return constants.FONTS
  }

  // this combines styles common to all script print html with
  // the format-specific ones provided by the subclass
  generateStyles() {
    return `
      ${
        this.prefs.headers.showOnFirstPage
          ? ''
          : constants.HIDE_HEADERS_ON_FIRST_PAGE
      }
      ${
        this.prefs.footers.showOnFirstPage
          ? ''
          : constants.HIDE_FOOTERS_ON_FIRST_PAGE
      }
      ${buildPageCssVars({
        layout: this.generatePageLayout(),
        title: this.title,
        timestamp: this.timestamp,
        monochrome: this.prefs.monochrome,
        duration: this.duration,
        rundownRowData: this.rundownRowData,
      })}
      ${cssStrings.headerFooterCSS}
      ${this.generateFormatSpecificStyles()}
    `
  }

  generatePageLayout(): PageLayout {
    return {
      size: { height: '11in', width: '8.5in' },
      margins: this.margins,
      orientation: 'portrait',
    }
  }

  generateHeaderAndFooter() {
    return renderToString(<ScriptHeaderAndFooter prefs={this.prefs} />)
  }
}

class OneColumnPrintStrategy extends BaseScriptPrintStrategy {
  constructor(params: ScriptPrintStrategyParams) {
    super(params)
  }

  margins = constants.ONE_COLUMN_MARGIN_SIZE

  generateFormatSpecificStyles() {
    return generateOneColumnStyles({
      monochrome: this.prefs.monochrome,
      blockFormats: this.blockFormats,
    })
  }

  generateBody() {
    const domSerializer = DOMSerializer.fromSchema(schema)
    const docFragment = domSerializer.serializeFragment(this.doc.content)
    const serializer = new XMLSerializer()
    const isInline = this.paginationType === 'inline'

    const paginationClass = isInline ? 'is-inline' : 'is-structural'

    if (isInline) {
      const { pageBreaks } = createPaginationBreakdown(
        this.doc,
        this.blockFormats,
      )
      pageBreaks.forEach(({ blockId, pageNumber }) => {
        const elt = docFragment.querySelector(`[id='${blockId}']`)
        elt?.classList.add('start-of-page')
        // not used yet because we're just doing 1,2,3... but
        // once we have page locking, this can be wired up into
        // the print css
        elt?.setAttribute('data-page-number', pageNumber)
      })
    }

    return `
      <div contenteditable="false" translate="no" class="ProseMirror is-static ${paginationClass}">
        ${serializer.serializeToString(docFragment)}
      </div>
    `
  }
}

class TwoColumnPrintStrategy extends BaseScriptPrintStrategy {
  constructor(params: ScriptPrintStrategyParams) {
    super(params)
  }

  margins = constants.TWO_COLUMN_MARGIN_SIZE

  generateFormatSpecificStyles() {
    return cssStrings.twoColumnCSS
  }

  generateBody() {
    const scriptJson = this.doc.toJSON() as ScriptJson
    return renderToString(
      <TwoColumnScript
        prefs={this.prefs}
        scriptJson={scriptJson}
        blockFormats={this.blockFormats}
      />,
    )
  }
}

class OneColumnAsteriskPrintStrategy extends BaseScriptPrintStrategy {
  protected html: string

  constructor(params: AsteriskPrintStrategyParmas) {
    super(params)
    this.html = params.html
  }

  margins = constants.ONE_COLUMN_MARGIN_SIZE

  generateFormatSpecificStyles() {
    return generateOneColumnStyles({
      monochrome: this.prefs.monochrome,
      blockFormats: this.blockFormats,
    })
  }

  generateBody() {
    return this.html
  }
}

// NOTE: We should really move this out of this file and treat it
// as a one-off.  The HTML is already generated and we don't use the
// values passed in for other print script strategies (e.g. the prose
// doc) in any way. This is much more like printing a rundown than
// printing a script.
class SideBySidePrintStrategy extends BaseScriptPrintStrategy {
  protected html: string
  protected headerHtml: string

  constructor(params: SideBySidePrintStrategyParmas) {
    super(params)
    this.html = params.html
    this.headerHtml = params.headerHtml
  }

  margins = constants.SIDE_BY_SIDE_MARGIN_SIZE

  generateFormatSpecificStyles() {
    return `
      ${cssStrings.sideBySideCSS}
      ${cssStrings.sideBySidePrintCSS}
    `
  }

  generateBody() {
    return this.html
  }

  generatePageLayout(): PageLayout {
    return {
      size: { height: '11in', width: '8.5in' },
      margins: this.margins,
      orientation: 'landscape',
    }
  }
  // for this layout specifically we pull a header from the guts of the side by side screen display
  // and draw page numbers in the footer declared using a simple prince counter in SideBySidePrint.scss
  // passing html inside the <header/> requires that we override the users active print preferences
  // (to ensure it is enabled on each page) but the page count in the footer displays even without an override
  generateHeaderAndFooter() {
    return renderToString(
      <header dangerouslySetInnerHTML={{ __html: this.headerHtml }} />,
    )
  }
}

// this is what is passed in to the helper functions
type PrintScriptParams = {
  doc: ScriptJson
  formatDefinition: ScriptFormatConfiguration
  prefs: ScriptPrintPreferences
  title: string
  readRate?: number
  rundownRowData?: RundownRowData
}

// we can use the PrintScriptParams to generate duration & timestamp
const enrichWithComputedValues = <T extends PrintScriptParams>(
  params: T,
): T & { timestamp: string; duration?: string } => {
  const { doc, readRate } = params
  const isScreenplay = doc.attrs.docType === 'screenplay'
  const timestamp = getPrintTimestamp()
  const duration =
    !isScreenplay && readRate
      ? formatSeconds(
          new util.ScriptBreakdown(createProsemirrorDoc(doc), readRate).timing
            .totalSeconds,
        )
      : undefined
  return {
    ...params,
    timestamp,
    duration,
  }
}

export const buildPrintableScriptHtml = (params: PrintScriptParams): string => {
  const { doc, prefs } = params
  const isScreenplay = doc.attrs.docType === 'screenplay'
  const shouldPrintTwoColumn = prefs.columns && !isScreenplay
  const strategyParams = enrichWithComputedValues(params)
  const strategy: IPrincePrintStrategy = shouldPrintTwoColumn
    ? new TwoColumnPrintStrategy(strategyParams)
    : new OneColumnPrintStrategy(strategyParams)

  return buildHtmlForPrince(strategy)
}

export const buildPrintableRevisionHtml = (
  params: PrintScriptParams & { html: string },
): string => {
  const strategy = new OneColumnAsteriskPrintStrategy(
    enrichWithComputedValues(params),
  )
  return buildHtmlForPrince(strategy)
}

// TODO: see comment above in the strategy, this shares so little code with other forms
// of script printing that it should not be here
export const buildPrintableDiffHtml = (
  params: PrintScriptParams & {
    html: string
    headerHtml: string
  },
): string => {
  const strategy = new SideBySidePrintStrategy(enrichWithComputedValues(params))
  return buildHtmlForPrince(strategy)
}
