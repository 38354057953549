/*
  LocalPersistence manages storing data in localStorage and browser cookies.
  It allows an in-memory storage to be used instead so that tests and storybook
  can mock the storage without a browser.

  Note: CookieStorage is used over browser localStorage for persisting credentials
  and userId. This is done for choo app compatibility and because we pass the session
  cookie on requests to some server routes. window.localStorage is used for user preferences
*/
import jsc from 'js-cookie'

import { ExplorerViewType, SplitEditorPref } from '@state/types'
import {
  RundownPrintPrefs,
  ScriptPrintPreferences,
} from '@util/zodSchemas/printPreferences'

import { RundownRowData } from '../ScriptoApiClient/types'

import { OpenedItemsPref, prefSchemas } from './prefSchemas'
export { prefSchemas as schemas }

export type SortOrder = 'recent' | 'alphabetical' | 'newest' | 'oldest'
export type SortOrderPreferences = {
  [key: string]: SortOrder
}

export type MyHistory = OpenedItemsPref

interface KVStore {
  get: (key: string, options?: jsc.CookieAttributes) => string | undefined
  set: (key: string, value: string, options?: jsc.CookieAttributes) => void
  remove: (key: string, options?: jsc.CookieAttributes) => void
}

export type RundownLayoutType = 'screen' | 'print'

export type RundownScriptLayout = {
  layout: SplitEditorPref
  enabled: boolean
  rundownWidth?: number
  rundownHeight?: number
}

export type RundownColumnState = {
  [colId: string]: {
    width?: number
    hide?: boolean | null
  }
}

export type PrompterViewPrefs = {
  fontSize: number
  darkMode: boolean
  reversed: boolean
  autoUpdate: boolean
}

export const defaultPrompterViewPrefs: PrompterViewPrefs = {
  fontSize: 36,
  darkMode: true,
  reversed: false,
  autoUpdate: false,
}

export type UserPreferences = {
  bracketsInNav?: boolean
  sidebarClosed?: boolean
  sidebarOpenWidth?: number
  pageless?: boolean
  sidebarCurrentPane?: ExplorerViewType
  snapshotFilter?: 'all' | 'manual'
  wrapRundownText?: boolean
  prompterView?: PrompterViewPrefs
  whatsNew?: number
  editorZoom?: number
  splitLayout?: RundownScriptLayout
  hideCollabAvatars?: boolean // displayed be default
  hideBlockMenu?: boolean // displayed by default
  showCollabCursors?: boolean // hidden by default
  currentOrgId?: string
  suppressConfirmations?: boolean
  showDebugToolbar?: boolean
  snapshotHistoryWidth?: number
  showMarginRuler?: boolean
}

const KEYS = {
  PREFERENCES: 'scripto-preferences',
  PDF_PREFERENCES: 'scripto-pdf-preferences',
  SORT_PREFERENCES: 'scripto-sort-preferences',
  RUNDOWN: 'rd',
  FAVORITES: 'scripto-favorites',
  MY_HISTORY: 'scripto-my-history',
  RUNDOWN_CLIPBOARD: 'scripto-rdclipboard',
  INK_PREFS: 'scripto-ink-preferences',
  RD_PRINT_PREFS: 'scripto-rd-print-prefs',
  SCRIPT_PRINT_PREFS: 'scripto-script-print-prefs',
} as const

export type InkPreferences = {
  lineWrap?: boolean
  syntaxHighlighting?: boolean
}

// for printing scripts and snapshots
export type EditorPdfPreferences = {
  columns: boolean
  headers: boolean
}

export const defaultEditorPdfPreferences: EditorPdfPreferences = {
  headers: true,
  columns: false,
}

export type RundownClipboard = {
  schemaId: number
  rows: RundownRowData[]
  copiedAt: string
}

function parseSavedBlob<
  T extends
    | UserPreferences
    | RundownColumnState
    | EditorPdfPreferences
    | RundownClipboard
    | InkPreferences
    | Partial<ScriptPrintPreferences>,
>(value?: string): T {
  if (!value) {
    return {} as T
  }
  try {
    const parsed = JSON.parse(value)
    return typeof parsed === 'object' ? parsed : {}
  } catch {
    return {} as T
  }
}

// this is the local storage key. For the main preferences we store
// as `script-preferences:<user-id>` but some other preferences are split out
// into their own keys, like rundown column prefs for a schema, etc
const keyForUser = (userId: string, scope?: string): string => {
  return scope ? `${scope}:${userId}` : `${KEYS.PREFERENCES}:${userId}`
}

const keyForOrgUser = ({
  scope,
  userId,
  orgId,
}: {
  userId: string
  orgId: string
  scope: string
}) => {
  return `${scope}:${userId}:${orgId}`
}

// window.localstorage doesn't call get/set/remove with the same names
// so we can use this to get a more generic interface
const LocalStorage: KVStore = {
  get: (key: string) => window.localStorage.getItem(key) || undefined,
  set: (key: string, value: string) => window.localStorage.setItem(key, value),
  remove: (key: string) => window.localStorage.removeItem(key),
}

// NOTE: cookie credentials are being stored with these keys as well and we are
// sharing them with choo. We don't want to change these names until we stop sharing
const LOGGED_IN_KEY = 'isLoggedIn'

// the logged in key only uses a 2 part hostname so we can share it with microsite
const fullHostname = window.location.hostname
const twoPartHostname = fullHostname.split('.').slice(-2).join('.')

export class LocalPersistence {
  protected _cookieStorage: KVStore
  protected _localStorage: KVStore

  constructor(storage?: KVStore) {
    this._localStorage = storage || LocalStorage
    this._cookieStorage = storage || jsc
  }
  markLoggedIn = (isLoggedIn: boolean) => {
    if (isLoggedIn) {
      this._cookieStorage.set(LOGGED_IN_KEY, 'true', {
        domain: twoPartHostname,
      })
    } else {
      this._cookieStorage.remove(LOGGED_IN_KEY, {
        domain: twoPartHostname,
      })
    }
  }

  getUserPreferences = (userId: string) => {
    const stringValue = this._localStorage.get(keyForUser(userId))
    return Object.freeze(parseSavedBlob<UserPreferences>(stringValue))
  }

  setUserPreferences = ({
    userId,
    preferences,
  }: {
    userId: string
    preferences: Partial<UserPreferences>
  }) => {
    const key = keyForUser(userId)
    this._localStorage.set(key, JSON.stringify(preferences))
  }

  getSortOrderPreferences = (userId: string) => {
    const key = keyForUser(userId, KEYS.SORT_PREFERENCES)
    const stringValue = this._localStorage.get(key)
    return Object.freeze(parseSavedBlob<SortOrderPreferences>(stringValue))
  }

  setSortOrderPreferences = ({
    userId,
    preferences,
  }: {
    userId: string
    preferences: SortOrderPreferences
  }) => {
    const key = keyForUser(userId, KEYS.SORT_PREFERENCES)
    this._localStorage.set(key, JSON.stringify(preferences))
  }

  setEditorPdfPreferences = ({
    userId,
    preferences,
  }: {
    userId: string
    preferences: EditorPdfPreferences
  }) => {
    const key = keyForUser(userId, KEYS.PDF_PREFERENCES)
    this._localStorage.set(key, JSON.stringify(preferences))
  }

  keyForRundownCols = ({
    userId,
    schemaId,
    layout,
  }: {
    userId: string
    schemaId: string
    layout: RundownLayoutType
  }) => keyForUser(userId, `${KEYS.RUNDOWN}:${schemaId}:${layout}-cols`)

  getScriptPrintPreferences = ({
    userId,
  }: {
    userId: string
  }): ScriptPrintPreferences => {
    const key = keyForUser(userId, `${KEYS.SCRIPT_PRINT_PREFS}`)
    return prefSchemas.storedScriptPrintPrefs.parse(this._localStorage.get(key))
  }

  setScriptPrintPreferences = ({
    userId,
    prefs,
  }: {
    userId: string
    prefs: ScriptPrintPreferences
  }) => {
    const key = keyForUser(userId, `${KEYS.SCRIPT_PRINT_PREFS}`)
    this._localStorage.set(key, JSON.stringify(prefs))
  }

  getRundownColumnState = ({
    userId,
    schemaId,
    layout,
  }: {
    userId: string
    schemaId: string
    layout: RundownLayoutType
  }): RundownColumnState => {
    const key = this.keyForRundownCols({ userId, schemaId, layout })
    const stringValue = this._localStorage.get(key)
    return parseSavedBlob<RundownColumnState>(stringValue)
  }

  setRundownColumnState = ({
    userId,
    schemaId,
    layout,
    columnState,
  }: {
    userId: string
    schemaId: string
    layout: RundownLayoutType
    columnState: RundownColumnState
  }) => {
    const key = this.keyForRundownCols({ userId, schemaId, layout })
    this._localStorage.set(key, JSON.stringify(columnState))
  }

  getFavoriteListings = ({
    userId,
    orgId,
  }: {
    userId: string
    orgId: string
  }): string[] => {
    const key = keyForOrgUser({ userId, orgId, scope: KEYS.FAVORITES })
    const rawResult = this._localStorage.get(key)
    if (!rawResult) {
      return []
    }
    return prefSchemas.parseFavoriteUuids(rawResult)
  }

  setFavoriteListings = ({
    userId,
    orgId,
    favorites,
  }: {
    userId: string
    orgId: string
    favorites: string[]
  }) => {
    const key = keyForOrgUser({ userId, orgId, scope: KEYS.FAVORITES })
    this._localStorage.set(key, JSON.stringify(favorites))
  }

  getMyHistory = async ({
    userId,
    orgId,
  }: {
    userId: string
    orgId: string
  }): Promise<MyHistory> => {
    const key = keyForOrgUser({ userId, orgId, scope: KEYS.MY_HISTORY })
    return prefSchemas.parseOpenedItems(this._localStorage.get(key))
  }

  setMyHistory = async ({
    userId,
    orgId,
    myHistory,
  }: {
    userId: string
    orgId: string
    myHistory: MyHistory
  }): Promise<void> => {
    const key = keyForOrgUser({ userId, orgId, scope: KEYS.MY_HISTORY })
    this._localStorage.set(key, JSON.stringify(myHistory))
  }

  getEditorPdfPreferences = (userId: string): EditorPdfPreferences => {
    const key = keyForUser(userId, KEYS.PDF_PREFERENCES)
    const storedVal = this._localStorage.get(key)

    if (storedVal) return parseSavedBlob<EditorPdfPreferences>(storedVal)

    return defaultEditorPdfPreferences
  }

  getRundownClipboard = (userId: string): RundownClipboard | undefined => {
    const key = keyForUser(userId, KEYS.RUNDOWN_CLIPBOARD)
    const storedVal = this._localStorage.get(key)
    if (storedVal) {
      return parseSavedBlob<RundownClipboard>(storedVal)
    }
  }

  setRundownClipboard = (userId: string, data: RundownClipboard) => {
    const key = keyForUser(userId, KEYS.RUNDOWN_CLIPBOARD)
    this._localStorage.set(key, JSON.stringify(data))
  }

  getInkPreferences = (userId: string): InkPreferences | undefined => {
    const key = keyForUser(userId, KEYS.INK_PREFS)
    const storedVal = this._localStorage.get(key)
    if (storedVal) {
      return parseSavedBlob<InkPreferences>(storedVal)
    }
  }

  setInkPreferences = (userId: string, data: InkPreferences) => {
    this._localStorage.set(
      keyForUser(userId, KEYS.INK_PREFS),
      JSON.stringify(data),
    )
  }

  getRundownPrintPrefs = (userId: string): RundownPrintPrefs => {
    const key = keyForUser(userId, KEYS.RD_PRINT_PREFS)
    return prefSchemas.storedRundownPrintPrefs.parse(
      this._localStorage.get(key),
    )
  }

  setRundownPrintPrefs = (userId: string, prefs: RundownPrintPrefs) => {
    this._localStorage.set(
      keyForUser(userId, KEYS.RD_PRINT_PREFS),
      JSON.stringify(prefs),
    )
  }

  isScriptoPreferenceEvent = (event: StorageEvent): boolean => {
    return Object.values<string>(KEYS).some((prefix) =>
      event.key?.startsWith(prefix),
    )
  }
}
