import { BaseModel } from '@state/models/BaseModel'
import { reloadWindow } from '@util'
import { authToken } from '@util/authToken'

type LoggedInStatus = {
  loggedIn: true
  userId: string
}

type LoggedOutStatus = {
  loggedIn: false
}

type AuthStatus = LoggedInStatus | LoggedOutStatus

/*
  This model wraps the calls to login, logout and check auth status to handle
  all the variations of scrapi vs. api and making sure that the in-memory
  singleton auth token gets populated properly
*/
export const AuthManager = BaseModel.named('AuthManager').actions((self) => ({
  _onLoggedIn(token: string) {
    self.environment.localPersistence.markLoggedIn(true)
    authToken.set(token)
  },

  async _getScrapiAuthStatus(): Promise<AuthStatus> {
    const response = await self.scrapi.auth.status()
    if (response.status === 200) {
      if (response.body.loggedIn) {
        const { socketToken, id } = response.body
        this._onLoggedIn(socketToken)
        return { loggedIn: true, userId: id }
      }
      // only clear the token on an explicit failure, we might have gotten
      // another kind of failure
      authToken.clear()
    }
    return { loggedIn: false }
  },

  async _getApiAuthStatus(): Promise<AuthStatus> {
    const response = await self.apiClient.getAuthStatus()
    if (response) {
      this._onLoggedIn(response.socketToken)
      return { loggedIn: true, userId: response.id }
    }
    // apiClient would have thrown, so the null response
    // means api successfuly determined we are not logged in
    authToken.clear()
    return { loggedIn: false }
  },

  getAuthStatus(): Promise<AuthStatus> {
    const { useNidoAlpha } = self.rootStore.view
    return useNidoAlpha ? this._getScrapiAuthStatus() : this._getApiAuthStatus()
  },

  // this throws if login fails. The scrapi signature for login
  // is very different
  async loginWithApi(args: {
    email: string
    password: string
  }): Promise<LoggedInStatus> {
    const response = await self.apiClient.login(args)
    this._onLoggedIn(response.socketToken)
    return {
      loggedIn: true,
      userId: response.id,
    }
  },

  // The scrapi login returns a lot of different error codes without
  // throwing, so just handle the socket token and pass the response through.
  // NOTE: not used in wallaby yet
  async loginWithScrapi(args: { email: string; password: string }) {
    const response = await self.scrapi.auth.login({ body: args })
    if (response.status === 200) {
      if (response.body.loggedIn) {
        this._onLoggedIn(response.body.socketToken)
      }
    }
    return response
  },

  async logout() {
    if (self.rootStore.view.useNidoAlpha) {
      const response = await self.scrapi.auth.logout({ body: {} })
      // make scrapi throw if this fails since we haven't successfully
      // logged out
      if (response.status !== 200) {
        throw new Error('Unknown server error', { cause: 200 })
      }
    } else {
      await self.apiClient.logout()
    }

    authToken.clear()
    self.environment.localPersistence.markLoggedIn(false)
    self.trackEvent(self.analytics.EVENTS.LOGOUT)
    await reloadWindow('/login')
  },
}))
