import { autorun } from 'mobx'
import { detach, flow, Instance, types } from 'mobx-state-tree'
import pAll from 'p-all'
import { Node as PmNode } from 'prosemirror-model'

import { utils } from '@showrunner/scrapi'

import {
  createPopupWindowProxy,
  createRemotePlayer,
  exposeControllerToPlayer,
  getRemoteCompiler,
  InkCompileMessage,
  RemotePlayer,
} from '@ink'
import { ILoadedScript, ScriptReference } from '@state/types'

import { BaseModel } from '../BaseModel'

import { InkSourceFile } from './InkSourceFile'

type CompileStatus = 'working' | 'ok' | 'error'

interface IInkSourceFile extends Instance<typeof InkSourceFile> {}

export const InkProject = BaseModel.named('InkProject')
  .props({
    sourceFileVersion: 0,
    compilationVersion: 0,
    jsonVersion: 0,
    isCompiling: false,
    compilerMessages: types.frozen<InkCompileMessage[]>([]),
    compiledJson: types.maybe(types.string),
    sourceFileMap: types.map(InkSourceFile),
  })
  .volatile<{
    lastProcessedProseDoc?: PmNode
    remotePlayer?: RemotePlayer
    popupWindowProxy: WindowProxy | null
  }>(() => ({
    popupWindowProxy: null,
  }))
  .views((self) => ({
    get inkText() {
      return self.lastProcessedProseDoc
        ? utils.ink.blockLinesToInkText(
            utils.ink.proseToBlockLines(self.lastProcessedProseDoc.toJSON()),
          )
        : ''
    },
    get compilationIsCurrent() {
      const { sourceFileVersion, compilationVersion } = self
      return compilationVersion === sourceFileVersion
    },
    get jsonIsCurrent() {
      const { jsonVersion, compilationVersion } = self
      return jsonVersion === compilationVersion
    },
    get status(): CompileStatus {
      if (!this.compilationIsCurrent) {
        return 'working'
      }
      return this.jsonIsCurrent ? 'ok' : 'error'
    },
    get mainScript(): ILoadedScript | undefined {
      const { currentScript } = self.rootStore
      return currentScript?.isInk ? currentScript : undefined
    },
    compilerMessagesForScript(scriptId: string) {
      return self.compilerMessages.filter((m) => m.file === scriptId)
    },
    get sourceFiles() {
      return Array.from(self.sourceFileMap.values())
    },
    async getExportableSources(): Promise<{
      zipFileName: string
      files: Array<{
        fileName: string
        contents: string
      }>
    }> {
      const { main, includes } = await getRemoteCompiler().compilableSources
      const files: Array<{
        fileName: string
        contents: string
      }> = [{ fileName: 'Main.ink', contents: main }]
      Object.entries(includes).forEach(([fileName, contents]) =>
        files.push({ fileName, contents }),
      )

      const name = self.rootStore.currentRundown?.name ?? 'ScriptoInkProject'
      return {
        zipFileName: `${name}.zip`,
        files,
      }
    },
    get isLoadingSources(): boolean {
      return !!this.sourceFiles.find((s) => s.isLoading)
    },
    /*
      when to trigger a compilation depends on a combination of a lot of
      observables. This getter combines all of them, then an autorun
      watches this observable and compiles when appropriate
    */
    get shouldRecompileNow(): boolean {
      // If the URL implies a manifest, wait for it to load
      const rundown = self.rootStore.currentRundown
      const rundownId = self.rootStore.location.getPathParam('rundownId')
      const awaitingRundownLoad = rundownId && !rundown
      return !!(
        !awaitingRundownLoad &&
        // only compile if there's an ink script loaded in the editor
        this.mainScript &&
        self.lastProcessedProseDoc &&
        // don't compile if we're still compiling
        !self.isCompiling &&
        // don't compile if we have already compiled the latest sources
        !this.compilationIsCurrent &&
        // don't compile if we're fetching non-loaded sources
        !this.isLoadingSources
      )
    },
    useRundownScripts(): boolean {
      const rundown = self.rootStore.currentRundown
      const activeScriptId = self.rootStore.location.getPathParam('scriptId')
      const activeScriptInRundown =
        rundown &&
        !!rundown?.orderedScripts.find((s) => s.scriptId === activeScriptId)

      return !!activeScriptInRundown || !!(rundown && !activeScriptId)
    },
    // To avoid spamming the API server with hundreds of concurrent GET script requests,
    // this limits the fetching to a few scripts at a time.
    async fetchUnloadedSources() {
      const sourcesToLoad = Array.from(self.sourceFileMap.values()).filter(
        (source) =>
          !(
            source.isLoading ||
            source.hasLoadedOnce ||
            source.excludeFromCompilation
          ),
      )
      const loadFunctions = sourcesToLoad.map((s) => () => s.loadFromServer())
      await pAll(loadFunctions, { concurrency: 5 })
    },
  }))
  .actions((self) => ({
    updateIncludedSources: flow(function* updateIncludedSources(
      references: ScriptReference[],
    ) {
      const sourcesToRemove: IInkSourceFile[] = Array.from(
        self.sourceFileMap.values(),
      ).filter(
        ({ scriptId }) => !references.find((r) => r.scriptId === scriptId),
      )

      // pull all the sources from the remote compiler
      // TODO: maybe chunk these into batches of 5 at a time?
      yield Promise.all(
        sourcesToRemove.map(({ scriptId }) =>
          getRemoteCompiler().removeSource(scriptId),
        ),
      )

      // add any new references to the map.
      references.forEach(({ scriptId, name }) => {
        if (!self.sourceFileMap.has(scriptId)) {
          self.sourceFileMap.put({ scriptId, name })
        }
      })

      yield self.fetchUnloadedSources()
      self.sourceFileVersion = self.sourceFileVersion + 1
    }),
    updateRemotePlayer() {
      self.remotePlayer?.updateData({
        inkJson: self.compiledJson,
        title: self.mainScript?.name,
        compileStatus: self.status,
      })
    },
    removeRemotePlayer() {
      self.popupWindowProxy?.close()
      self.remotePlayer = undefined
      self.popupWindowProxy = null
    },
    launchPopup() {
      // create a new window if it doesn't exist or has been closed
      if (!self.popupWindowProxy || self.popupWindowProxy.closed) {
        self.popupWindowProxy = createPopupWindowProxy()

        if (self.popupWindowProxy) {
          // expose the player to the controller (the controller is this mst store)
          self.remotePlayer = createRemotePlayer(self.popupWindowProxy)

          exposeControllerToPlayer({
            proxy: self.popupWindowProxy,
            onPlayerReady: this.updateRemotePlayer,
            onPlayerClosed: this.removeRemotePlayer,
          })
        }
      } else {
        self.popupWindowProxy.focus()
      }
    },
    compile: flow(function* compile() {
      self.isCompiling = true
      const nextCompileVersion = self.sourceFileVersion
      const { inkJson, messages } = yield getRemoteCompiler().compile()

      self.compilationVersion = nextCompileVersion
      self.compilerMessages = messages

      if (inkJson) {
        self.compiledJson = inkJson
        self.jsonVersion = nextCompileVersion
        self.remotePlayer?.updateData({ inkJson })
      }
      self.isCompiling = false
    }),
    setLoadedDoc(doc: PmNode) {
      self.lastProcessedProseDoc = doc
      self.sourceFileVersion = self.sourceFileVersion + 1
    },
    async updateMain({
      scriptId,
      name,
      doc,
    }: {
      scriptId: string
      name: string
      doc: PmNode
    }) {
      this.setLoadedDoc(doc)
      const source =
        self.sourceFileMap.get(scriptId) ??
        self.sourceFileMap.put({ scriptId, name })
      source.updateFromLoadedScript({ name })
      const blockLines = utils.ink.proseToBlockLines(doc.toJSON())
      await getRemoteCompiler().updateSource({
        scriptId,
        name,
        blockLines,
      })
    },
  }))
  .actions((self) => ({
    afterAttach() {
      autorun(() => {
        const { mainScript } = self
        const editorState = mainScript?.observableEditor?.editorState
        if (
          editorState &&
          mainScript &&
          !self.lastProcessedProseDoc?.eq(editorState.doc)
        ) {
          self.updateMain({
            scriptId: mainScript.id,
            name: mainScript.name,
            doc: editorState.doc,
          })
        }
      })

      // keep an eye on the currentScript and currentRundown and update
      // the sources accordingly
      autorun(() => {
        const rundown = self.rootStore.currentRundown
        const newSources: ScriptReference[] =
          rundown && self.useRundownScripts()
            ? rundown.orderedScripts
            : self.mainScript
              ? [
                  {
                    scriptId: self.mainScript.id,
                    name: self.mainScript.name,
                  },
                ]
              : []

        self.updateIncludedSources(newSources)
      })

      // trigger a recompilation when we're not compiling if
      // there is something new to compile
      autorun(() => {
        if (self.shouldRecompileNow) {
          self.compile()
        }
      })

      autorun(() => {
        self.remotePlayer?.updateData({ compileStatus: self.status })
      })

      // close the player and destroy this instance if there are no sources
      autorun(() => {
        if (self.sourceFiles.length === 0 && self.remotePlayer) {
          self.removeRemotePlayer()
          detach(self)
        }
      })
      // close the player if the main app window is closed
      window.addEventListener('beforeunload', self.removeRemotePlayer)
    },
  }))
