import React from 'react'

import { Group } from '@mantine/core'
import { observer } from 'mobx-react-lite'

import { useMst } from '@hooks'
import { SideBySide, SideBySideProps } from '@layouts/SideBySide'
import { DEFAULT_SIDEBAR_WIDTH } from '@util/constants'

import { AsteriskView, SideBySideView, StaticView } from './bodyViews'
import { SnapshotList } from './SnapshotList'

const limits: SideBySideProps['limits'] = {
  firstChild: {
    minWidth: 400,
    minHeight: Infinity,
  },
  secondChild: {
    minWidth: 250,
    minHeight: Infinity,
  },
}

export const HistoryAndBody = observer(function HistoryAndBody({
  scriptId,
  width,
}: {
  scriptId: string
  width: number
}) {
  const mst = useMst()
  const { currentView } = mst.view.snapshotLand
  const historyWidth =
    mst.user.prefs.snapshotHistoryWidth ?? DEFAULT_SIDEBAR_WIDTH
  const bodyWidth = width - historyWidth
  const handleResize = (bodySize: { width?: number }) => {
    if (typeof bodySize.width === 'number') {
      mst.user.updatePreferences({
        snapshotHistoryWidth: width - bodySize.width,
      })
    }
  }

  return (
    <SideBySide
      layout="columns"
      limits={limits}
      preferredWidth={bodyWidth}
      onResizeEnd={handleResize}
    >
      <Group bg="gray.2" h="100%" align="flex-start" justify="center">
        {currentView === 'static' && <StaticView scriptId={scriptId} />}
        {currentView === 'diff' && <SideBySideView scriptId={scriptId} />}
        {currentView === 'asterisk' && <AsteriskView scriptId={scriptId} />}
      </Group>

      <SnapshotList scriptId={scriptId} />
    </SideBySide>
  )
})
