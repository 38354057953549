import { useQuery } from '@tanstack/react-query'

import { useMst } from '@state'

export const useSnapshotData = ({
  snapshotId,
  scriptId,
}: {
  snapshotId: string
  scriptId: string
}) => {
  const { apiClient } = useMst()
  return useQuery({
    queryFn: () => apiClient.getSnapshot({ scriptId, snapshotId }),
    queryKey: ['snapshot', { scriptId, snapshotId }],
    staleTime: Infinity,
  })
}

export const useScriptData = (scriptId: string) => {
  const { apiClient } = useMst()
  return useQuery({
    queryFn: async () => {
      const script = await apiClient.getScript(scriptId)
      const fetchedAt = new Date()
      return {
        script,
        fetchedAt,
      }
    },
    queryKey: ['script-for-snapshot-views', scriptId],
    // this combination of options means that as long as this is mounted, the query
    // won't get stale, but the result doesn't get cached either. That means that
    // when you enter the snapshot view for a particular snapshot, we get the script and
    // don't get it again if you go to side-by-side vs compare, etc. But when you leave
    // and re-enter, we don't cache the script so it will be refetched the next time you enter
    // this view
    staleTime: Infinity,
    gcTime: 0,
  })
}
