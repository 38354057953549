export { pageBreakerKey } from './constants.js'
export { getRepaginateFunction } from './repaginate'

import { Plugin } from 'prosemirror-state'

import { getConfigData } from '../configData'
import { PluginFactory } from '../types'

import { pageBreakerKey } from './constants.js'
import { getRepaginateFunction } from './repaginate'

export const pageBreakerPlugin: PluginFactory = () => {
  return new Plugin({
    key: pageBreakerKey,
    /**
     * Breaks a document into dynamic pages on each transaction, as needed.
     */
    appendTransaction(transactions, oldState, newState) {
      const { currentBlockFormats, paginationType } = getConfigData(newState)
      if (paginationType !== 'structural') {
        return
      }
      const repaginator = getRepaginateFunction(currentBlockFormats)
      const last = transactions[transactions.length - 1]
      // exit early under the following conditions:
      // last transaction is from page breaker plugin
      // last transaction is from collab plugin
      // doc is unchanged
      if (
        last.getMeta(pageBreakerKey) ||
        last.getMeta('collab$') ||
        oldState.doc.eq(newState.doc)
      ) {
        return
      }

      return repaginator(newState, oldState)
    },
  })
}
