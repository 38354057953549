import { EditorState } from 'prosemirror-state'

import { NodeTypeKey, ScriptDocType, types } from '@showrunner/codex'

import { getSelectionBlockType, nextNodeOnTab, setEditorBlockType } from '@util'

import { getLastBlockType } from '../../prose-utils.js'

function getTabHandler(docType: ScriptDocType) {
  /**
   * Generalized tab handler. Handles Tab & Shift+Tab in various contexts.
   * @param {object} viewState - PM view state
   * @param {function} [viewDispatch] - PM event dispatcher
   * @param {boolean} reverse - reverse direction (shift)
   * @returns {boolean} required thing for PM input rules (always true)
   */
  return function handleTab(
    viewState: EditorState,
    viewDispatch: Dispatch,
    reverse = false,
  ) {
    const { $from } = viewState.selection
    const dual = $from.depth > 2
    // skip it if we're in the first node of a dual dialogue column
    // because it can only be character
    if (dual && $from.index(3) === 0) {
      return true
    }
    const blockType = getSelectionBlockType(viewState)
    // skip tabbing if this is a new act or end of act
    if (
      !blockType ||
      blockType === types.NEW_ACT ||
      blockType === types.END_OF_ACT
    ) {
      return true
    }
    const lastBlockType = getLastBlockType(viewState)
    const nextBlockType = nextNodeOnTab(
      blockType as NodeTypeKey,
      lastBlockType,
      docType,
      {
        reverse,
        dual,
      },
    )
    // dont cycle block type via tab when the selection spans several
    if (nextBlockType) {
      const { $from, $to } = viewState.selection
      if ($from.parent.eq($to.parent)) {
        setEditorBlockType(viewState, viewDispatch, nextBlockType)
      }
    }
    return true
  }
}
export { getTabHandler }
