import { EditorState, Selection, TextSelection } from 'prosemirror-state'
import { canSplit } from 'prosemirror-transform'

import { NodeTypeMap, schema } from '@showrunner/codex'

const fromIsStartOfNonEmptyBlock = (selection: Selection): boolean => {
  const { $from } = selection
  if ($from.parent.content.size === 0) return false // block is empty
  return $from.parentOffset === 0 // touches start
}

/*
  preserve the existing block's id, element number, untiming and other attrs
  if the existing block triggers a manual pageBreak, strip that attr and copy it
  over to the newly created preceding block when appropriate
*/
export const maybeInsertPrecedingBlock = (
  state: EditorState,
  dispatch?: Dispatch,
) => {
  const { tr } = state
  const { selection } = tr
  if (!fromIsStartOfNonEmptyBlock(selection)) return false
  const { $to, $from } = selection

  if (selection instanceof TextSelection) tr.deleteSelection()
  if (!canSplit(tr.doc, tr.mapping.map($from.pos), 1)) return false

  const { type, attrs: originalAttrs } = $to.parent

  const typesAfter = [{ type, attrs: { ...originalAttrs, pageBreak: null } }]
  const attrsBefore = { pageBreak: originalAttrs.pageBreak ?? null }

  tr.split(tr.mapping.map($from.pos), 1, typesAfter)
  tr.setNodeMarkup(
    tr.mapping.map($from.before()),
    schema.nodes[NodeTypeMap.DIALOGUE],
    attrsBefore,
  )
  dispatch?.(tr)
  return true
}
