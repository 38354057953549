import React from 'react'

import {
  infiniteQueryOptions,
  useInfiniteQuery,
  useQueryClient,
} from '@tanstack/react-query'

import { useMst } from '@state'
import { SnapshotSummary } from '@util/ScriptoApiClient/types'

/*
  Kinda fancy... this uses useInfiniteQuery to get pages of results and
  keep them in the query cache. The issue happens, however, if someone adds a new snapshot
  then we can get a snapshot on a later page that's already in the cache. This hook
  is smart and when it gets a next page, it clears any old version of that from the cache
  so we wind up with the latest version (it's possible a snapshot got renamed so we'll use
  the newer data, just keep the ID only)
*/
export const useSnapshotHistory = (scriptId: string) => {
  const { apiClient, doDebug, user } = useMst()
  const queryClient = useQueryClient()
  const filter = user.prefs.snapshotFilter ?? 'all'
  const [total, setTotal] = React.useState(0)

  // the queryFn references the queryOptions defined below it for
  // reactQuery to be able to do some type inference.
  // see https://tanstack.com/query/latest/docs/framework/react/typescript#typing-query-options
  const queryFn = async ({ pageParam }: { pageParam: number }) => {
    await doDebug()
    const response = await apiClient.fetchSnapshotHistory({
      scriptId,
      from: pageParam,
      size: 50,
      filter,
    })
    setTotal(response.total)

    // we may have records returned that are already in the cache due to overlapping
    // cursors-- the ID is immutable, the new value is better. so, just nuke the old value
    const cachedData = queryClient.getQueryData(queryOptions.queryKey)
    if (cachedData) {
      const newIds = new Set(response.results.map((snap) => snap.id))
      const newPages = cachedData.pages.map((page) => ({
        ...page,
        results: page.results.filter((p) => !newIds.has(p.id)),
      }))
      queryClient.setQueryData(queryOptions.queryKey, {
        ...cachedData,
        pages: newPages,
      })
    }

    return response
  }

  const queryOptions = infiniteQueryOptions({
    queryKey: ['infinite-snaphots', scriptId, filter],
    queryFn,
    initialPageParam: 0,
    getNextPageParam: (lastPage) => {
      const nextFrom = lastPage.range[1] + 1
      // don't return a value if there are no more to find.
      // useInfiniteQuery interprets the undefined as meaning
      // there are no more to load
      if (nextFrom < lastPage.total) {
        return nextFrom
      }
    },
    // when the component is unmounted, we throw away all the results
    gcTime: 0,
    // we'll deal with refreshing ourselves
    staleTime: Infinity,
  })

  const query = useInfiniteQuery(queryOptions)

  const data: SnapshotSummary[] = React.useMemo(
    () => query.data?.pages.flatMap((page) => page.results) ?? [],
    [query.data],
  )

  const resetFromFirstPage = () => {
    queryClient.resetQueries({
      queryKey: ['infinite-snaphots', scriptId, filter],
    })
  }

  const {
    isPending,
    isFetching,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    error,
  } = query

  const loadMore = () => {
    if (!isFetching) {
      fetchNextPage()
    }
  }

  return {
    total,
    isFetchingFirstPage: isPending,
    isFetchingNextPage,
    hasMore: hasNextPage,
    loadMore,
    data,
    resetFromFirstPage,
    error,
  }
}
