import { Menu } from '@mantine/core'

import { schema, util } from '@showrunner/codex'

import { TimingPopover } from '@components/EditorToolbar/TimingPopover'
import { FancyMenuItem } from '@components/FancyMenuItem'
import { Toolbar } from '@components/Toolbar'
import { TabItem } from '@components/Toolbar/ToolbarTabs'
import { Keys, useNavigation, useShortcuts } from '@hooks'
import { useMst } from '@state'
import { SOME_DIFF_CONTEXT_LINES } from '@util/printing'

import { useSnapshotData } from '../useSnapshotQueries'

import { useToolbarBreakpoints } from './useToolbarBreakpoints'

type SnapshotTab = 'snapshot' | 'compare' | 'diff'
export const SnapshotToolbar = ({
  scriptId,
  snapshotId,
  activeTab,
  onPrint,
  onDownloadPdf,
  onDownloadFdx,
  viewMenuItems,
  slenePicker,
}: {
  scriptId: string
  snapshotId: string
  activeTab: SnapshotTab
  onPrint?: () => void
  onDownloadPdf?: () => void
  onDownloadFdx?: () => void
  viewMenuItems?: React.ReactNode
  slenePicker?: React.ReactNode
}) => {
  const { navigate } = useNavigation()
  const { data } = useSnapshotData({ snapshotId, scriptId })
  const mst = useMst()
  const handleGoBack = () => {
    navigate(mst.view.explorerState.lastScriptPath ?? `/scripts/${scriptId}`)
  }

  const { buttonMode, tabMode } = useToolbarBreakpoints()

  const setSelectedTab = (tab: string | null) => {
    if (tab !== activeTab) {
      if (tab === 'snapshot') {
        navigate(`/scripts/${scriptId}/snapshots/${snapshotId}`)
      } else if (tab === 'compare') {
        navigate(`/scripts/${scriptId}/compare/${snapshotId}`)
      } else if (tab === 'diff') {
        navigate(
          `/scripts/${scriptId}/diff/${snapshotId}?context=${SOME_DIFF_CONTEXT_LINES}`,
        )
      }
    }
  }

  const { menuProps, getItemProps } = useShortcuts({
    print: {
      keys: [Keys.CMD, 'P'],
      action: () => onPrint?.(),
    },
    export: {
      keys: [Keys.CMD, Keys.SHIFT, 'E'],
      action: () => onDownloadPdf?.(),
    },
  })

  const items: TabItem[] = [
    { id: 'snapshot', icon: 'fa-camera', label: 'Snapshot' },
    { id: 'diff', icon: 'fa-columns', label: 'Side-by-Side' },
  ]

  // we suppress asterisks view for ink scripts
  if (data?.scriptFormat.definition.scriptType !== 'ink') {
    items.splice(1, 0, {
      id: 'compare',
      icon: 'fa-asterisk',
      label: 'Asterisk',
    })
  }

  const isSnapshotTab = activeTab === 'snapshot'
  const isScreenplay = data?.doc.attrs.docType === 'screenplay'
  const readRate = mst.currentOrg?.readRate

  const timing =
    data && !isScreenplay && isSnapshotTab && readRate
      ? new util.ScriptBreakdown(schema.nodeFromJSON(data.doc), readRate).timing
      : undefined

  return (
    <Toolbar>
      <Toolbar.Section position="left">
        <Toolbar.Button
          icon="fa-arrow-left"
          label="Back to Editor"
          onClick={handleGoBack}
        />
        <Toolbar.Divider />
        <Toolbar.Tabs
          selectedTab={activeTab}
          onSelectTab={setSelectedTab}
          items={items}
          mode={tabMode}
        />
      </Toolbar.Section>
      <Toolbar.Section position="right">
        {slenePicker}
        {viewMenuItems && (
          <Menu position="bottom-end">
            <Menu.Target>
              <div>
                <Toolbar.Button
                  mode={buttonMode}
                  icon="fa-sliders"
                  label="Settings"
                  dropdown
                />
              </div>
            </Menu.Target>
            <Menu.Dropdown>{viewMenuItems}</Menu.Dropdown>
          </Menu>
        )}
        {viewMenuItems && <Toolbar.Divider />}
        {timing && (
          <TimingPopover
            docTiming={timing}
            mode={buttonMode}
            height={mst.view.dimensions.scriptScroller.height}
          />
        )}
        <Toolbar.CopyUrlButton label="Copy URL" mode={buttonMode} />
        <Menu {...menuProps}>
          <Menu.Target>
            <div>
              <Toolbar.Button
                mode={buttonMode}
                label="Export"
                icon="fa-share-from-square"
                dropdown
              />
            </div>
          </Menu.Target>
          <Menu.Dropdown>
            {onPrint && (
              <FancyMenuItem title="Print..." {...getItemProps('print')} />
            )}
            {onDownloadPdf && (
              <FancyMenuItem title="Export PDF" {...getItemProps('export')} />
            )}
            {onDownloadFdx && (
              <FancyMenuItem
                title="Export to Final Draft"
                onClick={onDownloadFdx}
              />
            )}
          </Menu.Dropdown>
        </Menu>
      </Toolbar.Section>
    </Toolbar>
  )
}
