import { flow, types } from 'mobx-state-tree'

import { utils } from '@showrunner/scrapi'

import { getRemoteCompiler } from '@ink'
import { ScriptPayload } from '@util/ScriptoApiClient/types'

import { BaseModel } from '../BaseModel'

export const InkSourceFile = BaseModel.named('InkSourceFile')
  .props({
    scriptId: types.identifier,
    name: types.string,
    isLoading: false,
    loadError: types.maybe(types.string),
    excludeFromCompilation: false,
    hasLoadedOnce: false,
  })
  .actions((self) => ({
    updateFromLoadedScript({ name }: { name: string }) {
      self.excludeFromCompilation = false
      self.name = name
      self.isLoading = false
      self.loadError = undefined
    },

    loadFromServer: flow(function* load() {
      self.isLoading = true
      self.loadError = undefined
      try {
        const { doc, scriptFormat }: ScriptPayload =
          yield self.apiClient.getScript(self.scriptId)
        const isInk = scriptFormat.definition.scriptType === 'ink'
        const blockLines = isInk ? utils.ink.proseToBlockLines(doc) : []
        yield getRemoteCompiler().updateSource({
          scriptId: self.scriptId,
          name: self.name,
          isInk,
          blockLines,
        })
        self.excludeFromCompilation = !isInk
        self.hasLoadedOnce = true
      } catch (e) {
        self.loadError = `Failed to load ${self.scriptId}`
        yield getRemoteCompiler().updateSource({
          scriptId: self.scriptId,
          name: self.name,
          isInk: true,
          loadError: self.loadError,
          blockLines: [],
        })
      } finally {
        self.isLoading = false
      }
    }),
  }))
