import { ResolvedPos } from 'prosemirror-model'
import { TextSelection } from 'prosemirror-state'
import { canSplit, joinPoint } from 'prosemirror-transform'

import { NodeTypeMap } from '@showrunner/codex'

import { pageBreakerKey } from '@choo-app/lib/editor/plugins/page-breaker'
import { Command } from '@util/prosemirrorHelpers'

/**
 * find cut position before node at given position
 * copied from https://github.com/ProseMirror/prosemirror-commands/blob/51a2f46ae40acb771c179b005836d3532fab99b3/src/commands.js#L90
 */
function findCutBefore($pos: ResolvedPos) {
  if (!$pos.parent.type.spec.isolating) {
    for (let i = $pos.depth - 1; i >= 0; i--) {
      if ($pos.index(i) > 0) {
        return $pos.doc.resolve($pos.before(i + 1))
      }
      if ($pos.node(i).type.spec.isolating) {
        break
      }
    }
  }
  return null
}

const create: Command = (editorState, dispatch) => {
  const { selection, tr } = editorState
  const { $from } = selection

  // move the selection to the start of the level 2 block. Basically
  // we always want to create a block ABOVE the block we're on
  const breakPos = $from.start(2)
  const newSelection = TextSelection.create(tr.doc, breakPos)
  tr.setSelection(newSelection)

  const { pos, depth } = newSelection.$from

  const typesAfter = [
    {
      type: editorState.schema.nodes[NodeTypeMap.PAGE],
      attrs: { dynamic: false },
    },
    // preserve the current block type and attributes
    {
      type: $from.parent.type,
      attrs: $from.parent.attrs,
    },
  ]

  // we split the block leaving an orphaned empty block above. We want
  // to get rid of this
  let priorPos
  const $prior = tr.doc.resolve(breakPos - 2)
  if ($prior.depth === depth && $from.node(1).eq($prior.node(1))) {
    priorPos = $prior.pos
  }

  // FIXME: canSplit is returning a false negative when we include typesAfter.
  // Need to find root cause then file a bug report.
  // Caution: canSplit and split PM methods are very difficult to debug.
  // Nate's investigation seems to indicate that canSplit and split are not
  // treating the typesAfter argument the same way.
  if (!canSplit(tr.doc, pos, depth)) {
    // Seems typesAfter array is treated differently in canSplit vs split.
    // eslint-disable-next-line no-console
    console.error(
      `insertPageBreak failed: cannot split at ${pos} (depth: ${depth})`,
    )
    return false
  }
  let nextTr = tr.split(pos, depth, typesAfter)

  // in transactions with multiple steps we generally wrap
  // positions in tr.mapping.map() to ensure that they still
  // refer to the location in the document we expect.
  // we skip that intentionally here because the block we want
  // to delete didn't exist until *after* tr.split() was called
  if (priorPos) {
    nextTr = nextTr.replace(priorPos, pos)
  }

  nextTr = nextTr.scrollIntoView()

  // Check whether doc conforms to schema, and raise error when they do not.
  // TODO: Catch this and do something with it? Haven't raised an error yet.
  nextTr.doc.check()
  // return true for keydown handler if dispatch is present
  if (dispatch) {
    dispatch(nextTr)
  }
  return true
}

const remove: Command = (editorState, dispatch) => {
  // skip if there's an active selection
  if (
    !editorState.selection.empty ||
    !(editorState.selection instanceof TextSelection)
  ) {
    return false
  }
  const { $cursor } = editorState.selection

  // skip if we're not at the start of a text block
  if (!$cursor || $cursor.parentOffset > 0) {
    return false
  }
  const $cut = findCutBefore($cursor)
  const nodeBefore = $cut && $cut.nodeBefore
  const joinPt = $cut ? joinPoint(editorState.tr.doc, $cut.pos) : null

  // skip if node before is not a page,
  // or current node is not a dynamic page
  const skip =
    !joinPt ||
    !nodeBefore ||
    nodeBefore.type.name !== NodeTypeMap.PAGE ||
    !$cursor.node(1).attrs.dynamic

  if (skip) {
    return false
  }

  dispatch?.(
    editorState.tr
      .join(joinPt)
      .setMeta(pageBreakerKey, 'pageJoin')
      .scrollIntoView(),
  )
  return false
}

export const structuralPageBreak = {
  create,
  remove,
}
