import { Node as PmNode } from 'prosemirror-model'
import { Plugin, PluginKey } from 'prosemirror-state'
import { Decoration, DecorationSet } from 'prosemirror-view'

import { IRoot } from '@state'

import { findSyntaxMatches } from './helpers'
import { inkSyntaxRules } from './ink-syntax'

export const SYNTAX_HIGHLIGHTING = 'syntaxHighlighting'
export const syntaxHighlightPluginKey = new PluginKey(SYNTAX_HIGHLIGHTING)

type PluginState = {
  enabled: boolean
  decorationSet: DecorationSet
}

// This plugin is used in snapshots so we have a simplified Factory
// signature, it only requires { mst } (to check the user prefs)
export const syntaxHighlightingFactory = ({ mst }: { mst: IRoot }) => {
  const createDecorationSet = (doc: PmNode): DecorationSet => {
    const decorations: Decoration[] = []
    doc.descendants((node, pos) => {
      if (node.isTextblock) {
        inkSyntaxRules.forEach((rule) => {
          const syntaxMatches = findSyntaxMatches(node.textContent, rule)
          syntaxMatches.forEach(({ className, startIndex, length }) => {
            const startPos = pos + startIndex + 1
            decorations.push(
              Decoration.inline(startPos, startPos + length, {
                class: className,
              }),
            )
          })
        })

        // don't traverse inside
        return false
      }
    })

    return DecorationSet.create(doc, decorations)
  }

  // We only want to traverse the doc if the user has syntaxHighlighting
  // enabled, if not we short-circuit and use DecorationSet.empty.
  //
  // We keep track of the enabled state so we can tell if the decorations
  // are stale when the user toggles the preference.
  const getPluginState = (doc: PmNode): PluginState => {
    const enabled = !!mst.user.inkPreferences?.syntaxHighlighting
    const decorationSet = enabled
      ? createDecorationSet(doc)
      : DecorationSet.empty

    return {
      enabled,
      decorationSet,
    }
  }

  return new Plugin<PluginState>({
    key: syntaxHighlightPluginKey,
    state: {
      init(config, editorState) {
        return getPluginState(editorState.doc)
      },

      apply(tr, pluginState) {
        const wasEnabled = pluginState.enabled
        const isNowEnabled = !!mst.user.inkPreferences?.syntaxHighlighting

        if (tr.docChanged || wasEnabled !== isNowEnabled) {
          return getPluginState(tr.doc)
        }

        return pluginState
      },
    },
    props: {
      decorations(state) {
        const { decorationSet } = this.getState(state)
        return decorationSet
      },
    },
  })
}
