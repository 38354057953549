import { DirectEditorProps, EditorView } from 'prosemirror-view'

import { ScriptFormatConfiguration } from '@util/formats'

import {
  getTextClipboardSerializer,
  ScriptFormatClipboardSerializer,
} from './serializers'

export const createEditorView = ({
  element,
  editorProps,
  format,
}: {
  element: HTMLElement
  editorProps: Omit<
    DirectEditorProps,
    'clipboardSerializer' | 'clipboardTextSerializer'
  >
  format: ScriptFormatConfiguration
}) => {
  const clipboardSerializer = new ScriptFormatClipboardSerializer(format)
  const clipboardTextSerializer = getTextClipboardSerializer(format)

  return new EditorView(element, {
    ...editorProps,
    clipboardSerializer,
    clipboardTextSerializer,
  })
}
