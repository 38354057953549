import React from 'react'

import { ActionIcon, Card, Group, Stack, Text } from '@mantine/core'
import cn from 'classnames'

import { FaIcon } from '@components/FaIcon'
import { SnapshotSummary } from '@util/ScriptoApiClient/types'

import * as helpers from './helpers'

import styles from './SnapshotCard.module.scss'

export type SelectionMode = 'solo' | 'comparison'

type SnapshotCardProps = {
  data?: SnapshotSummary
  sticky?: boolean
  selected?: boolean
  onClick?: () => void
  onClose?: () => void
  icon?: React.ReactNode
  enableClick?: boolean
  loading?: boolean
  error?: boolean
}

export const SnapshotCard = (props: SnapshotCardProps) => {
  const { data, onClick, onClose, selected, sticky, icon, enableClick } = props
  const classes = cn(styles.snapshotCard, {
    [styles.clickable]: enableClick && !selected,
    [styles.circled]: selected,
    [styles.active]: selected,
    [styles.sticky]: sticky,
  })

  const manual = !!data?.name
  const title = helpers.cardTitle({ data })
  const subtitle = data ? helpers.subtitle(data) : ' '

  return (
    <Card
      padding="sm"
      className={classes}
      onClick={enableClick ? onClick : undefined}
    >
      <Group gap={0} justify="space-between" wrap="nowrap">
        <Stack gap={2}>
          <Group gap={6} wrap="nowrap">
            {icon}
            <Text
              style={{ wordBreak: 'break-all' }}
              lineClamp={1}
              fw={manual ? 'bold' : undefined}
            >
              {title}
            </Text>
          </Group>
          <Text style={{ wordBreak: 'break-all' }} lineClamp={1} c="dimmed">
            {subtitle}
          </Text>
        </Stack>
        {!!onClose && (
          <ActionIcon onClick={onClose}>
            <FaIcon c="dark.9" icon="fa-xmark" size="14" />
          </ActionIcon>
        )}
      </Group>
    </Card>
  )
}

export const ChooseSnapshotCard = ({ compare }: { compare?: boolean }) => (
  <Card padding="sm" className={styles.chooseSnapshotCard}>
    <Text lineClamp={1}>
      {compare ? 'Choose a snapshot to compare' : 'Choose a snapshot'}
    </Text>
  </Card>
)
