import { EditorState } from 'prosemirror-state'
import { EditorView } from 'prosemirror-view'

import { ScriptFormatConfiguration } from '@util/formats'

export type PaginationType = Exclude<
  ScriptFormatConfiguration['paginationType'],
  undefined
>

// TODO: this needs to get exported from codex so we don't need to redefine
const PAGINATION_TYPE_VALUES: PaginationType[] = [
  'inline',
  'none',
  'structural',
] as const
export function isPaginationType(value: unknown): value is PaginationType {
  return (
    typeof value === 'string' &&
    PAGINATION_TYPE_VALUES.some((item) => item === value)
  )
}

// this type is exported in newer versions of prosemirror libs
export type Command = (
  state: EditorState,
  dispatch?: Dispatch,
  view?: EditorView,
) => boolean

// this type is exported in newer versions of prosemirror libs
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type Attrs = { readonly [key: string]: any }
