import { MarkType } from 'prosemirror-model'
import { EditorState, TextSelection, Transaction } from 'prosemirror-state'
import { EditorView } from 'prosemirror-view'

import { MarkTypeKey, MarkTypeMap, schema } from '@showrunner/codex'

export const getSelectionColors = (state: EditorState): [string, string] => {
  const colors: string[] = []
  const bgColors: string[] = []

  state.doc.nodesBetween(state.selection.from, state.selection.to, (node) => {
    node.marks.forEach((mark) => {
      if (mark.type.name === MarkTypeMap.COLOR) {
        colors.push(mark.attrs.color)
      }
      if (mark.type.name === MarkTypeMap.BACKGROUND_COLOR) {
        bgColors.push(mark.attrs.color)
      }
    })
  })
  return [
    colors.length > 0 ? colors[0] : '',
    bgColors.length > 0 ? bgColors[0] : '',
  ]
}

export const removeMark = ({
  tr,
  selection,
  markType,
}: {
  tr: Transaction
  selection: TextSelection
  markType: MarkType
}) =>
  selection.empty
    ? tr.removeStoredMark(markType)
    : tr.removeMark(selection.from, selection.to, markType)

export const addMark = ({
  color,
  tr,
  selection,
  markType,
}: {
  color: string
  tr: Transaction
  selection: TextSelection
  markType: MarkType
}) => {
  const pendingMark = markType.create({ color })
  return selection.empty
    ? tr.addStoredMark(pendingMark)
    : tr.addMark(selection.from, selection.to, pendingMark)
}

export const updateMark = ({
  color,
  editorView,
  mark,
}: {
  color: string
  editorView: EditorView
  mark: MarkTypeKey
}) => {
  const markType = schema.marks[mark]

  const { state } = editorView
  const { selection, tr } = state

  const shouldRemoveMark = color === ''

  shouldRemoveMark
    ? removeMark({ tr, selection, markType })
    : addMark({ tr, selection, color, markType })

  editorView.dispatch(tr)
}
