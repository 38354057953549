import { collab } from 'prosemirror-collab'
import { dropCursor } from 'prosemirror-dropcursor'
import { gapCursor } from 'prosemirror-gapcursor'
import { EditorState } from 'prosemirror-state'

import { schema } from '@showrunner/codex'

import { ILoadedScript, IRoot, IUser } from '@state'

import { getLiveEditorPlugins } from './plugin-configs'

// TODO: there's a lot of redundant info in config, we should consolidate
// and stop using choo stores when we can use mst
export type EditorStateConfig = {
  mst: IRoot
  script: ILoadedScript
  collabId: string
  clientId: string
  scriptId: string
  user: IUser
}

// createSnapshotEditorState() dictates which plugins are enabled in static snapshots
export function createLiveEditorState(config: EditorStateConfig): EditorState {
  const { script, collabId } = config
  const ourPlugins = getLiveEditorPlugins(config).map((fn) => fn(config))

  // Stock ProseMirror plugins are added to the end of the array
  const plugins = ourPlugins.concat([
    dropCursor(),
    gapCursor(),
    collab({
      clientID: collabId,
      version: script.version,
    }),
  ])

  const editorState = EditorState.create({
    doc: schema.nodeFromJSON(script.doc),
    plugins,
  })

  // NOTE: we used to repaginate immediately upon loading the script but this
  // can be dangerous when other users are actively typing
  return editorState
}
