import { EditorState, TextSelection } from 'prosemirror-state'

import { schema, types } from '@showrunner/codex'

// TODO: handle wrapping existing dialogue in dual container
function insertDualDialogue(viewState: EditorState, viewDispatch: Dispatch) {
  const { $from, $to } = viewState.selection
  if (!$from.parent.isBlock) {
    return false
  }
  if (viewDispatch) {
    // NOTE: not sure why this is needed, this is from some internal PM stuff
    const atEnd = $to.parentOffset === $to.parent.content.size
    if (!atEnd) {
      return false
    }
    const tr = viewState.tr
    const type = viewState.schema.nodes[types.DUAL_DIALOGUE]
    tr.replaceRangeWith($from.pos, $to.pos, type.createAndFill())

    // creating a text selection that is two positions further than where we
    // started, which should be the first character node
    const modifiedSelection = TextSelection.findFrom(
      tr.doc.resolve($from.pos + 2),
      1,
      true,
    )

    if (modifiedSelection) {
      tr.setSelection(modifiedSelection)
    }

    viewDispatch(tr.scrollIntoView())
  }
  return true
}
// insert hard break (inline node) in current block
function insertHardBreak(state: EditorState, dispatch: Dispatch) {
  dispatch(
    state.tr
      .replaceSelectionWith(schema.nodes[types.HARD_BREAK].create())
      .scrollIntoView(),
  )
  return true
}

export { insertDualDialogue, insertHardBreak }
