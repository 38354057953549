import { Loader } from '@components/Loader'
import { useEffectOnlyOnce, useNavigation } from '@hooks'
import { useMst } from '@state'

export const OAuthCallbackPage = () => {
  const { location, log, apiClient, trackEvent, MIXPANEL_EVENTS } = useMst()
  const { navigate } = useNavigation()
  const slackToken = location.getQueryParam('code')
  const scriptId = location.getQueryParam('state')
  const error = location.getQueryParam('error')
  const errorDescription = location.getQueryParam('error_description')

  const addToSlackAndRedirect = async () => {
    const errMsg = 'Error adding script to slack'
    if (slackToken && scriptId) {
      try {
        await apiClient.addScriptToSlack({ scriptId, slackToken })
        trackEvent(MIXPANEL_EVENTS.SCRIPT_SLACK_INTEGRATION_ENABLED)
      } catch (err) {
        log.error(errMsg, { err })
      }
    }
    if (error) log.error(errMsg, { error, description: errorDescription })

    // we redirect whether the handshake is completed/fails/aborted
    navigate(`/scripts/${scriptId}`)
  }

  useEffectOnlyOnce(addToSlackAndRedirect)

  return <Loader fullScreen />
}
