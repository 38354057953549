import mixpanel, { Dict } from 'mixpanel-browser'
import hash from 'object-hash'

import { SplitEditorPref } from '@state/types'

import { UserPayload } from '../ScriptoApiClient/types'

import * as EVENT_NAMES from './eventNames'

/*
  You can see analytics events in the browser console by setting
  the localstorage key "debugMixpanel" to a comma delimited list of event
  names OR to * to log all


  example log only two events:
    localStorage.setItem('debugMixpanel', 'script status changed,writing activity')

  example log all events:
    localStorage.setItem('debugMixpanel', '*')
*/

export class MixpanelClient {
  private readonly _token: string
  private readonly _logEvents: string[]
  private _loaded: boolean

  constructor(token: string) {
    this._token = token
    this._loaded = false
    const logEventsString = window.localStorage.getItem('debugMixpanel')
    this._logEvents = logEventsString ? logEventsString.split(',') : []

    if (token) {
      try {
        mixpanel.init(this._token)
        this._loaded = true
      } catch (error) {
        // eslint-disable-next-line no-console
        console.warn('[analytics] mixpanel init failed', error)
      }
    }
  }

  get EVENTS() {
    return EVENT_NAMES
  }

  identifyUser(
    payload: UserPayload,
    org?: {
      name: string
      id: string
    },
  ) {
    const { id, email, name, staff } = payload
    if (this._loaded) {
      mixpanel.identify(hash(id))
      mixpanel.people.set('Last Event', new Date())
      mixpanel.people.set('$last_name', name)
      mixpanel.people.set('$email', email)
      mixpanel.people.set('userId', id)
      mixpanel.people.set('staff', staff)

      if (org) {
        mixpanel.people.set('activeWorkspaceName', org.name)
        mixpanel.people.set('activeWorkspaceId', org.id)
      }
    }
  }

  setUserProperties(properties: Dict) {
    if (this._loaded) {
      mixpanel.people.set(properties)
    }
  }

  // mixpanel super properties are tracked on every event sent. We use
  // this to include userId, workspaceId and workspaceName on every event
  setPersistentEventProperties(properties: Dict) {
    if (this._loaded) {
      mixpanel.register(properties)
    }
  }

  private logEventToConsole(eventName: string, eventProperties?: Dict) {
    const shouldLog =
      this._logEvents.includes(eventName) || this._logEvents.includes('*')
    if (shouldLog) {
      // eslint-disable-next-line no-console
      console.log('track', `"${eventName}"`, eventProperties)
    }
  }

  track(eventName: string, eventProperties?: Dict) {
    // uncomment this for dev debugging
    // console.log(`MIXPANEL EVENT: ${eventName}`, eventProperties)
    this.logEventToConsole(eventName, eventProperties)
    if (this._loaded) {
      // this call triggers an update to user $last_seen
      mixpanel.people.set('Last Event', new Date())
      mixpanel.track(eventName, eventProperties)
    }
  }

  trackDocCreated(options: {
    type: 'rundown' | 'script'
    format: string
    accessLevel: 'shared' | 'private'
    from:
      | 'dashboard'
      | 'explorer'
      | 'import'
      | 'duplicate'
      | 'clone'
      | 'document pane'
  }) {
    this.track(EVENT_NAMES.DOCUMENT_CREATED, options)
  }

  trackDocModified(docType: DocumentListingType, docId: number | string) {
    this.track(EVENT_NAMES.DOCUMENT_MODIFIED, { docType, docId })
  }

  trackDocOpened(docType: DocumentListingType, docId: number | string) {
    this.track(EVENT_NAMES.DOCUMENT_OPENED, { docType, docId })
  }

  trackEnterSplitView() {
    this.track(EVENT_NAMES.ENTER_SPLIT_VIEW)
  }

  trackChangeSplitView(mode: SplitEditorPref) {
    this.track(EVENT_NAMES.CHANGE_SPLIT_VIEW, { mode })
  }

  trackExitSplitView() {
    this.track(EVENT_NAMES.EXIT_SPLIT_VIEW)
  }
}
