import React from 'react'

import { useMove, UseMovePosition } from '@mantine/hooks'
import cn from 'classnames'

import {
  AllowedRange,
  ConfiguredMarginValue,
  marginConfigToSliderPositions,
  sliderPositionToMarginConfig,
  US_PORTRAIT_CONFIG,
} from './helpers'

import styles from './MarginSlider.module.scss'

const Thumb = ({
  pos,
  active,
  onMouseDown,
  disabled,
}: {
  pos: number
  active: boolean
  onMouseDown: () => void
  disabled?: boolean
}) => {
  const handleMouseDown = disabled ? undefined : onMouseDown
  return (
    <div onMouseDown={handleMouseDown}>
      <i
        className={cn('fas fa-caret-down', styles.thumb, {
          [styles.activeThumb]: active,
          [styles.disabledThumb]: disabled,
        })}
        style={{ left: pos }}
      />
    </div>
  )
}

type MarginSliderProps = {
  constraints: AllowedRange
  margins?: ConfiguredMarginValue
  onChange?: Callback<[ConfiguredMarginValue]>
  disabled?: boolean
}
export const MarginSlider = ({
  margins,
  constraints,
  onChange,
  disabled,
}: MarginSliderProps) => {
  const { minLeftPx, maxRightPx, minWidthPx } = constraints
  const activeThumbRef = React.useRef<'left' | 'right'>()

  // left and right are the raw positions for the sliders and we need to
  // store these as state AND refs to handle the out-of-react-lifecycle
  // nature of drag events
  const [left, setLeftState] = React.useState(minLeftPx)
  const [right, setRightState] = React.useState(maxRightPx)
  const positionRef = React.useRef({ left, right })

  const setLeft = (value: number) => {
    setLeftState(value)
    positionRef.current.left = value
  }

  const setRight = (value: number) => {
    setRightState(value)
    positionRef.current.right = value
  }

  const clampLeft = ({
    newLeft,
    currentRight,
  }: {
    newLeft: number
    currentRight: number
  }) => {
    if (newLeft < minLeftPx) return minLeftPx
    const maxAllowed = currentRight - minWidthPx
    if (newLeft > maxAllowed) {
      return maxAllowed
    }
    return newLeft
  }

  const clampRight = ({
    newRight,
    currentLeft,
  }: {
    newRight: number
    currentLeft: number
  }) => {
    if (newRight > maxRightPx) return maxRightPx
    const minRequired = currentLeft + minWidthPx
    if (newRight < minRequired) {
      return minRequired
    }
    return newRight
  }

  const handleMove = ({ x }: UseMovePosition) => {
    const { left: currentLeft, right: currentRight } = positionRef.current
    if (activeThumbRef.current === 'left') {
      setLeft(
        clampLeft({
          newLeft: x * US_PORTRAIT_CONFIG.unscaledWidthPx,
          currentRight,
        }),
      )
    } else if (activeThumbRef.current === 'right') {
      setRight(
        clampRight({
          newRight: x * US_PORTRAIT_CONFIG.unscaledWidthPx,
          currentLeft,
        }),
      )
    }
  }

  const handleMoveComplete = (positions: { left: number; right: number }) => {
    const newMargins = sliderPositionToMarginConfig({
      leftPx: positions.left,
      rightPx: positions.right,
    })
    onChange?.(newMargins)
    activeThumbRef.current = undefined
  }

  const { ref, active: isDragging } = useMove(handleMove, {
    onScrubEnd: () => {
      handleMoveComplete(positionRef.current)
    },
  })
  const draggingLeft = isDragging && activeThumbRef.current === 'left'
  const draggingRight = isDragging && activeThumbRef.current === 'right'

  // if we're not dragging and the margins change, we want
  // to reset to those values
  React.useEffect(() => {
    if (margins && !isDragging) {
      // TODO: this timeout is temporary--
      // we probably need some way to buffer but this probably isn't it
      const timeoutId = setTimeout(() => {
        const { leftPx, rightPx } = marginConfigToSliderPositions(margins)
        setLeft(leftPx)
        setRight(rightPx)
      }, 50)

      return () => clearTimeout(timeoutId)
    }
  }, [isDragging, margins])

  return (
    <div
      style={{
        left: 0,
        right: 0,
        '--min-left-margin': `${minLeftPx}px`,
        '--max-right-margin': `${maxRightPx}px`,
      }}
      className={styles.track}
      ref={ref}
    >
      {/*
        show the thumbs if we have margins even if disabled
       */}
      {margins && (
        <>
          <Thumb
            disabled={disabled}
            onMouseDown={() => (activeThumbRef.current = 'left')}
            active={draggingLeft}
            pos={left}
          />
          <Thumb
            disabled={disabled}
            onMouseDown={() => (activeThumbRef.current = 'right')}
            active={draggingRight}
            pos={right}
          />
        </>
      )}
    </div>
  )
}
